import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import basketService from '../api/BasketService';

export class BasketItem extends Component {
    static displayName = BasketItem.name;
  
    constructor (props) {
      super(props);
      this.state = {
        updating: false,
      };
      this.handleRemoveItem = this.handleRemoveItem.bind(this);
    }
    
    async handleRemoveItem(e) {      
      e.preventDefault()

      if(this.state.updating) return;

      this.setState({ 
        updating: true
      });

      const removed = await basketService.removeFromBasket(
        this.props.basketItem.productId,
        this.props.basketItem.productSizeVariantId,
      );
      if(removed) {
          console.log('removed item from basket')
      }

      this.setState({ 
        updating: false
      });
    }
    
    async handleUpdateItemQuantity(e, quantityChange) {
        e.preventDefault()

        if(quantityChange !== 1 && quantityChange !== -1) return;

        if(this.state.updating) return;

        this.setState({ 
            updating: true
        });

        const done = await basketService.updateItemQuantity(
          this.props.basketItem.productId,
          this.props.basketItem.productSizeVariantId,
          quantityChange
        );
        if(done) {
            console.log('item quantity in basket updated')
        }

        this.setState({ 
            updating: false
        });
      }
      
    render() {
      const basketItem = this.props.basketItem;
      const hideButtons = this.props.hideButtons;
      return (
        <div className="row mb-3">
            <div className="col-5 col-md-3 col-lg-2">
              {hideButtons &&
              <img src={basketItem.product.id < 19167 ? basketItem.product.listingThumbnail.url : process.env.REACT_APP_MEDIAURL_BASKET_PRODUCTIMG + basketItem.product.listingThumbnail.url} className="w-100" />
              }
              {!hideButtons &&
              <Link to={`/p/${basketItem.product.id}/${basketItem.product.friendlyUrlName}`}><img src={basketItem.product.id < 19167 ? basketItem.product.listingThumbnail.url : process.env.REACT_APP_MEDIAURL_BASKET_PRODUCTIMG + basketItem.product.listingThumbnail.url} className="w-100" /></Link>
              }
            </div>
            <div className="col-7 col-md-5 col-lg-6">
                {hideButtons &&
                <h5 className="pr-4">{basketItem.product.title}</h5>
                }
                {!hideButtons &&
                <h5 className="pr-4"><Link to={`/p/${basketItem.product.id}/${basketItem.product.friendlyUrlName}`}>{basketItem.product.title}</Link></h5>
                }
                <p>{basketItem.sizeVariantTitle} {/*| {basketItem.product.status}*/}</p>
                {!hideButtons &&
                <button type="button" className="close d-md-none position-absolute px-3" style={{top:'0px', right:'0px'}} aria-label="Remove" onClick={e => this.handleRemoveItem(e)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
                {!hideButtons &&
                <div className="mt-3">
                  <button type="button" className="btn btn-sm btn-outline-dark d-md-inline-block d-none" onClick={e => this.handleRemoveItem(e)}>
                      Remove
                  </button>
                  <div className="d-md-none row">
                    <div className="col-6">
                      <div className="btn-group" role="group" aria-label="Item quantity in basket">
                          <button type="button" className="btn btn-sm btn-outline-dark" onClick={e => this.handleUpdateItemQuantity(e, -1)}>-</button>
                          <button type="button" className="btn btn-sm btn-outline-dark btn-label" aria-disabled="true">{basketItem.quantity}</button>
                          <button type="button" className="btn btn-sm btn-outline-dark" onClick={e => this.handleUpdateItemQuantity(e, 1)}>+</button>
                      </div>
                    </div>
                    <div className="col-6 text-right">
                      {basketItem.totalCostCurrency}
                    </div>
                  </div>
                </div>
                }

            </div>
            <div className="col-2 d-none d-md-block text-right">
                {!hideButtons &&
                <div className="btn-group" role="group" aria-label="Item quantity in basket">
                    <button type="button" className="btn btn-sm btn-outline-dark" onClick={e => this.handleUpdateItemQuantity(e, -1)}>-</button>
                    <button type="button" className="btn btn-sm btn-outline-dark btn-label" aria-disabled="true">{basketItem.quantity}</button>
                    <button type="button" className="btn btn-sm btn-outline-dark" onClick={e => this.handleUpdateItemQuantity(e, 1)}>+</button>
                </div>
                }
                {hideButtons &&
                basketItem.quantity
                }
            </div>
            <div className="col-2 d-none d-md-block text-right">
                {basketItem.totalCostCurrency}
            </div>
        </div>
      );
    }
}

