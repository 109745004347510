import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Button, Form, Input } from 'reactstrap';
import basketService from '../api/BasketService';

export class ProductAddToBasket extends Component {
    static displayName = ProductAddToBasket.name;

    constructor(props) {
        super(props);
        this.state = {
            product: props.product,
            selectedSizeId: '',
            buttonText: this.getButtonText(false),
            loading: false,
            showSuccessMessage: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setButtonLoadingState = this.setButtonLoadingState.bind(this);
    }

    handleChange(e) {
        this.setState({ selectedSizeId: parseInt(e.target.value) });
    }

    async handleSubmit(e) {
        e.preventDefault();
        if (!this.state.selectedSizeId) {
            alert('Please select a size');
            return;
        }
        this.setButtonLoadingState(true);
        const basketItem = await basketService.addToBasket(
            this.state.product.id,
            this.state.selectedSizeId,
        );

        if(basketItem) {
            this.setState({
                showSuccessMessage: true
            })
            this.setButtonLoadingState(false);
            return;
        }

        //todo
        //show error
        alert('Error message type');
        this.setButtonLoadingState(false);
    }

    setButtonLoadingState(isLoading) {
        let txt = this.getButtonText(isLoading);
        this.setState({
            buttonText: txt,
            loading: isLoading
        });
    }
    
    getButtonText(isLoading) {
        return isLoading ? 'Loading..' : 'Add to bag';
    }

    render() {
        let sizeSelectOptions = this.state.product.sizes.map((size) =>
            <option key={size.id.toString()} value={size.id.toString()}>{size.sizeDisplayTitle}</option>
        );
        return (
            <Form onSubmit={this.handleSubmit}>
                <Input type="select" name="select" id="AddToBasket" onChange={this.handleChange}
                    className="w-100 mb-2 product-input addtobasket-sizeselect">
                    <option value=''>Choose your size</option>
                    {sizeSelectOptions}
                </Input>
                <Button className="w-100 product-input addtobasket-btn" disabled={this.state.loading}>{this.state.buttonText}</Button>
                <Collapse isOpen={this.state.showSuccessMessage}>
                    {<Link className="my-3 w-100 btn btn-primary product-input" to="/basket">Item added, view your bag</Link>}
                </Collapse>
            </Form>
        );
    }
}
