import React, { Component } from 'react';
import { Meta } from "../layout-components/Meta";

export class ReturnPolicyPage extends Component {
    static displayName = ReturnPolicyPage.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className="container px-0" style={{maxWidth:'600px'}}>
              <Meta title="Return Policy | FLAIR" />

              <h1 className="fslash mb-4 text-uppercase">Return Policy</h1>
              <p>Flair is unifying the return policy across our designers to simiplify the process and provide you with the best shopping experience and peace of mind.</p>
              <p>We're confident that you will enjoy the products sold on Flair but in case you do need to return an item, there are three simple return policies you'll find for the products shown on Flair:</p>
              <ul>
                  <li className="mb-2"><strong>Full Refund</strong>: The product is eligible for a full refund within 14 days of the purchase date. This does not include the shipping fee.</li>
                  <li className="mb-2"><strong>Partial Refund</strong>: The product is eligible for a 50% refund. The other 50% goes towards the designer's material and labour costs. You will mainly find this for products that are made-to-order or custom-made. This does not include the shipping fee.</li>
                  <li className="mb-2"><strong>No Refund</strong>: This product is not eligible for a refund, and we advise you to check all details before purchasing.</li>
              </ul>
              <p>All of the above are our general return policies; please make sure to check the product details carefully for any further specifics before purchasing.<br/>
                 Some designers might allow for an exchange if the wrong size was purchased; however to avoid any issues, please contact us and check beforehand. If you have questions regarding sizing or similar ahead of making a purchase, you can reach out to us on: <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a>. <br/>Together we can improve sustainability by reducing returns and shipping.</p>
              <p>Items <b>must</b> be returned unworn, undamaged and unused, with all tags attached and the original packaging included. Flair reserves the right for the designer to refuse the return if the product does not meet these requirements. If you believe an item to be defective or damaged, please contact us so that we can help resolve the issue.</p>
              <p>For return shipping, the customer is responsible to send the item back, including the cost of shipping. Thus, Flair and our designers don't take responsibility for items lost in return transit. In this unfortunate situation, we will not be able to issue a refund. We recommend you use tracked shipping when returning an item.</p>
              <div className="mb-5">
                <h3 className="mt-4 mb-2 font-italic font-weight-bold">How do I return an item?</h3>
                <ol>
                    <li className="mb-2">Having met all above requirements, contact us on <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer" className="font-weight-bold">{process.env.REACT_APP_CONTACT_EMAIL}</a> to request a refund, stating your order number.</li>
                    <li className="mb-2">We will provide you with an address to return the item to.</li>
                    <li className="mb-2">You will ship the item back.</li>
                    <li className="mb-2">Once the returned item has been received, quality checked and approved, Flair will issue a refund to the original method of payment and a confirmation e-mail will be sent to you.<br/>
                                         Please note that it can take up to 5 business days to process the refund. It might take additional time for your bank to process the transaction and update your bank statement.
                    </li>
                </ol>
              </div>
            </div>
        );
    }
}
