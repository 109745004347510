import React, { Fragment, Component } from 'react';

export class BillingSection extends Component {
    static displayName = BillingSection.name;

    constructor (props) {
      super(props);
      this.state = {
        showState: false,
        stateRequired: false,
        stateLabel: 'County',
        postCodeLabel: 'Postal Code',
      };
      this.countryChange = this.countryChange.bind(this);
    }

    countryChange(event) {
      event.preventDefault();
      switch(event.target.value) {
        case 'us':
          this.setState({
            showState: true,
            stateRequired: true,
            stateLabel: 'State',
            postCodeLabel: 'ZIP'
          });
          break;
        case 'gb':
          this.setState({
            showState: true,
            stateRequired: false,
            stateLabel: 'County',
            postCodeLabel: 'Postcode'
          });
          break;
        default:
          this.setState({
            showState: false,
            stateRequired: false,
            stateLabel: 'County',
            postCodeLabel: 'Postal Code'
          });
      }
      this.props.billingCountryChange(event.target.value);
      this.props.handleChange(event);
    }
  
    render() {
      return (
        <Fragment>
          <h2 className="d-flex justify-content-between align-items-center mb-3">1. Your Address</h2>

          <div className="row">
            <div className="col-6 mb-3">
              <label htmlFor="billing_firstname">First name</label>
              <input type="text" className="form-control" id="billing_firstname" name="firstName" onChange={this.props.handleChange} placeholder="" defaultValue={""} required />
              <div className="invalid-feedback">
                Valid first name is required.
              </div>
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="billing_lastname">Last name</label>
              <input type="text" className="form-control" id="billing_lastname" name="lastName" onChange={this.props.handleChange} placeholder="" defaultValue={""} required />
              <div className="invalid-feedback">
                Valid last name is required.
              </div>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="email">Email</label>
            <input type="email" className="form-control" id="email" name="emailAddress" onChange={this.props.handleChange} placeholder="you@example.com" required />
            <div className="invalid-feedback">
              Please enter a valid email address for shipping updates.
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="phonenumber">Phone number <span className="text-muted">(Optional)</span></label>
            <input type="tel" className="form-control" id="phonenumber" name="phoneNumber" onChange={this.props.handleChange} placeholder="" />
            <div className="invalid-feedback">
              Please enter a valid phone number.
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="billing_address">Address</label>
            <input type="text" className="form-control" id="billing_address" name="address" onChange={this.props.handleChange} placeholder="1234 Main St" required />
            <div className="invalid-feedback">
              Please enter your address.
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="billing_city">City</label>
            <input type="text" className="form-control" id="billing_city" name="city" onChange={this.props.handleChange} placeholder="London" required />
            <div className="invalid-feedback">
              Please enter your city.
            </div>
          </div>

          <div className="row">
            <div className={(!this.state.showState ? 'col-md-8 mb-3' : 'col-md-5 mb-3')}>
              <label htmlFor="billing_country">Country</label>
              <select onLoad={this.countryChange} onChange={this.countryChange} className="custom-select d-block w-100" id="billing_country" name="country" required>
                <option value="">Choose...</option>
                <option value="gb">United Kingdom</option>
                {/*
                <option value="cn">China</option>
                <option value="de">Germany</option>
                <option value="us">United States</option>
                */}
              </select>
              <div className="invalid-feedback">
                Please select a valid country.
              </div>
            </div>
            <div className={'col-6 col-md-4 mb-3 ' + (!this.state.showState ? ' d-none' : '')}>
              <label htmlFor="billing_county">{this.state.stateLabel} <span className={'text-muted' + (this.state.stateRequired ? ' d-none' : '')}>(Optional)</span></label>
              <input type="text" className="form-control" id="billing_county" name="county" onChange={this.props.handleChange} placeholder="" />
            </div>
            <div className={(!this.state.showState ? 'col-md-4 mb-3' : 'col-6 col-md-3 mb-3')}>
              <label htmlFor="billing_postcode">{this.state.postCodeLabel}</label>
              <input type="text" className="form-control" id="billing_postcode" name="postCode" onChange={this.props.handleChange} placeholder="" required />
              <div className="invalid-feedback">
                Postcode is required.
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
}