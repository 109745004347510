import React, { Component } from 'react';
import { ProductGallery } from './ProductGallery';
import { ProductRightSideDetails, ProductMobileBottomDetails } from './ProductSideDetails';
import { ProductDetailsTabs } from './ProductDetailsTabs';
import { ProductStory } from './ProductStory';
import { ProductAddToBasket } from './ProductAddToBasket'
import { Meta } from "../layout-components/Meta";
import './ProductDetailsPage.css';

export class ProductDetailsPage extends Component {
    static displayName = ProductDetailsPage.name;

    constructor(props) {
        super(props);
        this.state = { product: null, loading: true };
        this.scrollToAR = this.scrollToAR.bind(this);
        this.scrollToDetail = this.scrollToDetail.bind(this);
    }

    componentDidMount() {
        const { id, friendlyUrlName } = this.props.match.params;
        this.fetchData(id, friendlyUrlName);
    }

    scrollToAR() {
        if(this.arRef && this.arRef.current) {
            this.arRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });            
        }
    }

    scrollToDetail(tab) {
        if(this.productDetailsTabsRef && this.productDetailsTabsRef.current) {
            if(tab) {
                this.productDetailsTabsRef.current.setActiveTab(tab);
                setTimeout(() => {
                    this.productDetailsTabsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });            
                }, 10);
            } else {
                this.productDetailsTabsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });            
            }
        }
    }


    render() {
        if (this.state.loading)
            return (
                <p><em>Loading...</em></p>
            );

        const product = this.state.product;
        
        return (
            <div className="product">
                <Meta 
                    title={`${product.title} by ${product.designer.brandTitle} | FLAIR`}
                    description={`Buy ${product.title} by ${product.designer.brandTitle} on Flair.fashion. ${product.tagline}`}
                    url={`${process.env.REACT_APP_URL_DEFAULT_DOMAIN}/p/${product.id}/${product.friendlyUrlName}`}
                    image={product.gallery[0].url}>
                    <meta property="og:type" content="product" />
                    <meta property="product:price:amount" content={product.unitPrice} />
                    <meta property="product:price:currency" content="GBP" />
                    <meta property="product:brand" content={product.designer.brandTitle} />
                </Meta>
                <div className="d-block d-md-none px-4 py-3 product-atb--mobile">
                    <ProductMobileBottomDetails product={product} />
                </div>
                <div className="row mb-5">
                    <div className="col-md-7 col-xl-8 productgallery">
                        <ProductGallery productId={product.id} gallery={product.gallery} title={`FLAIR - ${product.title} by ${product.designer.brandTitle}`} />
                    </div>
                    <div className="col-md-5 col-xl-4 d-flex justify-content-center">
                        <ProductRightSideDetails product={product} scrollToDetail={this.scrollToDetail} scrollToAR={this.scrollToAR} />
                    </div>
                </div>
                <div className="container my-5 px-0">
                    <ProductDetailsTabs ref={this.productDetailsTabsRef} product={product} />
                </div>
                <div className="mb-5 pb-5 mx-auto d-none d-md-block" style={{maxWidth:'300px'}}>
                    <h3 className="text-center">Buy now</h3>
                    <ProductAddToBasket product={product} />
                </div>
                <div className="mb-5 pb-5">
                    <ProductStory product={product} arRef={this.arRef} />
                </div>
            </div>
        );
    }

    async fetchData(id, friendlyUrlName) {
        const response = await fetch(`api/products/details/${id}/${friendlyUrlName}`, {
        });
        const data = await response.json();

        this.arRef = data && data.arContent ? React.createRef() : null;
        this.productDetailsTabsRef = data ? React.createRef() : null;

        this.setState({ 
            product: data, 
            loading: false
        });

        setTimeout(() => {
            if (this.props.location.hash.includes('#ar')) { 
                this.scrollToAR();
            }
        }, 300);
    }
}
