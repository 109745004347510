import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";

import {injectStripe} from 'react-stripe-elements';
import { BillingSection } from './BillingSection';
import { ShippingSection } from './ShippingSection';
import { PaymentSection } from './PaymentSection';
import { CheckoutSideSummary } from './CheckoutSideSummary';
import { BasketDetails } from './BasketDetails';

import checkoutService from '../api/CheckoutService';

import './CheckoutPage.css';

class CheckoutForm extends Component {
  static displayName = CheckoutForm.name;

  constructor (props) {
    super(props);
    this.state = {
        pageProcessStatus: CheckoutForm.pageProcessStatus.notReady,
        error: null,
        checkout: null,
        order: null,
        completeUrl: null,
        paymentCountry: null,
        paymentMethodType: null,
        orderInput: {
            basket: null,
            stripePaymentIntentId: null,
            stripePaymentIntentClientSecret: null,
            emailAddress: null,
            phoneNumber: null,
            shippingAddress: {
                firstName: null,
                lastName: null,
                address: null,
                city: null,
                state: null,
                postCode: null,
                country: null,
                sameAddressAsBilling: true,
            },
            billingAddress: {
                firstName: null,
                lastName: null,
                address: null,
                city: null,
                state: null,
                postCode: null,
                country: null,
            }
        }
    };
    this.fetchCheckoutData = this.fetchCheckoutData.bind(this);
    this.billingCountryChange = this.billingCountryChange.bind(this);
    this.handleBillingChange = this.handleBillingChange.bind(this);
    this.handleShippingChange = this.handleShippingChange.bind(this);
    this.paymentMethodTypeChange = this.paymentMethodTypeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePaymentIntentResult = this.handlePaymentIntentResult.bind(this);
    this.handlePaymentSourceActiviation = this.handlePaymentSourceActiviation.bind(this);
    this.populateOrderData = this.populateOrderData.bind(this);

    this.startProcess = this.startProcess.bind(this);
    this.endProcess = this.endProcess.bind(this);
    this.setError = this.setError.bind(this);
    this.redirectToNextStep = this.redirectToNextStep.bind(this);

    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  startProcess() {
    this.setState({
        pageProcessStatus: CheckoutForm.pageProcessStatus.processing,
        error: null
    })
  }
  endProcess() {
    this.setState({
        pageProcessStatus: CheckoutForm.pageProcessStatus.pageReady,
    })
  }

  setError(error) {
    this.setState({
        error: error
    })
  }

  redirectToNextStep(completeUrl) {
    this.setState({
        pageProcessStatus: CheckoutForm.pageProcessStatus.complete,
        completeUrl: completeUrl
    })
  }

  componentDidMount() {
    if (!this.props.stripe || !this.props.elements) {
        this.setError('Problem: payment gateway hasn\'t loaded yet. Refresh the page');
        this.endProcess();
        return;
    }

    this.fetchCheckoutData();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.error !== prevState.error &&
       this.state.error && this.state.error.length > 0) {
      console.log('scroll up');
      setTimeout(function() { 
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
      }, 500);
    }
  }



  billingCountryChange(country) {
    //if(!country || country.length <= 0) return;
    //console.log(country);
    this.setState({ paymentCountry: country });
    //
  }

  handleBillingChange(event) {
      event.preventDefault();
      const name = event.target.name;
      const value = event.target.value;
      const order = this.state.orderInput;
      switch(name) {
          case 'emailAddress':
          case 'phoneNumber':
            this.setState({
                orderInput: {
                    ...order,
                    [name]: value
                }
              });
              break;
          default:
            this.setState({
                orderInput: {
                    ...order,
                    billingAddress: {
                        ...order.billingAddress,
                        [name]: value
                    }
                }
              });
      }
  }

  handleShippingChange(event) {
      //event.preventDefault();
      const name = event.target.name;
      const value = event.target.value;
      const order = this.state.orderInput;
      this.setState({
        orderInput: {
            ...order,
            shippingAddress: {
                ...order.shippingAddress,
                [name]: value
            }
        }
      });
  }

  paymentMethodTypeChange(type) {
    this.setState({ paymentMethodType: type });
  }

  getProcessingUrl(orderId, query) {
    return `/checkout/processing/${orderId}${query ? query : ''}`;
  }


  getErrorMessage() {
    if(!this.state.error || this.state.error.length <= 0)
        return;
    let message;
    if(this.state.error) {
        message = (
            <Fragment>
                <hr />
                <p className="mb-0">{this.state.error}</p>
            </Fragment>
        );
    }
    return (
        <div className="alert alert-danger" role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="alert-heading">Oh no!</h4>
            <p>There was an error when processing your order. Please check your information and try again.</p>
            <p>If this continues please get in touch with us to see how we can help resolve the issue. <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a></p>
            {message}
        </div>
    );
  }



  render() {
    if (this.state.pageProcessStatus === CheckoutForm.pageProcessStatus.notReady)
        return (
            <p><Meta title="Checkout | FLAIR" /><em>Loading...</em></p>
            );

    if (this.state.pageProcessStatus === CheckoutForm.pageProcessStatus.complete)
        return (
            <Redirect to={this.state.completeUrl} />
        );
        
    const checkout = this.state.checkout;

    if(!checkout || !checkout.basket || !checkout.basket.basketItemsGroups)
        return (
          <p><Meta title="Checkout | FLAIR" /><em>No items!</em></p>
        );

    const errorMessage = this.getErrorMessage();
    const processing = this.state.pageProcessStatus === CheckoutForm.pageProcessStatus.processing;

    return (
        <form onSubmit={this.handleSubmit}>

          <Meta title="Checkout | FLAIR" />

          <div className="container px-0 checkout-form">

            <h1 className="pb-3 text-center text-uppercase fslash">Checkout</h1>

            {errorMessage}

            <div className="row">

              <div className="col-md-4 order-md-2 mb-4 d-none d-md-block">
                <CheckoutSideSummary 
                    basket={checkout.basket}
                    processing={processing} />
              </div>

              <div className="col-md-8 order-md-1">

                <div className="pb-4 mb-5 border-bottom">
                  Almost there...!
                </div>

                <div className="pb-4 mb-5 border-bottom">
                  <BillingSection 
                    billingCountryChange={this.billingCountryChange} 
                    handleChange={this.handleBillingChange} />
                </div>

                <div className="pb-4 mb-5 border-bottom">
                  <ShippingSection handleChange={this.handleShippingChange} />
                </div>

                <div className="pb-4 mb-5 border-bottom">
                  <PaymentSection 
                    paymentCountry={this.state.paymentCountry} 
                    paymentMethodTypeChange={this.paymentMethodTypeChange} />

                  <div id="card-errors" className="element-errors"></div>
                  <div id="iban-errors" className="element-errors"></div>
                </div>

                <div className="pb-4 mb-5">
                  <BasketDetails 
                    basket={checkout.basket} />
                </div>

                <div className="pb-4 mb-5 d-md-none">
                  <button type="submit" disabled={processing} className="btn btn-dark btn-lg w-100">{processing ? 'Processing...' : 'Place your order'}</button>
                  <div className="mt-3 small text-muted">
                    By placing your order, you agree to our <Link to="/terms-and-conditions" className="text-underline">Terms &amp; Conditions</Link>, <Link to="/privacy-policy" className="text-underline">Privacy</Link> and <Link to="return-policy" className="text-underline">Returns</Link> policies. You also consent to your data being stored with Flair and your shipping address to be shared with the designer(s) solely for delivery purposes.
                  </div>
                </div>

              </div>
            </div>
          </div>

        </form>
    );
  }

  

  async fetchCheckoutData() {
    const data = await checkoutService.fetchCheckoutData();
    if(data === null) {
        this.setState({ 
            pageProcessStatus: CheckoutForm.pageProcessStatus.pageReady,
            checkout: null
        });
    } else {
        console.log(data);
        const order = this.state.orderInput;
        this.setState({ 
            pageProcessStatus: CheckoutForm.pageProcessStatus.pageReady,
            checkout: data,
            orderInput: {
                ...order,
                basket: data.basket.localBasket,
                stripePaymentIntentId: data.stripePaymentIntentId,
                stripePaymentIntentClientSecret: data.stripePaymentIntentClientSecret
            }
        });
    }
  }




  async handleSubmit(event) {
    event.preventDefault();

    const { checkout } = this.state;
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
        this.setError('Problem: payment gateway hasn\'t loaded yet.');
        this.endProcess();
        return;
    }

    if(this.state.pageProcessStatus !== CheckoutForm.pageProcessStatus.pageReady) return;

    if(!checkout.stripePaymentIntentClientSecret) {
        this.setError('Problem: payment intent secret is not available.');
        this.endProcess();
        return;
    }

    this.startProcess();

    const orderData = this.populateOrderData();

    const fullname = orderData.billingAddress.firstName + ' ' + 
                     orderData.billingAddress.lastName;

    
    const order = this.state.order ? this.state.order :
                                     await checkoutService.initiateCheckoutOrder(orderData);
    if(order === null) {
        this.setError('Order failed');
        this.endProcess();
        return;
    } else if(order.errors) {
        this.setError(`Order failed: ${order.errors}`);
        this.endProcess();
        return;
    } else {
        this.setState({
            order: order,
        });
    }

    const processingUrl = this.getProcessingUrl(order.id, `?paymentIntent=${orderData.stripePaymentIntentId}`);

    if(this.state.paymentMethodType === PaymentSection.paymentMethods.card.id) {
        // Confirm the PaymentIntent with the card Element.
        this.props.stripe
            .confirmCardPayment(
                this.state.checkout.stripePaymentIntentClientSecret, 
                {
                    payment_method: {
                        card: this.props.elements.getElement(PaymentSection.paymentMethods.card.id),
                        billing_details: {
                            name: fullname,
                        },
                    },
                }
            )
            .then((response) => {
                this.handlePaymentIntentResult(response, order, processingUrl);
            });
        return;
    } 
    else if (this.state.paymentMethodType === PaymentSection.paymentMethods.sepa_debit.id) {
        // Confirm the PaymentIntent with the IBAN Element.
        this.props.stripe
            .confirmSepaDebitPayment(
                this.state.checkout.stripePaymentIntentClientSecret,
                {
                    payment_method: {
                        sepa_debit: this.props.elements.getElement(PaymentSection.paymentMethods.sepa_debit.id),
                        billing_details: {
                            name: fullname,
                            email: orderData.emailAddress,
                        },
                    },
                }
            )
            .then((response) => {
                this.handlePaymentIntentResult(response, order, processingUrl);
            });
        return;
    }
    else if (this.state.paymentMethodType === PaymentSection.paymentMethods.ideal.id) {
        // Confirm the PaymentIntent with the iDEAL Element.
        this.props.stripe
            .confirmIdealPayment(
                this.state.checkout.stripePaymentIntentClientSecret,
                {
                    payment_method: {
                        ideal: this.props.elements.getElement(PaymentSection.paymentMethods.ideal.id),
                    },
                    return_url: CheckoutForm.domainUrl + processingUrl,
                }
            )
            .then((response) => {
                if (response.error) {
                    //TODO
                    // Inform the customer that there was an error.
                    this.setError(`Order failed: ${response.error}`);
                    this.endProcess();
                    return;
                }
            });
        return;
    } 
    else if (this.state.paymentMethodType === PaymentSection.paymentMethods.alipay.id) {
        this.props.stripe
            .confirmAlipayPayment(
                this.state.checkout.stripePaymentIntentClientSecret,
                {
                  payment_method: {
                    billing_details: {
                        name: fullname,
                        email: orderData.emailAddress,
                    },
                  },
                  return_url: CheckoutForm.domainUrl + processingUrl,
                }
            ).then((response) => {
                this.handlePaymentIntentResult(response, order, processingUrl);
            });
        return;
    }
    else {
        // Prepare all the Stripe source common data.

        const sourceData = {
            type: this.state.paymentMethodType,
            //TODO
            //"amount" and "currency" should be changed to be retrived as part of the checkout object from backend
            amount: this.state.checkout.basket.totalCost * 100,
            currency: 'gbp',
            owner: {
                name: fullname,
                email: orderData.emailAddress,
            },
            redirect: {
                return_url: CheckoutForm.domainUrl + processingUrl,
            },
            //TODO
            //THIS SHOULD COME FROM A CONFIG FILE
            statement_descriptor: process.env.REACT_APP_STRIPE_STATEMENT_DESCRIPTOR,
            metadata: {
                paymentIntent: orderData.stripePaymentIntentId
            }
        };

        switch (this.state.paymentMethodType) {
            case PaymentSection.paymentMethods.sofort.id:
              // SOFORT: The country is required before redirecting to the bank.
              sourceData.sofort = {
                //TODO
                //IMPORTANT
                //this needs to come from config or backend
                country: 'GB',
              };
              break;
            case 'ach_credit_transfer':
              // ACH Bank Transfer: Only supports USD payments, edit the default config to try it.
              // In test mode, we can set the funds to be received via the owner email.
              //TODO
              //CHECK THIS AND TEST
              sourceData.owner.email = `amount_${sourceData.amount}@example.com`;
              break;
          }
        
        // Create a Stripe source with the common data and extra information.
        this.props.stripe
            .createSource(sourceData)
            .then((response) => {
                //console.log('[source]', payload);
                this.handlePaymentSourceActiviation(response, order, orderData.stripePaymentIntentId);
            });        
    }
  }


  populateOrderData() {
      const order = this.state.orderInput;
      if(order.shippingAddress.sameAddressAsBilling) {
        order.shippingAddress.firstName = order.billingAddress.firstName;
        order.shippingAddress.lastName = order.billingAddress.lastName;
        order.shippingAddress.address = order.billingAddress.address;
        order.shippingAddress.city = order.billingAddress.city;
        order.shippingAddress.state = order.billingAddress.state;
        order.shippingAddress.postCode = order.billingAddress.postCode;
        order.shippingAddress.country = order.billingAddress.country;
      }
      return order;
  }



  // Handle new PaymentIntent result
  async handlePaymentIntentResult(paymentResponse, order, completeUrl) {
    const {paymentIntent, error} = paymentResponse;
    if (error) {
        this.setError(`Payment failed: ${error.message}`);
        this.endProcess();
        console.log('[error]', error);
        return;
    } else if(paymentIntent.status === 'succeeded'||
              paymentIntent.status === 'processing') {
        console.log('[PaymentIntent]', paymentIntent);
        if(await this.finishCheckoutOrder(order.id)) {
          console.log('REDIRECT');
          this.redirectToNextStep(completeUrl);
          return;
        }
    }
    this.setError('Payment failed');
    this.endProcess();
  }

  // Handle activation of payment sources not yet supported by PaymentIntents
  async handlePaymentSourceActiviation(sourceResponse, order, stripePaymentIntentId) {
    const {source, error} = sourceResponse;
    if (error) {
        this.setError(`Payment failed: ${error.message}`);
        this.endProcess();
        console.log('[error]', error);
        return;
    }

    if(!await this.finishCheckoutOrder(order.id)) {
        this.setError('Error finishing the checkout');
        this.endProcess();
        return;
    }

    const progressingUrl = this.getProcessingUrl(order.id, `?source=${source.id}&client_secret=${source.client_secret}&paymentIntent=${stripePaymentIntentId}`);

    switch (source.flow) {
      case 'none':
        // Normally, sources with a `flow` value of `none` are chargeable right away,
        // but there are exceptions, for instance for WeChat QR codes just below.
        if (source.type === 'wechat') {
            this.redirectToNextStep(progressingUrl);

            /*
          // Display the QR code.
          const qrCode = new QRCode('wechat-qrcode', {
            text: source.wechat.qr_code_url,
            width: 128,
            height: 128,
            colorDark: '#424770',
            colorLight: '#f8fbfd',
            correctLevel: QRCode.CorrectLevel.H,
          });
          // Hide the previous text and update the call to action.
          form.querySelector('.payment-info.wechat p').style.display = 'none';
          let amount = store.formatPrice(
            store.getPaymentTotal(),
            config.currency
          );
          submitButton.textContent = `Scan this QR code on WeChat to pay ${amount}`;
          // Start polling the PaymentIntent status.
          pollPaymentIntentStatus(paymentIntent.id, 300000);
          */
        } else {
          console.log('Unhandled none flow.', source);
        }
        break;
      case 'redirect':
        // Immediately redirect the customer.
        //submitButton.textContent = 'Redirecting…';
        window.location.replace(source.redirect.url);
        //this.redirectToNextStep(source.redirect.url);
        break;
      case 'code_verification':
        // Display a code verification input to verify the source.
        break;
      case 'receiver':
        // Display the receiver address to send the funds to.
        /*
        mainElement.classList.add('success', 'receiver');
        const receiverInfo = confirmationElement.querySelector(
          '.receiver .info'
        );
        let amount = store.formatPrice(source.amount, config.currency);
        switch (source.type) {
          case 'ach_credit_transfer':
            // Display the ACH Bank Transfer information to the user.
            const ach = source.ach_credit_transfer;
            receiverInfo.innerHTML = `
              <ul>
                <li>
                  Amount:
                  <strong>${amount}</strong>
                </li>
                <li>
                  Bank Name:
                  <strong>${ach.bank_name}</strong>
                </li>
                <li>
                  Account Number:
                  <strong>${ach.account_number}</strong>
                </li>
                <li>
                  Routing Number:
                  <strong>${ach.routing_number}</strong>
                </li>
              </ul>`;
            break;
          case 'multibanco':
            // Display the Multibanco payment information to the user.
            const multibanco = source.multibanco;
            receiverInfo.innerHTML = `
              <ul>
                <li>
                  Amount (Montante):
                  <strong>${amount}</strong>
                </li>
                <li>
                  Entity (Entidade):
                  <strong>${multibanco.entity}</strong>
                </li>
                <li>
                  Reference (Referencia):
                  <strong>${multibanco.reference}</strong>
                </li>
              </ul>`;
            break;
          default:
            console.log('Unhandled receiver flow.', source);
        }
        // Poll the PaymentIntent status.
        pollPaymentIntentStatus(paymentIntent.id);
        */
        break;
      default:
        // Customer's PaymentIntent is received, pending payment confirmation.
        break;
    }
  }

  async finishCheckoutOrder(orderId) {
    const completedOrder = await checkoutService.finishCheckoutOrder(orderId);
    console.log(completedOrder);
    //REDIRECT USER TO CHECKOUT COMPLETE
    return completedOrder.complete;
  }

  static domainUrl = process.env.REACT_APP_URL_DEFAULT_DOMAIN;

  static pageProcessStatus = {
      notReady: 0,
      pageReady: 1,
      processing: 2,
      complete: 4
  }
}


export default injectStripe(CheckoutForm);
