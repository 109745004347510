import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";
import './DesignersListingPage.css';

export class DesignersListingPage extends Component {
    static displayName = DesignersListingPage.name;

    constructor(props) {
        super(props);
        this.state = { designers: [], loading: true };
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        if(this.state.loading) {
            return (
                <p><Meta title="Designers | FLAIR" /><em>Loading...</em></p>
            );
        }

        let content = this.state.designers.map(d =>
            <div key={d.id}>
                <Link to={`/${d.friendlyUrlName}`} className="mb-2 d-inline-block">
                    {d.brandTitle}
                </Link>
            </div>
        );
        
        return (
            <div>
                <Meta title="Designers | FLAIR" />
                <h1 className="header-style mb-4 text-uppercase">All Designers</h1>
                <div className="designerslist w-100">
                    {content}
                </div>
                <Link to="/join-flair" className="font-italic text-muted mt-5 pt-5 d-inline-block">Want to join Flair? Tell your brand story!</Link>
            </div>
        );
    }

    async fetchData() {
        const response = await fetch(`api/designers`, {
        });
        const data = await response.json();
        this.setState({ 
            designers: data,
            loading: false
        });
    }
}
