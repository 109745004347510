import React, { Fragment, useEffect, useRef } from 'react';
import CarouselSlider from '../common/CarouselSlider';

export function StoryImagesCarousel(props) {

    const prevArrowEl = useRef();
    const nextArrowEl = useRef();
    const [isInitialized, setIsInitialized] = React.useState(false);

    useEffect(() => {
      setIsInitialized(Boolean(prevArrowEl.current && nextArrowEl.current));
    }, [prevArrowEl, nextArrowEl]);
    
    if(!props.row.data.images || !props.row.data.images.length || props.row.data.images.length <= 0) {
        return(<Fragment></Fragment>);
    }

    const getCarouselSizeOptions = (size) => {
        const offset = 20;
        switch(size) {
            case 'small':
                return {
                    itemWidth: 200 - offset,
                    responsive:
                        [
                            {
                                breakpoint: 992,
                                settings: {
                                    itemWidth: 420 - offset
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    itemWidth: 350 - offset
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    itemWidth: 260 - offset
                                }
                            }
                        ]
                };
            case 'large':
                return {
                    itemWidth: 400 - offset,
                    responsive:
                        [
                            {
                                breakpoint: 992,
                                settings: {
                                    itemWidth: 620 - offset
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    itemWidth: 550 - offset
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    itemWidth: 460 - offset
                                }
                            }
                        ]
                };                
            default:
                return {
                    itemWidth: 300 - offset,
                    responsive:
                        [
                            {
                                breakpoint: 992,
                                settings: {
                                    itemWidth: 520 - offset
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    itemWidth: 450 - offset
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    itemWidth: 360 - offset
                                }
                            }
                        ]
                };
        }
    }

    return (
        <div className="my-5 story-images-carousel">
          <section className="position-relative">
            {isInitialized &&
            <CarouselSlider 
                {...getCarouselSizeOptions(props.row.data.size)}
                itemsAlignment={props.row.data.itemsAlignment}
                fullPageWidth={true}
                scrollLock={false}
                exactWidth={true}
                slidesToShow="auto"
                sliderClassName="pr-4"
                wrapperClassName="w-full"
                sliderNextButton={nextArrowEl.current}
                sliderPreviousButton={prevArrowEl.current}>
                {props.row.data.images.map((p, index) =>
                    <div key={index} className="pl-4 pr-1">
                        <img src={process.env.REACT_APP_MEDIAURL_STORY_IMAGESCAROUSEL + p.file.url} className="w-100" />
                    </div>
                )}
            </CarouselSlider>
            }
            <div className="d-flex justify-content-center slider-controller my-4">
                <div className="btn-group ml-1" role="group">
                    <button type="button" className="btn btn-outline-dark slider-prev" ref={prevArrowEl} aria-label="Previous">&nbsp;</button>
                    <button type="button" className="btn btn-outline-dark slider-next" ref={nextArrowEl} aria-label="Next">&nbsp;</button>
                </div>
            </div>
          </section>
        </div>
    );
}


export function StoryImagesCarouselLEGACY(props) {

    const prevArrowEl = useRef();
    const nextArrowEl = useRef();
    const [isInitialized, setIsInitialized] = React.useState(false);

    useEffect(() => {
      setIsInitialized(Boolean(prevArrowEl.current && nextArrowEl.current));
    }, [prevArrowEl, nextArrowEl]);
    
    if(!props.row.data.images || !props.row.data.images.length || props.row.data.images.length <= 0) {
        return(<Fragment></Fragment>);
    }

    return (
        <div className="my-5 story-images-carousel">
          <section className="position-relative">
            {isInitialized &&
            <CarouselSlider 
                fullPageWidth={true}
                itemWidth={300}
                scrollLock={false}
                exactWidth={true}
                slidesToShow="auto"
                sliderClassName="pr-4"
                wrapperClassName="w-full"
                sliderNextButton={nextArrowEl.current}
                sliderPreviousButton={prevArrowEl.current}
                responsive = {
                    [
                        {
                        breakpoint: 992,
                        settings: {
                            itemWidth: 520
                        }
                        },
                        {
                        breakpoint: 600,
                        settings: {
                            itemWidth: 450
                        }
                        },
                        {
                        breakpoint: 480,
                        settings: {
                            itemWidth: 360
                        }
                        }
                    ]
                }>
                {props.row.data.images.map((p, index) =>
                    <div key={index} className="pl-4 pr-1">
                        <img src={p.file.url} className="w-100" />
                    </div>
                )}
            </CarouselSlider>
            }
            <div className="d-flex justify-content-center slider-controller my-4">
                <div className="btn-group ml-1" role="group">
                    <button type="button" className="btn btn-outline-dark slider-prev" ref={prevArrowEl} aria-label="Previous">&nbsp;</button>
                    <button type="button" className="btn btn-outline-dark slider-next" ref={nextArrowEl} aria-label="Next">&nbsp;</button>
                </div>
            </div>
          </section>
        </div>
    );
}