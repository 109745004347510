import React, { Component } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/sea/index.css';

export default class StoryVideo extends Component {
    static displayName = StoryVideo.name;
   
    constructor(props) {
      super(props);
      try {
        if(props.row.data.file.type === 'video/youtube') {
          require('videojs-youtube');
        }
      } catch {
        console.log('video plugin could not load');
      }
    }

    componentDidMount() {
        //console.log(this.props.row);
        const videoJsOptions = {
            fluid: true,
            autoplay: this.props.row.data.autoplay === true ? 'muted' : false,
            controls: this.props.row.data.showControls === false && this.props.row.data.autoplay === true ? false : true,
            loop: this.props.row.data.loop,
            poster: this.props.row.data.thumbnail ? process.env.REACT_APP_MEDIAURL_STORY_VIDEO_THUMBNAIL + this.props.row.data.thumbnail : null,
            sources: [{ 
              src: process.env.REACT_APP_MEDIAURL_STORY_VIDEO_URLBASE + this.props.row.data.file.url + process.env.REACT_APP_MEDIAURL_STORY_VIDEO_URLSUFFIX,
              type: 'application/x-mpegURL'
            }]
          }
          

        // instantiate Video.js
        this.player = videojs(this.videoNode, videoJsOptions, function onPlayerReady() {
          console.log('onPlayerReady', this)
        });
    }
    
      // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
          this.player.dispose()
        }
    }
    
    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        let txt, rowStyle;
        if(this.props.row.data.showCaption && this.props.row.data.caption) {
          if(this.props.row.data.stretched) {
            txt = (
              <div className='my-2 mx-4'>
                <small className="text-muted" dangerouslySetInnerHTML={ {__html: this.props.row.data.caption} } />
              </div>
            )            
          } else {
            txt = (
              <div className='my-2'>
                <small className="text-muted" dangerouslySetInnerHTML={ {__html: this.props.row.data.caption} } />
              </div>
            )
          }
        }

        if(this.props.row.data.style === 'half_right') {
          rowStyle = 'col-12 offset-md-6 col-md-6 p-0';
        } else if(this.props.row.data.style === 'half_left') {
          rowStyle = 'col-12 col-md-6 p-0';
        } else if(this.props.row.data.style === 'half') {
          rowStyle = 'col-12 offset-md-3 col-md-6 p-0';
        } else {
          rowStyle = 'col-12 p-0';
        }

        return (
        <div className={"my-5" + (this.props.row.data.stretched ? " vid-fullscreen" : " container")}>	
            <div className="row m-0">
                <div className={rowStyle}>
                  <div data-vjs-player>
                      <video ref={ node => this.videoNode = node } className="video-js vjs-theme-sea"></video>
                  </div>
                  {txt}
                </div>
            </div>
        </div>
        )
    }
}

export class StoryVideoLEGACY extends Component {
  static displayName = StoryVideoLEGACY.name;
 
  constructor(props) {
    super(props);
    try {
      if(props.row.data.file.type === 'video/youtube') {
        require('videojs-youtube');
      }
    } catch {
      console.log('video plugin could not load');
    }
  }

  componentDidMount() {
      //console.log(this.props.row);
      const videoJsOptions = {
          fluid: true,
          autoplay: this.props.row.data.autoplay,
          controls: this.props.row.data.showControls,
          loop: this.props.row.data.loop,
          poster: this.props.row.data.thumbnail,
          sources: [{ 
            src: this.props.row.data.file.url,
            type: this.props.row.data.file.type
          }]
        }
        

      // instantiate Video.js
      this.player = videojs(this.videoNode, videoJsOptions, function onPlayerReady() {
        console.log('onPlayerReady', this)
      });
  }
  
    // destroy player on unmount
  componentWillUnmount() {
      if (this.player) {
        this.player.dispose()
      }
  }
  
  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
      let txt, rowStyle;
      if(this.props.row.data.text) {
        if(this.props.row.data.fullscreen) {
          txt = (
            <div className='my-1 mx-4' dangerouslySetInnerHTML={ {__html: this.props.row.data.text} } />
          )            
        } else {
          txt = (
            <div className='my-1' dangerouslySetInnerHTML={ {__html: this.props.row.data.text} } />
          )
        }
      }

      if(this.props.row.data.style === 'half_right') {
        rowStyle = 'col-12 offset-md-6 col-md-6 p-0';
      } else if(this.props.row.data.style === 'half_left') {
        rowStyle = 'col-12 col-md-6 p-0';
      } else if(this.props.row.data.style === 'half') {
        rowStyle = 'col-12 offset-md-3 col-md-6 p-0';
      } else {
        rowStyle = 'col-12 p-0';
      }

      return (
      <div className={"my-5" + (!this.props.row.data.fullscreen ? " container" : " vid-fullscreen")}>	
          <div className="row m-0">
              <div className={rowStyle}>
                <div data-vjs-player>
                    <video ref={ node => this.videoNode = node } className="video-js vjs-theme-sea"></video>
                </div>
                {txt}
              </div>
          </div>
      </div>
      )
  }
}
