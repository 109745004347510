import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";

export class AboutPage extends Component {
    static displayName = AboutPage.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Fragment>

                <Meta title="About FLAIR"
                    description="Flair is a marketplace aiming to empower fashion graduates and the next generation of emerging designers and brands. Utilising storytelling and technology such as augmented reality to bring designers' creativity and concepts to life." />
                
                <div className="container px-0 pt-lg-5">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h1 className="header-style page-title__h1 text-uppercase mb-4">Flair</h1>
                            <h2 className="text-uppercase mb-4">Flair Fashion is an ecosystem built to bridge the new era of online shopping with the emerging designers at the forefront of innovation in design, sustainability and technology.</h2>
                            <p>At the front end, Flair fuses the e-commerce marketplace experience with showcasing. Through storytelling and cutting-edge technology, Flair offers a new way for fashion brands to show and sell their collections -- connecting customer to creator like never before.</p>
                            <p>Beneath the surface of the Flair marketplace, Flair is building a Connection Hub <span className="font-italic">(COMING SOON)</span> to pair emerging designers with the supply chain services they need to establish and grow their brands.</p>
                        </div>
                    </div>

                    <section className="motive mt-5 mx-auto text-center" style={{maxWidth:'700px'}}>
                        <a className="btn btn-secondary btn-lg px-5 mt-3 mr-1" href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">Contact us</a>
                        <Link to="/join-flair" className="btn btn-secondary btn-lg px-5 mt-3 ml-1">Join Flair</Link>

                        <h2 className="fslash text-center mt-4">Or follow us on</h2>
                        <div className="ty-message mt-3 text-center">
                            <a href="https://www.instagram.com/flair.fashionofficial/" target="_blank">
                                <svg data-name="Layer 1" className="ty-social-icon mx-2" id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                                    <path className="social-cls-1" d="M83,23a22,22,0,0,1,22,22V83a22,22,0,0,1-22,22H45A22,22,0,0,1,23,83V45A22,22,0,0,1,45,23H83m0-8H45A30.09,30.09,0,0,0,15,45V83a30.09,30.09,0,0,0,30,30H83a30.09,30.09,0,0,0,30-30V45A30.09,30.09,0,0,0,83,15Z"/>
                                    <path className="social-cls-1" d="M90.14,32a5.73,5.73,0,1,0,5.73,5.73A5.73,5.73,0,0,0,90.14,32Z"/>
                                    <path className="social-cls-1" d="M64.27,46.47A17.68,17.68,0,1,1,46.6,64.14,17.7,17.7,0,0,1,64.27,46.47m0-8A25.68,25.68,0,1,0,90,64.14,25.68,25.68,0,0,0,64.27,38.47Z"/>
                                </svg>
                            </a>
                            <a href="https://twitter.com/flairfashion_/" target="_blank">
                                <svg data-name="Layer 2" className="ty-social-icon mx-2" id="Layer_2" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                                    <path className="social-cls-1" d="M86.94,30.66a19.58,19.58,0,0,1,14,6.17s7.64-1.5,11.86-4.47c0,0-1.06,5.88-8.09,10.38h.06c.54,0,4.48-.1,10.68-3,0,0-1.19,3.39-9.3,9.84v0c.49,15.26-7.06,43.47-35,53.51A56.36,56.36,0,0,1,52,106.52,53.69,53.69,0,0,1,22.5,97.85C24,98,25.35,98,26.7,98c16.52,0,24.52-8.48,24.08-8.48h-.61c-14,0-17.41-13.4-17.41-13.4a6.46,6.46,0,0,0,3.73.92,20.4,20.4,0,0,0,5-.73c-15.8-3.74-15.21-18.9-15.21-18.9a13.45,13.45,0,0,0,7.2,2.26,7,7,0,0,0,1.26-.1C20.31,48.15,29,34.52,29,34.52c16.26,18.87,36.25,19.54,39,19.54h.33c-1.48-8.86,2.17-17.37,12-22a15.35,15.35,0,0,1,6.54-1.4M106.2,49.6v0M122,18l-12.93,9.08a23.93,23.93,0,0,1-6.34,2.65,26.16,26.16,0,0,0-15.83-5.54h0a21.69,21.69,0,0,0-9.3,2c-9.18,4.31-14.76,11.75-15.87,20.68A49.25,49.25,0,0,1,33.94,30.28l-5.7-6.61L23.54,31a25.19,25.19,0,0,0-3.4,16.66l-.37,9.53a26.24,26.24,0,0,0,5.69,16.68l1,3.9a26.17,26.17,0,0,0,8.36,12.89,37.24,37.24,0,0,1-8.13.86c-1.19,0-2.42-.05-3.66-.15L.1,89.46l18.55,13.63A60.21,60.21,0,0,0,52,113a63,63,0,0,0,21.38-3.8,57.06,57.06,0,0,0,31.7-27.91,68.92,68.92,0,0,0,7.57-28.65c6.63-5.71,8.45-9.35,8.94-10.74l5.12-14.57-7,3.26L122,18Z"/>
                                </svg>
                            </a>
                        </div>
                        
                    </section>
                </div>
            </Fragment>
        );
    }
}
