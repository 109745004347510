import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";
import './Home.css';

export class Home extends Component {
  //static displayName = Home.name;

  constructor(props) {
    super(props);
  }

  render () {
    return (
      <div>
        <Meta title="FLAIR - The Fashion Platform For The Next Generation" />

        <div className="carousel-home mb-5 homep-b">
          <picture>
            <source media="(orientation: portrait) and (max-width: 767.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1000,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/passaro_banner_4_m.jpg" />
            <source media="(orientation: landscape) and (max-width: 767.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1000,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/passaro_banner_4_md.jpg" />
            <source media="(orientation: portrait) and (max-width: 1030px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1500,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/passaro_banner_4_sm.jpg" />
            <source media="(max-width: 991.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1600,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/passaro_banner_4_md.jpg" />
            <source media="(max-width: 1499.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1920,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/passaro_banner_4_lrg.jpg" />
            <source media="(min-width: 1500px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_2000,dpr_auto,f_auto,q_auto:best/v1682244429/TestSite/general/passaro_banner_4.jpg" />
            <img src="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1600,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/passaro_banner_4_md.jpg" className="w-100" alt="Flair" />
          </picture>
          <div className="homep-b-txt header-style">Bringing <span className="hl">Flair</span> Back To Fashion</div>
          <div className="small text-muted homep-b-credit">Photo by designer <Link to="steven-passaro" className="text-muted">Steven Passaro</Link></div>
        </div>

        <div className="container my-5 pb-5">
          <div className="row">
            <div className="col-6 header-style homep-enter text-center">
                <Link to="/shop">
                  <span className="hl">enter</span> shop
                </Link>
            </div>
            <div className="col-6 header-style homep-enter text-center">
                <Link to="/showcase">
                  <span className="hl">enter</span> showcase
                </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
