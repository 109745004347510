import React from 'react';
import { Link } from 'react-router-dom';
import './CategoriesSideListing.css';

export function CategoriesSideListing(props) {
    const { path, category } = props

    return (
        <div className="store-categories">
            <CategoriesSideItem 
                path={path} 
                category={category} />
        </div>
    );
}


export function CategoriesSideItem(props) {
    const { path, category } = props

    return (
        <div className="">
            <Link to={`${path}${category.friendlyUrlName}`} className={'' + (category.isSelected?'font-weight-bold cat-selected':'')}>
                {category.title}
            </Link>
            {category.childrenCategories && category.childrenCategories.map(childCategory =>
                <div key={childCategory.id} style={{ marginLeft: '0.8rem' }}>
                    <CategoriesSideItem path={`${path}${category.friendlyUrlName}/`} category={childCategory} />
                </div>
            )}
        </div>
    );
}
