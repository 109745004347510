import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SimpleQA } from '../layout-components/SimpleQA';
import { Meta } from "../layout-components/Meta";

export class FAQPage extends Component {
    static displayName = FAQPage.name;

    render() {
        return (
          <div className="container simple-qa px-0">
              <Meta title="FAQ | FLAIR" />

              <h1 className="fslash mb-4">FAQ</h1>

              <div className="my-5 mx-auto">
                  <h3 className="text-uppercase sp-section-header">Flair</h3>
                  <SimpleQA
                      question="What is Flair?">
                      <p>
                          We are a platform aiming to empower fashion graduates and the next generation of emerging designers to connect directly with customers. Here they can showcase their creative concepts and innovations in design, sustainability and technology.
                      </p>
                      <p>
                          We want to democratise the fashion industry and create fairer and more transparent practises, allowing our customers to discover and support brilliant independent designers.
                      </p>
                      <p>
                        <Link to="/about" className="font-weight-bold">Read about Flair's mission</Link>
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="How does the the Augmented Reality experience on Flair work?">
                      <p>
                          Watch our video guide below on using the Augmented Reality experience within Flair.
                      </p>
                      <p>
                          <iframe width="560" height="315" src="https://www.youtube.com/embed/57GDM6kkpC8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="I'm a designer, how do I join?">
                      <p>
                          Super! You can sign up and gain access to our exclusive beta, the <a href="https://portal.flair.fashion/" target="_blank" className="font-weight-bold">Flair Portal</a>. 
                          It's completely free! Join our waiting list, and once your application is approved, you'll have the opportunity to showcase your brand story and concept, as well as upload your collections and products.
                      </p>
                  </SimpleQA>
                </div>
              
              <div className="my-5 simple-qa mx-auto">
                  <h3 className="text-uppercase sp-section-header">Placing an Order</h3>
                  <SimpleQA
                      question="How do I place an order?">
                      <p>
                          Placing an order on Flair is easy - here's how you do it:
                      </p>
                      <ol>
                          <li className="mb-2">Find a product you like by browsing through the categories or designers from the top menu.</li>
                          <li className="mb-2">On the product page, select your size and click “Add to bag”. Once you've added all products you wish to purchase, it's time to checkout.</li>
                          <li className="mb-2">Click on the bag icon at the top right menu. Review and proceed to checkout.</li>
                          {/* Sign in to your account or, if you've not yet created one, proceed via guest checkout. */}
                          <li className="mb-2">Fill in your address and payment details, and place the order.</li>
                          <li className="mb-2">That's it – we'll send you an order confirmation email and keep you updated until you receive your package.</li>
                      </ol>
                  </SimpleQA>
                  
                  <SimpleQA
                      question="When will I receive my order?">
                      <p>
                          Flair supports designers and their individual making process and shipping times. Some products can be dispatched in a short time frame while others, for example made-to-order or custom-made, require a longer lead time. Check the product page details for more delivery estimates and information before placing your order.
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="What are the delivery options?">
                      <p>
                          For now we only support the standard option which is stated in the delivery information on the product page. If you need faster shipping, get in touch and we will work with the designer to try to make it happen. Please note that this may come with added charge for faster shipping.
                      </p>
                      <p>
                          We are constantly developing new features to Flair, and delivery options for individual designers in checkout will be coming in the near future.
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="I just placed an order, can I cancel or change my Flair order?">
                      <p>
                          As soon as you place you order, we notify our designers to get started. If you want to cancel or change the order before it's been dispatched, let us know as soon as possible and 
                          we will notify the designer.
                      </p>
                      <p>
                          <strong>Please note</strong>: We cannot guarantee that the cancellation or change will be accepted, as it is dependent on what stage the designer is at with the order.
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="How will I receive my order if I’m buying from multiple brands?">
                      <p>
                          When buying from multiple brands in the same order you will receive separate packages, potentially with different arrival times. As your order is directly shipped to you from the designer, it is dependent on their delivery timeframes.
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="Where do you ship to?">
                      <p>
                          At this stage, we are only shipping within the UK, however we are planning to expand to other markets in the near future. 
                      </p>
                      <p>
                          We would love to hear from you where you would like Flair's designers to ship to.
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="Do you use sustainable packaging?">
                      <p>
                          Our designers thrive on changing the industry and making it more sustainable, and this is often reflected in their packaging and shipping. At Flair we work with our designers to support this.
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="Do I need to register with Flair to place an order?">
                      <p>
                        No, all you need to provide us with is an email address and preferably a phone number too, to help us reach out to you regarding your order.
                        {/*We recommend that you register for an account to start adding pieces to your Wishlist, but, you can also place and track orders as a guest and sign up at a time that suits you.*/}
                      </p>
                  </SimpleQA>
              </div>

              <div className="my-5 simple-qa mx-auto">
                  <h3 className="text-uppercase sp-section-header">Payment</h3>
                  <SimpleQA
                      question="What payment methods do you accept?">
                      <p>
                          We accept payment from all major debit and credit cards. You can also pay using Alipay and WeChat.
                      </p>
                      <p>
                          We are working on adding more payment methods to Flair's checkout. Apple Pay and Google Pay will be added next.
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="How secure is your payment service?">
                      <p>
                          All payments on Flair are handled by our third party payment provider, <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a>. They are fully PCI compliant following global security standards for all handling, storing, processing, or transmitting of sensitive authentication and payment data.
                      </p>
                      <p>
                          We at Flair do not store or hold any payment sensitive details except for references such as last four digits of your card. All data sent from your browser to Flair and Stripe is through encrypted and secure SSL protocol, keeping your data safe.
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="If I’m purchasing from multiple designers, will I have to make multiple payments?">
                      <p>
                          No, even if you have multiple products from different designers in your bag you will only need to make one payment. To make ordering with us easier for you, we have a secure payment process which means you can order from as many designers as you want safely.
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="Are duties and taxes included?">
                      <p>
                          All prices are inclusive of tax except for products from designers based outside of the UK.
                      </p>
                      <p>
                          If you order products from a designer(s) shipping from outside the UK, you will be responsible for any import taxes and duties that may be added when the shipment reaches the UK. These charges are not determined by Flair.
                      </p>
                      <p>
                          We would recommend that you always check the Delivery tab for each product to know where the Designer is shipping from and if any import charges will be applicable.
                      </p>
                      {/*
                      <p>
                          All product prices shown on Flair are final and include tax and duties.
                      </p>
                        */}
                  </SimpleQA>
              </div>

              <div className="my-5 simple-qa mx-auto">
                  <h3 className="text-uppercase sp-section-header">Sizing And Fit</h3>
                  <SimpleQA
                      question="How do I know the item will fit me? OR How does the item fit?">
                      <p>
                          Sizing information is available on each product page, as provided by each independent designer. If you want to further confirm the size and fit or can't find the information you're looking for, get in touch with us on <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a> and we will reach out to the designer on your behalf. 
                      </p>
                      <p>
                          We recommend that you always check the sizing information before purchasing, in order to reduce returns and its impact on the environment.
                      </p>
                  </SimpleQA>
              </div>

              <div className="my-5 simple-qa mx-auto">
                  <h3 className="text-uppercase sp-section-header">Returns</h3>
                  <SimpleQA
                      question="How do I return or exchange an item?">
                      <p>
                          If you are unhappy with your purchase and wish to return or exchange your item please email <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a> and our customer service team will help you fulfil your request. 
                      </p>
                      <p>
                          All products sold on Flair adhere to one of three standard return policies - all details and instructions can be found on our <Link to="/return-policy" className="font-weight-bold">Return Policy</Link> page. 
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="How do I return my custom made order?">
                      <p>
                          Custom made products are likley to be under our partial refund policy. As these products are made for you, 50% goes towards the cost of the materials used. All details and instructions can be found on our <Link to="/return-policy" className="font-weight-bold">Return Policy</Link> page.
                      </p>
                      <p>
                          <strong>Important note</strong>: Designers might choose to have their products under the no refund or full refund policy. It's always important to check the return information on the product page before purchasing.
                      </p>
                  </SimpleQA>
              </div>

              <div className="my-5 simple-qa mx-auto">
                  <h3 className="text-uppercase sp-section-header">Technical</h3>
                  <SimpleQA
                      question="I'm having technical problems with the site, can you help?">
                      <p>
                          We are consantly monitoring the platform for any technical issues. If you're experiencing a problem, please get in touch with us as soon as possible on <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a>.
                      </p>
                  </SimpleQA>
                  <SimpleQA
                      question="Will my personal details stay safe?">
                      <p>
                          At Flair we take every measure to keep your personal details safe. For more information on how we handle and store your data, read our <Link to="/privacy-policy" className="font-weight-bold">Privacy Policy</Link>.
                      </p>
                  </SimpleQA>
              </div>
          </div>
        );
    }
}