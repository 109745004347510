import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";
import { FeaturedProducts } from '../home/FeaturedProducts';
//import { FlairFocus } from './ShopHomeComponents';
//import './DesignersHome.css';

export class ShopHome extends Component {

  constructor(props) {
    super(props);
    this.state = { data: null, loading: true };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  render () {
    if (this.state.loading)
      return (
          <p><Meta title="Shop Designers | FLAIR" /><em>Loading...</em></p>
      );
      
    const data = this.state.data;
     
    return (
      <div>
        <Meta title="Shop Designers | FLAIR" />

        <div className="row no-gutters w-full no-pPadding mb-5 pb-5">
            <div className="col-12 col-md-6 mb-3 mb-md-0">
                <Link to="/womenswear" className="d-flex align-items-center justify-content-center">
                    <picture>
                        <source media="(max-width: 767.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1000,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/flair-womenswear.jpg" />
                        <source media="(max-width: 991.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1600,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/flair-womenswear.jpg" />
                        <source media="(max-width: 1499.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1920,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/flair-womenswear.jpg" />
                        <source media="(min-width: 1500px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_2000,dpr_auto,f_auto,q_auto:best/v1682244429/TestSite/general/flair-womenswear.jpg" />
                        <img src="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1600,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/flair-womenswear.jpg" className="w-100" alt="Flair Shop Womenswear" />
                    </picture>
                    <div className="header-style homep-enter text-center position-absolute text-white text-uppercase p-3"><span className="d-block">Shop</span> Womenswear</div>
                </Link>
            </div>
            <div className="col-12 col-md-6">
                <Link to="/menswear" className="d-flex align-items-center justify-content-center">
                    <picture>
                        <source media="(max-width: 767.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1000,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/flair-menswear.jpg" />
                        <source media="(max-width: 991.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1600,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/flair-menswear.jpg" />
                        <source media="(max-width: 1499.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1920,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/flair-menswear.jpg" />
                        <source media="(min-width: 1500px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_2000,dpr_auto,f_auto,q_auto:best/v1682244429/TestSite/general/flair-menswear.jpg" />
                        <img src="https://res.cloudinary.com/flairfashion/image/upload/c_fill,w_1600,dpr_auto,f_auto,q_auto:best/v1672244429/TestSite/general/flair-menswear.jpg" className="w-100" alt="Flair Shop Menswear" />
                    </picture>
                    <div className="header-style homep-enter text-center position-absolute text-white text-uppercase p-3"><span className="d-block">Shop</span> Menswear</div>
                </Link>
            </div>
        </div>

        {/* 
        <section id="featuredesigner" className="mb-5 pb-5">
          <FlairFocus featuredDesigner={data.featuredDesigner} />

        </section>
        */}

        <section className="mb-5 pb-5">
            <h2 className="header-style page-title__h2 text-center my-4">Flair Pick of the Week</h2>
            <FeaturedProducts 
                products={data.featuredProducts}
                itemSmallBottomPadding={true}
            />
            <div className="text-center mb-5">
              <Link to="/womenswear" className="d-inline-block btn btn-outline-dark">Shop Womenswear</Link>
              <Link to="/menswear" className="d-inline-block btn btn-outline-dark ml-2">Shop Menswear</Link>
            </div>
        </section>

        <section className="mb-5 pb-5">
            <h2 className="header-style page-title__h2 text-center mt-4 mb-2">View Products In Your Space</h2>
            <p className="text-center mb-4">Using Augmented Reality</p>
            <FeaturedProducts 
                products={data.featured3D} 
                itemSmallBottomPadding={true}
            />
            <div className="text-center mb-5">
              <Link to="/womenswear" className="d-inline-block btn btn-outline-dark">Shop Womenswear</Link>
              <Link to="/menswear" className="d-inline-block btn btn-outline-dark ml-2">Shop Menswear</Link>
            </div>
        </section>

        
      </div>
    );
  }

  async fetchData() {
      const response = await fetch(`api/home/shop`, {
      });
      const data = await response.json();
      console.log(data);

      this.setState({ data: data, loading: false });

  }
}
