import React, { Component } from 'react';
import {StripeProvider,Elements} from 'react-stripe-elements';
import { Meta } from "../layout-components/Meta";
import CheckoutForm from './CheckoutForm'

export class CheckoutPage extends Component {
  static displayName = CheckoutPage.name;

  constructor () {
    super();
    this.state = {stripe: null};
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_API_PUBLIC_KEY)});
    } else {
      const stripeJs = document.createElement('script');
      stripeJs.src = 'https://js.stripe.com/v3/';
      stripeJs.async = true;
      stripeJs.onload = () => {
        //TODO
        // The setTimeout lets us pretend that Stripe.js took a long time to load
        // Take it out of your production code!
        setTimeout(() => {
          this.setState({
            stripe: window.Stripe(process.env.REACT_APP_STRIPE_API_PUBLIC_KEY),
          });
        }, 500);
      };
      document.body && document.body.appendChild(stripeJs);
    }
  }


  render() {
    if(!this.state.stripe)
      return (
        <p><Meta title="Checkout | FLAIR" /><em>Loading...</em></p>
      );

    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <CheckoutForm />
        </Elements>
      </StripeProvider>
    );
  }
}
