import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';

export class ProductDetailsTabs extends Component {
    static displayName = ProductDetailsTabs.name;

    constructor(props) {
        super(props);
        this.state = { activeTab: '1' };
        this.setActiveTab = this.setActiveTab.bind(this);
        this.anchorRef = React.createRef();
    }

    setActiveTab(tab) {
        if(this.state.tab !== tab) {
            this.setState({ 
                activeTab: tab
            });
        }
    }

    scrollIntoView(options) {
        this.anchorRef.current.scrollIntoView(options);
    }
    
    render() {
        const product = this.props.product;
        const activeTab = this.state.activeTab;

        return (
            <div className="position-relative">
                <div ref={this.anchorRef} className="position-absolute" style={{top: '-100px'}}></div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => this.setActiveTab('1')}>
                            Details
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => this.setActiveTab('2')}>
                            Size &amp; fit
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => this.setActiveTab('3')}>
                            Delivery
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === '4' ? 'active' : ''} onClick={() => this.setActiveTab('4')}>
                            Return
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="py-4">
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12" lg="5" className="mb-4">
                                <h3 className="mb-3">About {product.title}</h3>
                                <div dangerouslySetInnerHTML={ {__html: product.description} } />
                            </Col>
                            <Col xs="4" sm="4" lg="3" className="mb-4">
                                <Link to={`/${product.designer.friendlyUrlName}`}>
                                    <img
                                        src={product.designer.id < 1058 ? product.designer.portraitImage.url : process.env.REACT_APP_MEDIAURL_DESIGNER_PORTRAITLISTING + product.designer.portraitImage.url + "?v=1"}
                                        alt={product.designer.title} className="w-100" />
                                </Link>
                            </Col>
                            <Col xs="8" sm="8" lg="4" className="mb-4">
                                <h3 className="mb-3">Designed by <Link to={`/${product.designer.friendlyUrlName}`}>{product.designer.brandTitle}</Link></h3>
                                <h5><Link to={`/${product.designer.friendlyUrlName}`}>{product.designer.designerName}</Link></h5>
                                <div dangerouslySetInnerHTML={ {__html: product.designer.description} } />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                <h3 className="mb-3">Size &amp; fit</h3>
                                {this.getSizingInformation()}
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <Row>
                            <Col sm="12">
                                <h3 className="mb-3">Delivery Information</h3>
                                {this.getDeliveryInformation()}
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="4">
                        <Row>
                            <Col sm="12">
                                <h3 className="mb-3">Return Information</h3>
                                {this.getReturnInformation()}
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
        );
    }

    getDeliveryInformation() {
        return (
            <div style={{maxWidth:'600px'}}>
                <div dangerouslySetInnerHTML={ {__html: this.props.product.deliveryMessage} } />
                <hr style={{width:'100px'}} className="mr-auto ml-0 mt-4" />
                <p className="text-muted small" style={{maxWidth:'400px'}}>For delivery enquiries, contact us on <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer" className="text-muted">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer" className="text-muted">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a>.</p>
            </div>
        );
    }

    getSizingInformation() {
        return (
            <div style={{maxWidth:'600px'}}>
                <div dangerouslySetInnerHTML={ {__html: this.props.product.sizingInformation} } />
                <hr style={{width:'100px'}} className="mr-auto ml-0 mt-4" />
                <p className="text-muted small" style={{maxWidth:'400px'}}>For sizing enquiries, contact us on <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer" className="text-muted">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer" className="text-muted">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a>.</p>
            </div>
        );
    }

    getReturnInformation() {
        let message = "";
        switch(this.props.product.returnType) {
            case 2:
                message = (
                    <div>
                        <p>
                            This product is eligible for a full refund within 14 days of purchase. Excluding the order's shipping cost.
                        </p>
                        <p>
                            If returned, the product <b>must</b> be unworn, undamaged and unused, with all tags attached and the original packaging included.
                        </p>
                    </div>
                );
                break;
            case 1:
                message = (
                    <div>
                        <p>
                            This product is eligible for a partial refund of 50% within 14 days of purchase. Excluding the order's shipping cost.
                        </p>
                        <p>
                            If returned, the product <b>must</b> be unworn, undamaged and unused, with all tags attached and the original packaging included.
                        </p>
                    </div>
                );
                break;
            default:
                message = (
                    <div>
                        <p>
                            This product is not eligible for a refund.
                        </p>
                    </div>
                );
                break;
        };
        return (
            <div style={{maxWidth:'600px'}}>
                {message}
                <p>
                    We recommend that you always check the sizing information before purchasing in order to reduce returns and its impact on the environment.
                </p>
                <p>See full <Link to="/return-policy" className="font-weight-bold">Return Policy</Link></p>
                <hr style={{width:'100px'}} className="mr-auto ml-0 mt-4" />
                <p className="text-muted small" style={{maxWidth:'400px'}}>For return enquiries, contact us on <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer" className="text-muted">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer" className="text-muted">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a> if you have any enquiries.
                </p>
            </div>
        );
    }
}
