import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import './StaticPages.css';
import { SimpleQA } from '../layout-components/SimpleQA';
import { Meta } from "../layout-components/Meta";

export class JoinFlairPage extends Component {
    static displayName = JoinFlairPage.name;

    render() {
        return (
            <Fragment>
                <Meta title="Join Flair As A Designer" />

                <div className="container px-0 pt-lg-5">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h1 className="header-style page-title__h1 text-uppercase mb-4">Join Flair As A Designer</h1>
                            <h2 className="text-uppercase mb-4">A fully managed showcase space abd marketplace to share your work and grow your brand without limitations.</h2>
                            <section className="my-5">
                                <p>We're excited to announce that we're in the beta phase of developing our user-friendly portal for designers to join Flair. With this exclusive beta access, you'll be able to showcase your brand and collections, gain entry into our marketplace, and experience firsthand the innovative services we have in the pipeline.</p>
                                <p>It is completely free! Don't miss out on this opportunity and join our waiting list today.</p>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="container px-0">
                    <section className="mb-5 mt-4 mx-auto text-center" style={{maxWidth:'700px'}}>
                        <a className="btn btn-secondary btn-lg px-5 mt-3 mx-3" href="https://portal.flair.fashion" target="_blank" rel="noopener noreferrer">Take me to the Flair Portal</a>                    
                    </section>
                </div>
            </Fragment>
        );
    }
}
