import React, { Component } from 'react';
import { Meta } from "../layout-components/Meta";

export class TermsAndConditionsPage extends Component {
    static displayName = TermsAndConditionsPage.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className="container px-0" style={{maxWidth: '600px'}}>
              <Meta title="Terms & Conditions | FLAIR" />

              <h1 className="fslash mb-4 text-uppercase">Terms &amp; Conditions</h1>
              <p>Flair Fashion Limited operates an online marketplace ("Marketplace"), at 71-75 Shelton Street, London, Greater London, United Kingdom, WC2H 9JQ. In these terms we refer to Flair Fashion as "FF" or "we "or "us." We refer to end users as "you" and the entities that list and sell items as Designer(s).</p> 
              <p>The Marketplace allow sellers ("Designers") to list and sell items to end users. When you purchase an item, the contract for sale is solely between you and the Designer. That means that when you purchase an item on the Marketplace, you are making a purchase from the Designer who listed the item and the Designer is responsible for fulfilling your order. We are solely the technology company that allows the Marketplace to exist and as such are not involved in any transactions between you and the Designers.</p>
              <section className="my-4">
                  <h3>Conclusion of Contract; Registration </h3>
                  <p>You can access, save and print out the current Terms during the registration process and at any other time on our website.</p>
                  <p>FF is your contract partner when using the Marketplace. In order to make full use of the Marketplace, including viewing and purchasing items in the Marketplace, you may need to create an account ("Register"). If you buy items, the Designer is your contractual partner for the purchase.</p>
                  <p>You make a binding offer for the use of the Marketplace by Registering. Registration occurs when you supply an email address and a password or sign up using Facebook or Google, including allowing us to access certain data associated with your social network account.</p>
                  <p>The contract for the use of our Marketplace is concluded when we send an e-mail to the address you provided to us as part of the registration process ("Conclusion of Contract") after receipt of your offer. This confirmation e-mail represents our acceptance of your offer.</p>
              </section>
              <section className="my-4">
                  <h3>How the Marketplace works</h3>
                  <p>FF operates the Marketplace that allows Designers and users to share information and complete purchases. Designers are responsible for their stores, their items, their item listings and the processing and shipping of your order. Designers may be located in your country or region or elsewhere. If they are located elsewhere, this may have an effect on taxes, customs or the applicability of consumer protection or data protection rules.</p>
                  <p>Each final price is determined by or based on a price supplied by the Designer offering an item for sale. Designers provide the other information in the item listings. Designers are obligated to provide accurate information, but FF is not obligated nor able to guarantee the truth or accuracy of the information in item listings.</p>
                  <p>Although we are not a party to the contract formed between you and a Designer when you make a purchase, we may assist with payment processing, returns, refunds, and other customer service. FF does not guarantee (i) the existence, quality, safety, suitability, or legality of any Designer items or item listings; (ii) the truth or accuracy of any item descriptions or other content provided by Designers; (iii) the truth or accuracy of ratings, reviews, or other User Submissions; or (iv) the performance or conduct of any Designer or third party. FF does not endorse any Designer, item, or item listing. You should make all investigations and checks you feel are appropriate before making a purchase from a Designer. Please read labels, warnings and directions provided with a purchased item before using it.</p>
              </section>
              <section className="my-4">
                  <h3>User Requirements and Obligations</h3>
                  <p>You may be required to create an account and select a password and username. You promise to provide us with accurate, complete, and updated information about yourself. Where FF requires that you provide an email address, you are responsible for providing FF with your most current email address. You are obliged to keep your registration information, including your e-mail address, up to date and inform us about changes without undue delay by updating this information in your user account. You may not select as your username a name that you do not have the right to use, or another person's name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission.</p>
              </section>
              <section className="my-4">
                  <h3>Rules &amp; Restrictions</h3>
                  <p>You may only use the Marketplace for your own internal and personal use, and not on behalf of or for the benefit of any third party, and only in a manner that complies with applicable FF policies and all laws that apply to you. If your use of the Marketplace (including a purchase of any items on the Marketplace) is prohibited by any applicable laws, then you are not authorized to use the Marketplace.</p>
                  <p>You may not share your account or password with anyone, and you must protect the security of your account and your password. You must notify FF immediately of any unauthorized use of your password or any other breach of the security of your account. You are responsible for any activity associated with your account.</p>
                  <p>You must not create multiple accounts and you must not let others use your account.</p>
                  <p>Any sweepstakes, contests, raffles, surveys, games, or similar promotions (collectively, "Promotions") made available through our Sites may be governed by rules that are separate from these Terms of Use. If the rules for a Promotion conflict with these Terms of Use, the Promotion rules will govern.</p>
                  <p>When accessing or using the Marketplace, you agree to:</p>
                  <ol type="a">
                      <li>Abide by all laws, rules, regulations, these Terms, and all FF policies;</li>
                      <li>Only purchase items you are legally able to purchase in your jurisdiction;</li>
                      <li>Only use (or attempt to use) the Marketplace through interfaces provided by Us; and</li>
                      <li>Comply with the instructions in any robots.txt file present on the Marketplace.</li>
                  </ol>
                  <p>When accessing or using the Marketplace, you agree not to:</p>
                  <ol type="a">
                      <li>Infringe or violate the intellectual property rights or any other rights of anyone else (including FF);</li>
                      <li>Violate these Terms, FF policies, or any law or regulation, including any applicable export control laws;</li>
                      <li>Engage in activity that is deceptive, threatening, defamatory or obscene;</li>
                      <li>Circumvent any terms, policies or rules set forth on the Marketplace including those relating to any promotion, contest or program on the Marketplace;</li>
                      <li>Collect personal data from users of the Marketplace or use any such information found on the Marketplace;</li>
                      <li>Engage in activity that jeopardizes the security of your account or anyone else's (such as allowing someone else to log in to the Marketplace as you);</li>
                      <li>Attempt, in any manner, to obtain the password, account, or other security information from any other user;</li>
                      <li>Use a third party's credentials, conceal your true IP address, or otherwise impersonate or misrepresent your identity, your affiliation with any person or entity;</li>
                      <li>Violate the security of any computer network, or crack any passwords or security encryption codes;</li>
                      <li>Run Maillist, Listserv, any form of auto-responder or "spam" on the Marketplace, or any processes that run or are activated while you are not logged into the Marketplace, or that otherwise interfere with the proper working of the Marketplace (including by placing an unreasonable load on the Marketplace' infrastructure);</li>
                      <li>"Crawl," "scrape," or "spider" any page, data, or portion of or relating to the Marketplace or Content (through use of manual or automated means);</li>
                      <li>Decompile, reverse engineer, or otherwise attempt to obtain the source code or underlying ideas or information of or relating to the Marketplace; or</li>
                      <li>Attempt to do anything, or permit, encourage, assist, or allow any third party to do anything, prohibited in this list of items, or attempt, permit, encourage, assist, or allow any other violation of these Terms.</li>
                  </ol>
              </section>
              <section className="my-4">
                  <h3>Privacy</h3>
                  <p>Our Privacy Policy provides information regarding how we collect, use and disclose personal data, including with Designers. Please review the Privacy Policy for details.</p>
              </section>
              <section className="my-4">
                  <h3>User Submissions</h3>
                  <p>Anything you post, upload, share, store, or otherwise provide through the Marketplace is a "User Submission." User Submissions may include suggestions, comments, ideas, improvements, or other feedback or materials, and may be viewable by other users. In order to display your User Submissions on the Marketplace, you grant us certain rights in those User Submissions.</p>
                  <p>For all User Submissions, you hereby grant FF free of charge a royalty-free, perpetual, non-exclusive, transferable, sublicensable, worldwide license to use, store, display, reproduce, save, modify, create derivative works, perform, distribute, translate or otherwise act with respect to such User Submissions as we determine is necessary to operate, market and advertise the Marketplace, including to present display or perform such User Submission in accordance with your preferences.</p>
                  <p>FF will be free to use, disclose, reproduce, modify, license, transfer and otherwise distribute, and exploit the User Submissions in any manner.</p>
              </section>
              <section className="my-4">
                  <h3>Ratings, Reviews and other User Feedback</h3>
                  <p>FF enables you to generate ratings and reviews, photos, videos or other content about items and stores on the Marketplace ("Feedback"). Feedback is intended to enable other users to get a meaningful picture of the items or the store.</p>
                  <p>Feedback may only contain truthful information. Feedback must be in accordance with the law and may not violate the rights of third parties, in particular personal rights, trademark rights or copyrights, as well as data protection regulations. You agree: (i) to base any rating or review you post only on your first-hand experience with the applicable business, product, or service; (ii) you will not provide a rating or review for any business, product, or service with respect to which you have a competitive, ownership or other economic interest, employment relationship or other affiliation; (iii) you will prominently indicate if your review was sponsored or paid for in any way; and (iv) your review will comply with these Terms.</p>
                  <p>If you abuse the feedback system, FF is entitled to remove the Feedback taking your legitimate interests into account, in particular your right to freedom of expression. This applies particularly if:</p>
                  <ol type="a">
                      <li>FF is legally obliged to remove the Feedback, e.g. due to a court ruling</li>
                      <li>the Feedback contains vulgar, obscene, racist, not youth-free or in the criminal sense insulting statements</li>
                      <li>the Feedback contains personal data, e.g. name, address, telephone number and/or e-mail-address</li>
                      <li>the Feedback contains inappropriate links or scripts, e.g. with harmful content</li>
                      <li>the Feedback contains inappropriate advertising for third parties</li>
                      <li>the Feedback for items contains irrelevant information not related to the item, e.g. customer service of the store, shipping of the item, or site usability issues.</li>
                  </ol>
                  <p>Ratings and reviews and photos, videos or other content about items and stores posted by users on our Marketplace are User Submissions that are not endorsed by FF and do not represent the views of FF. FF does not assume liability for ratings and reviews or for any claims for economic loss resulting from such ratings and reviews. We reserve the right to exclude such Feedback for any reason or no reason, without notice to you.</p>
              </section>
              <section className="my-4">
                  <h3>Content</h3>
                  <p>The materials displayed or performed or available on or through the Marketplace, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions, and so forth (all of the foregoing, the "Content") are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Marketplace, and you won't use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content and/or (ii) in a way that violates someone else's (including FF's) rights.</p>
                  <p>You may not modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Marketplace.</p>
                  <p>Subject to your compliance with these Terms, FF or its content providers grant you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal and non-commercial use of the Marketplace.</p>
              </section>
              <section className="my-4">
                  <h3>Responsibilities</h3>
                  <p>Any information or Content publicly posted or privately transmitted through the Marketplace is the sole responsibility of the person from whom such information or Content originated. You access all such information and Content at your own risk.</p>
                  <p>You are responsible for all Content you contribute, in any manner, to the Marketplace, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it.</p>
                  <p>The Marketplace may contain links or connections to third party websites or Marketplace that are not owned or controlled by FF. When you access third party websites or use third party Marketplace, you accept that there are risks in doing so. We encourage you to be aware when you leave the Marketplace and to read the terms and conditions and privacy policy of each third party website or service that you visit or utilize.</p>
                  <p>FF has no control over the content, accuracy, privacy policies, or practices of or opinions expressed in any third party websites or by any third party that you interact with through the Marketplace. In addition, FF will not and cannot monitor, verify, censor or edit the content of any third party site or service.</p>
                  <p>Your interactions with organizations and/or individuals found on or through the Marketplace, including payment and delivery of goods or Marketplace, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction with any of these third parties.</p>
                  <p>If there is a dispute solely between participants on this site, or solely between users and a third party, you agree that FF is under no obligation to become involved.</p>
              </section>
              <section className="my-4">
                  <h3>Costs and Fees</h3>
                  <p>It is currently free to access and browse the Marketplace. However, you may choose to purchase items through the Marketplace from Designers, and you alone will be responsible for paying the purchase price of any items you buy, in addition to any applicable taxes and shipping costs, if any. FF is not your contract partner when purchasing items from Designers. As tax rules evolve, FF may calculate and collect taxes applicable to some purchases.</p>
                  <p>In the event a Designer needs to make a change to your order, FF will attempt to notify you by contacting the email address, billing address, and/or phone number provided at the time the order at the Designer was made.</p>
                  <p>By confirming your purchase at the end of the checkout process, you agree to pay the applicable fees as well as any shipping and handling charges or the applicable taxes. You must timely pay all fees or amounts associated with your use of the Marketplace.</p>
              </section>
              <section className="my-4">
                  <h3>Third Party Checkout Service.</h3>
                  <p>When you purchase goods through the Marketplace, we will direct you through a checkout process operated by a third party, called Stripe. Use of Stripe’s payment processing is subject to Stripe terms and conditions and we encourage you to read these before proceeding. BY making a purchase on the Marketplace, you confirm you agree to Stripe’s terms and conditions.</p>
              </section>
              <section className="my-4">
                  <h3>Refunds, Exchanges and Related</h3>
                  <p>We want you to be satisfied with your purchase on the Marketplace. Therefore, we have asked our Designers to offer you refunds and returns if you have a problem with an order or purchase in line with their refunds policy, which is available on the Marketplace and you should read prior to purchasing from a specific Designer. FF shall regularly write and encourage Designers to operate by their refund policy. However, we cannot guarantee that the Designers will refund or exchange any item you purchase and we will not be held liable if they refuse to do so.</p>
              </section>
              <section className="my-4">
                  <h3>Term; Termination</h3>
                  <p>This agreement shall commence when you start using the Marketplace and runs for an indefinite period of time, unless terminated in accordance with these Terms.</p>
                  <p>You are free to stop using the Marketplace at any time. Any unpaid amounts you owe Designers for items purchased will remain due. FF is also free to terminate (or suspend access to) your use of the Marketplace or your account, for any reason in our discretion, including your breach of these Terms upon two weeks' notice.</p>
                  <p>The right to terminate this agreement for good cause remains unaffected.</p>
                  <p>Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.</p>
              </section>
              <section className="my-4">
                  <h3>Changes to Terms or Marketplace</h3>
                  <p>FF has the right to change the contractually agreed Marketplace and these Terms if and to the extent that this is necessary for a valid reason that could not have been foreseen at the time the contract was concluded, and this change is reasonable for the user, in particular because the relationship between performance and counter performance does not significantly change to your disadvantage.</p>
                  <p>FF will provide appropriate notice regarding changes to the Marketplace or these Terms ("Notice of Changes").</p>
                  <p>In the event of changes to the contractually agreed Marketplace and these Terms, you have the right to terminate this contract in text form at the time the changes take effect.</p>
                  <p>Changes to the contractually agreed Marketplace and these Terms are deemed approved by you if you do not expressly object to them after receipt of the Notice of Changes. FF specifically draws your attention to this legal consequence, the commencement of the period, the period and the date on which the change takes effect. In the absence of such notice or if it is incomplete, the lack of objection does not constitute acceptance of the changes.</p>
                  <p>If you object to the changes, FF has the right to terminate this contract in text form at the time the changes take effect.</p>
              </section>
              <section className="my-4">
                  <h3>Limitation of Liability</h3>
                  <p>To the extent permitted by applicable law, FF limits or excludes its liability for: (i) your use of the Marketplace, including any downtime or periods of unavailability; (ii) products, information or other content provided by third parties; (iii) the use of products purchased from Designers; or (iv) any indirect, consequential or special damages. If FF has any liability to you, then to the extent permitted by applicable law you agree that: (i) you shall take reasonable measures to limit any damages incurred; and (ii) FF's liability shall be limited to those damages that were a foreseeable and direct result of its conduct. Except for liability for death or personal injury caused by FF, FF's liability shall not exceed the greater of (i) the aggregate amount of your purchases on the Marketplace in the six (6) months preceding the event on which your claim is based; or (ii) £200.</p>
              </section>
              <section className="my-4">
                  <h3>General</h3>
                  <h4>Electronic Communications</h4>
                  <p>Notices or declarations can be made in text form , including by email. The communication between FF and you take place electronically, e.g. by emails, texts, mobile push notices, or notices and messages on the Marketplace. If you us to provide FF formal notice via email, the message(s) must be sent to <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a>.</p>
                  <h4>Assignment</h4>
                  <p>We may transfer, assign, or delegate these Terms and our rights and obligations with prior notice to you.</p>
                  <h4>Force Majeure</h4>
                  <p>Should the parties be prevented from fulfilling their contractual obligations by force majeure such as natural disasters, war, terrorism, industrial disputes, sovereign orders or other circumstances which are beyond their control or the prevention of which cannot be achieved with reasonable technical or economic effort, the parties are released from their contractual obligations as long as these circumstances and their consequences have not been finally eliminated. In all the above-mentioned cases of release from performance, the parties are not entitled to claim damages unless the party claiming force majeure is at fault.</p>
                  <h4>Entire Agreement</h4>
                  <p>These Terms together with our Privacy Policy set forth the entire understanding and agreement between you and FF and supersede all prior understandings and agreements of the parties.</p>
                  <h4>Waiver</h4>
                  <p>Our failure to respond to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches.</p>
                  <h4>Governing Law</h4>
                  <p>These Terms shall be governed by and interpreted in accordance with the laws of England. You and we agree to submit to the exclusive jurisdiction of the courts of England for all disputes arising from the use of the Marketplace.</p>
              </section>
            </div>
        );
    }
}
