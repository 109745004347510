import React, { Component, Fragment } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { Redirect } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";

import checkoutService from '../api/CheckoutService';
import './CheckoutCompletePage.css';

export class CheckoutCompletePage extends Component {
  static displayName = CheckoutCompletePage.name;

  constructor (props) {
    super(props);
    const { orderId } = this.props.match.params

    const search =this.props.location.search;
    const params = new URLSearchParams(search);

    this.state = {
        orderId: orderId,
        redirectStatus: params.get('redirect_status'),
        status: null, 
        loading: true
    };
    this.player = React.createRef();
    this.freezeAnimLastFrame = this.freezeAnimLastFrame.bind(this);
  }

  componentDidMount() {
    this.fetchData(this.state.orderId);
}

  getErrorMessage() {
    if(!this.state.error || this.state.error.length <= 0)
        return;
    let message;
    if(this.state.error) {
        message = (
            <Fragment>
                <hr />
                <p className="mb-0">{this.state.error}</p>
            </Fragment>
        );
    }
    return (
        <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Oh no!</h4>
            <p>There was an error when processing your order. Please check your information and try again.</p>
            <p>If this continues please get in touch with us to see how we can help resolve the issue. <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a></p>
            {message}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
  }

  render() {
    if (this.state.loading)
        return (
            <p><Meta title="Checkout Complete | FLAIR" /><em>Loading...</em></p>
        );

    if (this.state.status === 'none')
        return (
            <Redirect to="/" />
        );

    const { status, redirectStatus } = this.state;

    let animUrl, animFrame, title, message;

    if(status === 'checkoutfailed' ||
       (status === 'checkoutprocess' && redirectStatus === 'failed')) {
        animUrl = 'https://assets10.lottiefiles.com/packages/lf20_imrP4H.json';
        animFrame = 60;
        title = 'The payment was unsuccessful';
        message = (
            <div className="ty-message my-4">
                Unfortunately there seems to be an issue with your payment and it has been declined. Please try again or get in touch with the team if you need any assistance. <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a>.
            </div>
        );
    } else {
        animUrl = 'https://assets8.lottiefiles.com/packages/lf20_sO9js3.json';
        animFrame = 47;
        title = 'Thank you for your order!';
        message = (
            <div className="ty-message my-4">
                We're very pleased that you've made a purchase from our talented designer(s). Your payment is now being processed and you will receive an email once it's been confirmed. In the meantime, share your experience and what you have bought from Flair <span className="font-weight-bold">#BoughtOnFlair</span>
            </div>
        );
    }


    return (
        <div className="ty">
            <Meta title="Checkout Complete | FLAIR" />
            <Player
                ref={this.player}
                autoplay
                onEvent={(event, t, a, c) => {
                    if (event === 'frame') this.freezeAnimLastFrame(animFrame);
                }}                
                background="transparent"
                src={animUrl}>
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
            <h1 className="ty-header text-center mb-5">{title}</h1>
            <h2 className="text-center">Order # {this.state.orderId}</h2>
            {message}
            <h2 className="fslash text-center mt-5">Follow us on</h2>
            <div className="ty-message my-4 text-center">
                <a href="https://www.instagram.com/flair.fashionofficial/" target="_blank">
                    <svg data-name="Layer 1" className="ty-social-icon mx-2" id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                        <path className="social-cls-1" d="M83,23a22,22,0,0,1,22,22V83a22,22,0,0,1-22,22H45A22,22,0,0,1,23,83V45A22,22,0,0,1,45,23H83m0-8H45A30.09,30.09,0,0,0,15,45V83a30.09,30.09,0,0,0,30,30H83a30.09,30.09,0,0,0,30-30V45A30.09,30.09,0,0,0,83,15Z"/>
                        <path className="social-cls-1" d="M90.14,32a5.73,5.73,0,1,0,5.73,5.73A5.73,5.73,0,0,0,90.14,32Z"/>
                        <path className="social-cls-1" d="M64.27,46.47A17.68,17.68,0,1,1,46.6,64.14,17.7,17.7,0,0,1,64.27,46.47m0-8A25.68,25.68,0,1,0,90,64.14,25.68,25.68,0,0,0,64.27,38.47Z"/>
                    </svg>
                </a>
                <a href="https://twitter.com/flairfashion_/" target="_blank">
                    <svg data-name="Layer 2" className="ty-social-icon mx-2" id="Layer_2" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                        <path className="social-cls-1" d="M86.94,30.66a19.58,19.58,0,0,1,14,6.17s7.64-1.5,11.86-4.47c0,0-1.06,5.88-8.09,10.38h.06c.54,0,4.48-.1,10.68-3,0,0-1.19,3.39-9.3,9.84v0c.49,15.26-7.06,43.47-35,53.51A56.36,56.36,0,0,1,52,106.52,53.69,53.69,0,0,1,22.5,97.85C24,98,25.35,98,26.7,98c16.52,0,24.52-8.48,24.08-8.48h-.61c-14,0-17.41-13.4-17.41-13.4a6.46,6.46,0,0,0,3.73.92,20.4,20.4,0,0,0,5-.73c-15.8-3.74-15.21-18.9-15.21-18.9a13.45,13.45,0,0,0,7.2,2.26,7,7,0,0,0,1.26-.1C20.31,48.15,29,34.52,29,34.52c16.26,18.87,36.25,19.54,39,19.54h.33c-1.48-8.86,2.17-17.37,12-22a15.35,15.35,0,0,1,6.54-1.4M106.2,49.6v0M122,18l-12.93,9.08a23.93,23.93,0,0,1-6.34,2.65,26.16,26.16,0,0,0-15.83-5.54h0a21.69,21.69,0,0,0-9.3,2c-9.18,4.31-14.76,11.75-15.87,20.68A49.25,49.25,0,0,1,33.94,30.28l-5.7-6.61L23.54,31a25.19,25.19,0,0,0-3.4,16.66l-.37,9.53a26.24,26.24,0,0,0,5.69,16.68l1,3.9a26.17,26.17,0,0,0,8.36,12.89,37.24,37.24,0,0,1-8.13.86c-1.19,0-2.42-.05-3.66-.15L.1,89.46l18.55,13.63A60.21,60.21,0,0,0,52,113a63,63,0,0,0,21.38-3.8,57.06,57.06,0,0,0,31.7-27.91,68.92,68.92,0,0,0,7.57-28.65c6.63-5.71,8.45-9.35,8.94-10.74l5.12-14.57-7,3.26L122,18Z"/>
                    </svg>
                </a>
            </div>

        </div>
    );
  }

  async fetchData(orderId) {    
    const data = await checkoutService.getCheckoutCompleteStatus(orderId);
    if(data !== null && data.order) {
        this.setState({ 
            status: data.order.status, 
            loading: false
        });
    } else {
        this.setState({ 
            status: 'none', 
            loading: false
        });
    }
  }

  freezeAnimLastFrame(frame) {
    //bug in player, doesn't stop at last frame after the animation finished playing
    //https://github.com/LottieFiles/lottie-react/issues/9
    //this.player.current.pause();
    //this.player.current.setSeeker(frame, false);
    //console.log('pause');

    console.log(this.player.current.state.seeker);
     if (this.player.current.state.seeker >= frame-2) {
        console.log('pause');
        this.player.current.pause();
     }
  }
}
