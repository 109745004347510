import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";
import './StaticPages.css';

const EMBED_URL = 'https://embed.twitch.tv/embed/v1.js';
const CHANNEL = 'flairfashion';


export class FlairClass21 extends Component {
    static displayName = FlairClass21.name;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let embed;
        const script = document.createElement('script');
        script.setAttribute(
          'src',
          EMBED_URL
        );
        script.addEventListener('load', () => {
          embed = new window.Twitch.Embed('twitch-embed', {
            width: '100%',
            height: '100%',
            channel: CHANNEL,
           });
        });
        document.body.appendChild(script);
    }
    
    render() {
        return (
            <Fragment>

                <Meta title="Flair Class of 2021 Showcase Event | FLAIR"
                    description="Join us to celebrates the coming generation of designers from the Class of 2021 on 22nd of September at Westfield Startford, in collaboration with Fashion District. Showcasing their work both virtually and physically." />
                
                <div className="mb-5 wt-banner">
                    <div id="twitch-embed" style={{width:'100%', height: '100vh'}}></div>
                    {/*
                    <picture>
                        <source media="(max-width: 767.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_scale,h_1000,w_1500,dpr_auto,f_auto,q_auto:best/v1704753762/TestSite/class21showcase/Flair-Class21-Showcase-banner_mobile.jpg" />
                        <source media="(min-width: 768px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_scale,w_1920,dpr_auto,f_auto,q_auto:best/v1704753762/TestSite/class21showcase/Flair-Class21-Showcase-banner.jpg" />
                        <img src="https://res.cloudinary.com/flairfashion/image/upload/c_scale,w_1920,dpr_auto,f_auto,q_auto:best/v1604753762/TestSite/class21showcase/Flair-Class21-Showcase-banner.jpg" className="w-100" alt="Flair Class21 Showcase Event" />
                    </picture>
                     */}
                </div>

                <div className="mx-auto" style={{maxWidth:'700px'}}>
                    <h3 className="text-center">Flair presents</h3>
                    <h1 className="text-center" style={{fontSize:"3em"}}>Class of 2021 Showcase</h1>

                    <div className="my-4 text-center">
                        <h2>Wednesday, September 22nd<br/>11.00am – 1.30pm</h2>
                        <div className="text-muted mb-2 mt-3">Location:</div>
                        <h2>Westfield Stratford City<br/>Montfichet Road<br/>London<br/>E20 1EJ</h2>
                    </div>

                    <div className="my-4">
                        <p>
                            Join us to celebrates the coming generation of designers from the Class of 2021, in collaboration with <a href="https://www.fashion-district.co.uk/" target="_blank">Fashion District</a>. At this event you’ll get to meet with designers showcasing their work both virtually and physically. There will also be live interviews with the designers, where you’ll learn more about the ideas behind their collections, their brand directions and plans for the future. In addition, we’re pleased to welcome Co-founder and CEO of <a href="https://compareethics.com/" target="_blank">Compare Ethics</a>, Abbie Morris, to take part in an important discussion around sustainability and production. 
                        </p>
                        <p>
                            We would love for you to join us even if you can’t be there in person, that’s why all elements will be accessible virtually too, and you will be able to interact with the designers via live stream on the day. A fully packed session of meeting designers, great discussions, PLUS there will be a surprise announcement at the end. This is an event you don’t want to miss!
                        </p>

                        <section>
                            <div style={{maxWidth: "500px"}} className="mx-auto mt-5">
                                <h2 className="text-center">Event agenda</h2>
                                <hr/>
                                <div className="row py-2">
                                    <div className="col-3 text-right text-muted">
                                        11am
                                    </div>
                                    <div className="col-9">
                                        <h3>Welcome to Flair</h3>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row py-2">
                                    <div className="col-3 text-right text-muted">
                                        11.10am
                                    </div>
                                    <div className="col-9">
                                        <h3>Talk with Abbie Morris, Co-Founder, Compare Ethics</h3>
                                        <p>Abbie is co-founder of Compare Ethics, building a verification technology that turns sustainability data into a tangible return on investment and connecting conscious consumers with verified sustainable products.</p>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row py-2">
                                    <div className="col-3 text-right text-muted">
                                        11.35am
                                    </div>
                                    <div className="col-9">
                                        <h3>Designers Share</h3>
                                        <p>5 designers sharing their journeys and introducing their work via 2 min video bites.</p>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row py-2">
                                    <div className="col-3 text-right text-muted">
                                        11.45am
                                    </div>
                                    <div className="col-9">
                                        <h3>Talk with Jack Hebden and Bea Bruecker</h3>
                                        <p>Interviews with Jack Hebden (LCF graduate) and Bea Bruecker (RCA graduate), delving into their lives and experiences as fashion graduates.</p>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row py-2">
                                    <div className="col-3 text-right text-muted">
                                        12.10pm
                                    </div>
                                    <div className="col-9">
                                        <h3>Designers Share</h3>
                                        <p>5 designers sharing their journeys and introducing their work via 2 min video bites.</p>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row py-2">
                                    <div className="col-3 text-right text-muted">
                                        12.20pm
                                    </div>
                                    <div className="col-9">
                                        <h3>Couch talk</h3>
                                        <p>The Flair team will be taking questions as well as making an exciting announcement...</p>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row py-2">
                                    <div className="col-3 text-right text-muted">
                                        12.30pm
                                    </div>
                                    <div className="col-9">
                                        <h3>Physical showcasing &amp; Networking</h3>
                                        <p>This is your chance to network with other creative minds while meeting the designers and getting a glimpse of their work through physical showcasing.</p>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        </section>

                        <div className="text-center my-5">
                            <h2 className="mb-3">How to attend:</h2>
                            <div className="d-inline-block text-center">
                                <a className="btn btn-secondary btn-lg px-5 mt-3 mb-2 mx-3" href="https://www.eventbrite.co.uk/e/flair-class-of-2021-showcase-tickets-167592394363" target="_blank">Get your ticket</a>
                                <br/>
                                In Westfield Stratford
                            </div>
                            <div className="line-divider"></div>
                            <div className="d-inline-block text-center">
                                <a className="btn btn-secondary btn-lg px-5 mt-3 mb-2 mx-3" href="https://www.twitch.tv/flairfashion" target="_blank">Watch Live Stream</a>
                                <br/>
                                On Twitch
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container my-5 pt-5">
                    <a href="https://www.fashion-district.co.uk/festival/" target="_blank">
                        <picture>
                            <source media="(max-width: 767.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_crop,h_1000,w_1500,dpr_auto,f_auto,q_auto:best/v1604753762/TestSite/class21showcase/Fashion-District-Festival-Banner.jpg" />
                            <source media="(min-width: 768px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_scale,w_1920,dpr_auto,f_auto,q_auto:best/v1604753762/TestSite/class21showcase/Fashion-District-Festival-Banner.jpg" />
                            <img src="https://res.cloudinary.com/flairfashion/image/upload/c_scale,w_1920,dpr_auto,f_auto,q_auto:best/v1604753762/TestSite/class21showcase/Fashion-District-Festival-Banner.jpg" className="w-100" alt="Flair Class21 Showcase Event" />
                        </picture>
                    </a>
                </div>
            </Fragment>
        );
    }
}
