import React from 'react';
import { Link } from 'react-router-dom';

export function CheckoutSideSummary(props) {
    const { basket, processing } = props;

    let content = basket.basketItemsGroups.map((group) =>
      <li key={group.designer.id.toString()} className="list-group-item">
        <ul className="list-unstyled">
          <li className="pb-2">
              By {group.designer.brandTitle}
          </li>
          {
            group.basketItems.map((item) =>
              <li key={item.productSizeVariantId.toString()} className="d-flex justify-content-between 1h-condensed pb-2">
                <div className="pr-2">
                  {item.quantity} x {item.product.title}
                  <div>
                    <small className="text-muted pr-3">{item.product.unitPriceCurrency}</small>
                    <small className="text-muted pr-3">{item.sizeVariantTitle}</small>
                  </div>
                </div>
                <span className="text-muted">{item.totalCostCurrency}</span>
              </li>
            )
          }
          <li className="d-flex justify-content-between 1h-condensed">
            <div className="pr-2">
              Shipping
            </div>
            <span className="text-muted">{group.designerShippingCostCurrency}</span>
          </li>
        </ul>
      </li>
    );


    return (
        <div className="sticky-top" style={{top: '100px'}}>
          <h2 className="d-flex justify-content-between align-items-center mb-3">Your Bag ({basket.totalItems})</h2>
          <ul className="list-group mb-3">
            {content}
            <li className="list-group-item d-flex justify-content-between 1h-condensed">
              <h6 className="pr-2">Total cost</h6>
              <h6>{basket.totalCostCurrency}</h6>
            </li>
          </ul>
          <div className="mt-3">
            <button type="submit" disabled={processing} className="btn btn-dark btn-lg w-100 mb-2">{processing ? 'Processing...' : 'Place your order'}</button>
            <div className="mt-2 mb-4 small text-muted">
              By placing your order, you agree to our <Link to="/terms-and-conditions" target="_blank" className="text-underline">Terms &amp; Conditions</Link>, <Link to="/privacy-policy" target="_blank" className="text-underline">Privacy</Link> and <Link to="return-policy" target="_blank" className="text-underline">Returns</Link> policies. You also consent to your data being stored with Flair and your shipping address to be shared with the designer(s) solely for delivery purposes.
            </div>
          </div>
        </div>
    );
}

