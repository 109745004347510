import React, { Component, Fragment } from 'react';
import '@google/model-viewer/dist/model-viewer'
import 'focus-visible';
import './StoryAR.css';
import QRCode from 'qrcode.react';

export default class StoryAR extends Component {
    static displayName = StoryAR.name;
   
    componentDidMount() {
        console.log(this.props.row);
    }
    
    isMobile() {
        return (/Android|iPhone|iPad|Opera Mini/i.test(navigator.userAgent) ||
                    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints && navigator.maxTouchPoints > 1)) 
                && !window.MSStream ;
    }

    render() {
        if(!this.props.row) {
            return(<Fragment />);
        }

        let content;

        if(this.props.row.type === 'ar-content') {
            content = 
                <ARContent 
                    row={this.props.row}
                    isMobile={this.isMobile()} />;
        } else if(this.props.row.type === 'ar-content-external') {
            content =
                <ARExternalContent 
                    row={this.props.row}
                    isMobile={this.isMobile()} />;
        } else {
            return(<Fragment />);
        }

        var containerProps = {};
        if (this.props.arRef)
            containerProps.ref = this.props.arRef;        

        return (
        <div {...containerProps} className="my-5 ar-container">
            <div className="row">
                {content}
            </div>
        </div>
        )
    }
}

function ARContent(props) {
    let message;

    if(props.isMobile) {
        message = "";
    } else {
        message = (
            <div>
                <QRCode 
                    value={`${process.env.REACT_APP_URL_DEFAULT_DOMAIN}${props.row.data.qrCode.url}`}
                    size={128}
                    bgColor="#fff"
                    fgColor="#000"
                    level="H"
                    className="ar-qrcode my-5"
                    renderAs={"svg"}
                    imageSettings={{
                        src: "https://res.cloudinary.com/flairfashion/image/upload/bo_30px_solid_rgb:ffffff,c_scale,f_auto,q_auto:best,w_200/v1598440140/TestSite/Logos/FlairLogo-09_lfllpn.jpg",
                        x: null,
                        y: null,
                        height: 37.3,
                        width: 50,
                        excavate: true
                    }}
                />
                <p>Scan this QR code on your AR supported device to trigger the augmented reality experience</p>
                <p className="small">* iPhone 6S or above | iPad2017/Pro | Selected Android devices</p>
            </div>
        );
    }

    return (
        <Fragment>
            <div className={`col-12 ar3d ${props.isMobile ? 'mobile col-md-10 col-lg-7' : 'col-md-7'}`}>
                <model-viewer 
                    src={props.row.data.glbUrl.url}
                    ios-src={props.row.data.usdzUrl.url}
                    ar
                    modes="webxr scene-viewer quick-look" 
                    quick-look-browsers="safari chrome"
                    ar-scale="auto" 
                    camera-controls
                    auto-rotate
                    shadow-intensity="1">
                    <button slot="ar-button" type="button" className="btn btn-primary ar-btn">
                        <svg version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <line className="st3-ar" x1="4" x2="28" y1="24" y2="24"/>
                            <polygon className="st3-ar" points="16,2 10,5 16,8 22,5 "/>
                            <polyline className="st3-ar" points="10,5 10,12 16,15 22,12 22,5 "/>
                            <line className="st3-ar" x1="16" x2="16" y1="8" y2="15"/>
                            <path className="st3-ar" d="M28.5,23.4l-3.9-4.7c-0.4-0.5-0.9-0.7-1.5-0.7H8.9c-0.6,0-1.2,0.3-1.5,0.7l-3.9,4.7C3.2,23.8,3,24.3,3,24.7V27  c0,1.1,0.9,2,2,2h22c1.1,0,2-0.9,2-2v-2.3C29,24.3,28.8,23.8,28.5,23.4z"/>
                        </svg>
                        View in your space
                    </button>
                </model-viewer>
            </div>
            <div className={`col-12 col-md-5 ${props.isMobile ? 'd-none' : 'd-md-flex align-items-center'}`}>
                <div className="my-4 mx-auto ar-info text-center">
                    {message}
                </div>
            </div>
        </Fragment>
    );
}

function ARExternalContent(props) {
    if(props.isMobile) {
        return (
            <Fragment>
                <div className={`col-12 arexternal mobile d-flex align-items-center`}>
                    <div className="my-4 mx-auto ar-info text-center">
                        <a href={props.row.data.contentUrl} target="_blank" className="btn btn-primary ar-btn-external my-4">
                            <svg version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <line className="st3-ar" x1="4" x2="28" y1="24" y2="24"/>
                                <polygon className="st3-ar" points="16,2 10,5 16,8 22,5 "/>
                                <polyline className="st3-ar" points="10,5 10,12 16,15 22,12 22,5 "/>
                                <line className="st3-ar" x1="16" x2="16" y1="8" y2="15"/>
                                <path className="st3-ar" d="M28.5,23.4l-3.9-4.7c-0.4-0.5-0.9-0.7-1.5-0.7H8.9c-0.6,0-1.2,0.3-1.5,0.7l-3.9,4.7C3.2,23.8,3,24.3,3,24.7V27  c0,1.1,0.9,2,2,2h22c1.1,0,2-0.9,2-2v-2.3C29,24.3,28.8,23.8,28.5,23.4z"/>
                            </svg>
                            Instagram AR Filter
                        </a>
                        <p>Open this on your mobile device with Instgram to experience the AR filter</p>
                    </div>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className="col-12 arexternal d-flex align-items-center">
                <div className="my-4 mx-auto ar-info text-center">
                    <QRCode 
                        value={props.row.data.contentUrl}
                        size={128}
                        bgColor="#fff"
                        fgColor="#000"
                        level="H"
                        className="ar-qrcode my-5"
                        renderAs={"svg"}
                        imageSettings={{
                            src: "https://res.cloudinary.com/flairfashion/image/upload/bo_30px_solid_rgb:ffffff,c_scale,f_auto,q_auto:best,w_200/v1598440140/TestSite/Logos/instagram.jpg",
                            x: null,
                            y: null,
                            height: 37,
                            width: 37,
                            excavate: true
                        }}
                    />
                    <p>Scan this QR code on your mobile device to trigger the Instagram filter</p>
                </div>
            </div>
        </Fragment>
    );
}
  