import React from 'react';
import Glider from 'react-glider';
import 'glider-js/glider.min.css';
import "./CarouselSlider.css";

export default function CarouselSlider(props) {

  const gliderProps = {
    className: props.sliderClassName ?? '',
    slidesToShow: props.slidesToShow ?? 4,
    draggable: props.draggable ?? true,
    hasArrows: props.hasArrows ?? true,
    scrollLock: props.scrollLock ?? true,
    hasDots: props.hasDots ?? false,
    itemWidth: props.itemWidth ?? undefined,
    exactWidth: props.exactWidth ?? undefined,
    responsive: props.responsive ?? undefined,
    arrows: props.sliderNextButton && props.sliderPreviousButton ? {
        prev: props.sliderPreviousButton,
        next: props.sliderNextButton,
      } : undefined,
  };

  const wrapperProps = {
    className: props.wrapperClassName ?? ''
  };

  if (props.itemsAlignment === 'middle') {
    wrapperProps.className += ' glider-itemsalign-middle';
  } else if (props.itemsAlignment === 'bottom') {
      wrapperProps.className += ' glider-itemsalign-bottom';
  }


  return (
    <div {...wrapperProps}>
        <Glider {...gliderProps}>
            {props.children}
        </Glider>
    </div>
  );
}
