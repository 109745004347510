import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import basketService from '../api/BasketService';

export class BasketIconMenu extends Component {
  static displayName = BasketIconMenu.name;

  constructor (props) {
    super(props);

    this.state = {
        itemsInBasket: 0
    };
  }

  componentDidMount() {
    this._subscription = basketService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    basketService.unsubscribe(this._subscription);
  }

  async populateState() {
    const itemsCount = await basketService.getBasketItemsCount();
    this.setState({
        itemsInBasket: itemsCount
    });
  }

  render() {
    const itemsInBasket = this.state.itemsInBasket;
    let basketCounter;
    if(itemsInBasket > 0) {
        basketCounter = `${itemsInBasket}`;
    }
    return (
        <Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to="/basket" style={{paddingTop:'4px',height:'32px'}}>
                  <svg enableBackground="new 0 0 50 50" version="1.1" viewBox="0 0 50 50" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                    <rect fill="none" height="50" width="50"/>
                    <path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
                    <circle cx="34" cy="19" r="2"/>
                    <circle cx="16" cy="19" r="2"/>
                  </svg><span className="badge badge-pill badge-dark">{basketCounter}</span>
                  </NavLink>
            </NavItem>
        </Fragment>
    );
  }
}
