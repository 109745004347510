import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DesignerCarouselListing } from "./DesignerCarouselListing";
import { CollectionCarouselListing } from '../collection/CollectionCarouselListing';
//import { InsightBoxes } from '../home/InsightBoxes';
import { Meta } from "../layout-components/Meta";
//import './DesignersHome.css';

export class ShowcaseHome extends Component {

  constructor(props) {
    super(props);
    this.state = { data: null, loading: true };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  render () {
    if (this.state.loading)
      return (
          <p><Meta title="Showcasing Designers | FLAIR" /><em>Loading...</em></p>
      );
      
    const data = this.state.data;
     
    return (
      <div>
        <Meta title="Showcasing Designers | FLAIR" />

        <section id="listd-section" className="mb-5 pb-5" style={{maxWidth:'100%'}}>
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4 offset-lg-1 col-xl-3 offset-xl-2 order-1 order-md-1 d-flex align-items-center justify-content-center mb-5 mb-md-0">
                <div className="txtblock">
                  <h1 className="header-style page-title__h2 mb-3 text-uppercase">Showcasing The Next Generation of Fashion Designers</h1>
                  <p className="mb-4">Designers at the forefront of innovation, sustainability and technology.</p>
                  <div className="d-flex align-items-stretch">
                    <Link to="/designers" className="flex-fill mr-1 btn btn-outline-dark">Show all designers</Link>
                    <Link to="/join-flair" className="flex-fill ml-1 btn btn-dark">Join as a designer</Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7 col-lg-6 order-2 order-md-2 d-flex align-items-center">
                <Link to="/designers" className="d-block">
                    <img src="https://res.cloudinary.com/flairfashion/image/upload/f_auto,q_auto:best/v1646863408/TestSite/General/dbanner.jpg" alt="Flair designers" className="w-100" />
                </Link>
              </div>
            </div>
        </section>

        <div className="mb-5 pb-5">
          <section className="position-relative">
              <h1 className="header-style page-title__h2 my-4 text-center">Say Hello To <span className="hl">Flair</span> Family</h1>
              <DesignerCarouselListing 
                  items={data.featuredDesigners}
                  sliderNextButton="#featuredDesigners-next"
                  sliderPreviousButton="#featuredDesigners-prev" />
              <div className="d-flex justify-content-center slider-controller my-4">
                <Link to="/designers" className="d-inline-block btn btn-outline-dark mr-1">Show all designers</Link>
                <div className="btn-group ml-1" role="group">
                    <button type="button" className="btn btn-outline-dark slider-prev" id="featuredDesigners-prev">&nbsp;</button>
                    <button type="button" className="btn btn-outline-dark slider-next" id="featuredDesigners-next">&nbsp;</button>
                </div>
              </div>
          </section>
        </div>



        <div className="mb-5 pb-5 pt-lg-4">
          <section className="position-relative">
              <h2 className="header-style page-title__h2 my-4 text-center">Recently Added Collections</h2>
              <CollectionCarouselListing 
                  items={data.featuredCollections} 
                  sliderNextButton="#recentCollection-next"
                  sliderPreviousButton="#recentCollection-prev"
                  showBrandTitle={true} />
              <div className="d-flex justify-content-center slider-controller my-4">
                <div className="btn-group ml-1" role="group">
                    <button type="button" className="btn btn-outline-dark slider-prev" id="recentCollection-prev">&nbsp;</button>
                    <button type="button" className="btn btn-outline-dark slider-next" id="recentCollection-next">&nbsp;</button>
                </div>
              </div>
          </section>
        </div>


        <div className="mb-5 pb-5 pt-lg-4">
          <div className="banner-full">
            <Link to="/adiamelias" className="d-block">
              <picture>
                <source media="(max-width: 767.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/ar_0.5,c_lfill,w_1000,dpr_auto,f_auto,q_auto:best/v1692244429/TestSite/Home-Banners/Adiam__Elias_Adiam-360_b_m.jpg" />
                <source media="(max-width: 991.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/ar_1,c_lfill,w_1600,dpr_auto,f_auto,q_auto:best/v1692244429/TestSite/Home-Banners/Adiam__Elias_Adiam-360_b_d.jpg" />
                <source media="(max-width: 1499.98px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_lfill,w_2000,dpr_auto,f_auto,q_auto:best/v1692244429/TestSite/Home-Banners/Adiam__Elias_Adiam-360_b_d.jpg" />
                <source media="(min-width: 1500px)" srcSet="https://res.cloudinary.com/flairfashion/image/upload/c_lfill,w_2400,dpr_auto,f_auto,q_auto:best/v1692244429/TestSite/Home-Banners/Adiam__Elias_Adiam-360_b_d.jpg" />
                <img src="https://res.cloudinary.com/flairfashion/image/upload/ar_0.5,c_lfill,w_1000,dpr_auto,f_auto,q_auto:best/v1692244429/TestSite/Home-Banners/Adiam__Elias_Adiam-360_b_m.jpg" className="w-100" alt="Formless | Flair" />
              </picture>
              <div className="banner-txt">Featured: Adiamelias</div>
            </Link>
          </div>
        </div>
{/* 
        <section className="my-5">
            <h2 className="header-style page-title__h2 text-center my-4">Insights</h2>
            <InsightBoxes />
        </section>
*/}        
      </div>
    );
  }

  async fetchData() {
      const response = await fetch(`api/home/showcase`, {
      });
      const data = await response.json();
      this.setState({ data: data, loading: false });

      console.log(data);
  }
}
