import React from 'react';
import { Link } from 'react-router-dom';
import { BasketItem } from './BasketItem';

export function BasketItemsGroup(props) {
    const group = props.group;
    let content = group.basketItems.map((item) =>
        <BasketItem key={item.productSizeVariantId.toString()}
            basketItem={item}
            hideButtons={props.hideButtons} />
    );
    return (
        <div className="container-fluid px-0 pb-4 mb-5 border-bottom">
            <div className="row">
                <div className="col-12">
                    <div className="row mb-3">
                        <div className="col-12 col-md-8">
                            {props.hideButtons &&
                              <h3>Designed by {group.designer.brandTitle}</h3>
                            }
                            {!props.hideButtons &&
                              <h3>Designed by <Link to={`/${group.designer.friendlyUrlName}`} className="">{group.designer.brandTitle}</Link></h3>
                            }
                      </div>
                        <div className="col-md-2 text-right text-muted d-none d-md-block">
                            Quantity
                        </div>
                        <div className="col-md-2 text-right text-muted d-none d-md-block">
                            Price
                        </div>
                    </div>
                    {content}
                    <div className="row mb-3">
                        <div className="col-9 col-sm-10 col-md-10 text-right text-muted">
                            Shipping
                        </div>
                        <div className="col-3 col-sm-2 col-md-2 text-right">
                            {group.designerShippingCostCurrency}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-9 col-sm-10 col-md-10 text-right text-muted">
                            Total from <span>{group.designer.brandTitle}</span>
                        </div>
                        <div className="col-3 col-sm-2 col-md-2 text-right">
                            {group.totalCostCurrency}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

