import React from 'react';
import { ProductListingItem } from '../store-catalogue/ProductListingItem';

export function FeaturedProducts(props) {
    const { products, totalInRow, itemSmallBottomPadding } = props;

    let content = products.map(p =>
        <div key={p.id} className={
            totalInRow === "5" ? "col-6 col-md-3 col-lg-2" : "col-6 col-md-3"
        }>
            <ProductListingItem product={p} itemSmallBottomPadding={itemSmallBottomPadding} />
        </div>
    );

    return (
        <div className="productlisting">
            <div className="row">
                {content}
            </div>
        </div>
    );
}
