import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";
import './StaticPages.css';

export class FlairProgrammeSept23 extends Component {
    static displayName = FlairProgrammeSept23.name;

    constructor(props) {
        super(props);
    }

  render () {
    return (
      <div>
        <Meta title="Flair Programme September 2023 | FLAIR"
            description="Flair's programme for September 2023 during London Fashion Week. Join us our exciting events and meet some incredible designers and their collections." />

        <div className="carousel-home mb-5 homep-b">
          <picture>
            <source media="(orientation: portrait) and (max-width: 767.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000,c-at_max/Events/flair-programme-sept-23-dead-lotus-couture-m_5Ng1bphkY.jpg?updatedAt=1693175192217" />
            <source media="(orientation: landscape) and (max-width: 767.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1000,c-at_max/Events/flair-programme-sept-23-dead-lotus-couture_oLFJ4FpRtV.jpg?updatedAt=1693175192482" />
            <source media="(orientation: portrait) and (max-width: 1030px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1500,c-at_max/Events/flair-programme-sept-23-dead-lotus-couture-m_5Ng1bphkY.jpg?updatedAt=1693175192217" />
            <source media="(max-width: 991.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1600,c-at_max/Events/flair-programme-sept-23-dead-lotus-couture_oLFJ4FpRtV.jpg?updatedAt=1693175192482" />
            <source media="(max-width: 1499.98px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1920,c-at_max/Events/flair-programme-sept-23-dead-lotus-couture_oLFJ4FpRtV.jpg?updatedAt=1693175192482" />
            <source media="(min-width: 1500px)" srcSet="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-2500,c-at_max/Events/flair-programme-sept-23-dead-lotus-couture_oLFJ4FpRtV.jpg?updatedAt=1693175192482" />
            <img src="https://ik.imagekit.io/flair/tr:f-auto,q-80,w-1600,c-at_max/Events/flair-programme-sept-23-dead-lotus-couture_oLFJ4FpRtV.jpg?updatedAt=1693175192482" className="w-100" alt="Flair Programme 2023 - Image by Dead Lotus Couture" />
          </picture>
          <div className="events-banner-txt d-flex">
            <div className="justify-content-center align-self-center">
                <div className="header-style border border-dark" style={{display:'inline-block', padding: '15px'}}>Flair Programme</div>
                <div className="header-style pb-4" style={{fontSize:'5em'}}>2023</div>
                <div className="header-style">September</div>
            </div>
          </div>
          <div className="small text-white homep-b-credit">Photo by designer Dead Lotus Couture</div>
        </div>

        <div className="container my-5 pb-5 special-event-list">
            <h1 className="header-style events-banner-txt-m">Flair Fashion Programme: September 2023</h1>
            <hr className="border-dark"/>
            <div className="row pb-2">
                <div className="col-12 col-md-2 p-md-4 border-right border-dark">Presentation / Party</div>
                <div className="col-12 col-md-7 p-md-4">
                    <p>
                        Thursday, 14th September<br/>
                        7:30pm - 10:00pm
                    </p>
                    <h1>Wild Corner: Flair Fashion Presentation at TRAMP- London Most Exclusive Private Club</h1>
                    <p>
                        Presenting 5 fashion designers during London Fashion Week at TRAMPs’ ornately decorated event space in Mayfair, previously enjoyed by Frank Sinatra and the Beatles-historical as it is magical.
                    </p>
                    <p>Location: 40 Jermyn Street, London, SW1Y 6DT</p>
                </div>
                <div className="col-12 col-md-3 p-md-4">
                    <a href="https://www.eventbrite.com/e/wild-corner-flair-fashion-presentation-at-tramp-tickets-707697982717?aff=flairweb" target="_blank" class="btn btn-outline-dark w-100">RSVP Eventbrite</a>
                </div>
            </div>
            <hr className="border-dark d-md-none"/>
            <div className="row py-2">
                <div className="col-12 col-md-2 p-md-4 border-right border-dark">Party</div>
                <div className="col-12 col-md-7 p-md-4">
                    <p>
                        Friday, 15th September<br/>
                        5:30pm - 8:30pm
                    </p>
                    <h1>Flair x Touch London: Fashion Underground Pop-up Launch Party</h1>
                    <p>
                        Showcasing an inclusive and dynamic event during London Fashion Week that aims to empower brands through captivating experiences at Spitalfields, and a community of creatives come together for their love of fashion and innovation.
                    </p>
                    <p>Location: 82a Commercial Street, London, E1 6LY</p>
                </div>
                <div className="col-12 col-md-3 p-md-4">
                    <a href="https://www.eventbrite.co.uk/e/flair-x-touch-london-fashion-underground-pop-up-tickets-707701984687?aff=flairweb" target="_blank" class="btn btn-outline-dark w-100">RSVP Eventbrite</a>
                </div>
            </div>
            <hr className="border-dark d-md-none"/>
            <div className="row py-2">
                <div className="col-12 col-md-2 p-md-4 border-right border-dark">Pop-up</div>
                <div className="col-12 col-md-7 p-md-4">
                    <p>
                        Thursday, 14th - 18th September<br/>
                        10:00am - 8:00pm
                    </p>
                    <h1>Flair x Touch London: Fashion Underground Pop-up</h1>
                    <p>
                        Browse and shop collections from incredible brands and emerging designers from all over the world.
                    </p>
                    <p>Location: 82a Commercial Street, London, E1 6LY</p>
                </div>
                <div className="col-12 col-md-3 p-md-4">
                <a href="https://www.eventbrite.co.uk/e/flair-x-touch-london-fashion-underground-pop-up-tickets-707701984687?aff=flairweb" target="_blank" class="btn btn-outline-dark w-100">RSVP Eventbrite</a>
                </div>
            </div>
        </div>
      </div>
    );
  }
}
