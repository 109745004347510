import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/home/Home';
import ScrollToTop from './components/layout-components/ScrollToTop';
import { BasketPage } from './components/basket/BasketPage';
import { ShowcaseHome } from './components/designer/ShowcaseHome';
import { ShopHome } from './components/store-catalogue/ShopHome';
import { DesignersListingPage } from './components/designer/DesignersListingPage';
import { DesignerDetailsPage } from './components/designer/DesignerDetailsPage';
import { CheckoutPage } from './components/checkout/CheckoutPage';
import { CheckoutProcessingPage } from './components/checkout/CheckoutProcessingPage';
import { CheckoutCompletePage } from './components/checkout/CheckoutCompletePage';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { StoreCataloguePage } from './components/store-catalogue/StoreCataloguePage';
import { ProductDetailsPage } from './components/product-details/ProductDetailsPage';
import { CollectionDetailsPage } from './components/collection/CollectionDetailsPage';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import { FAQPage } from './components/static-pages/FAQ';
import { TermsAndConditionsPage } from './components/static-pages/TermsAndConditions';
import { PrivacyPolicyPage } from './components/static-pages/PrivacyPolicy';
import { ReturnPolicyPage } from './components/static-pages/ReturnPolicy';
import { AboutPage } from './components/static-pages/About';
import { JoinFlairPage } from './components/static-pages/JoinFlair';

import { FlairClass21 } from './components/static-pages/FlairClass21';
import { FlairGrantClass21 } from './components/static-pages/FlairGrantClass21';
import { FlairProgrammeSept23 } from './components/static-pages/FlairProgrammeSept23';
import { PiecesTrousers } from './components/static-pages/pp/PiecesTrousers';
import { PiecesJacket } from './components/static-pages/pp/PiecesJacket';
import { NiamhTop } from './components/static-pages/pp/NiamhTop';
import { NiamhBottom } from './components/static-pages/pp/NiamhBottom';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <ScrollToTop />
        <Switch>
          <Route exact path='/' component={Home} />

          <Route exact path='/pp/1074/19250/01a1c9a1fffb4cf18de0a6921bdb7942' component={PiecesTrousers} />
          <Route exact path='/pp/1074/19253/56176dbbbdd94328a6b947d8614047f8' component={PiecesJacket} />
          <Route exact path='/pp/1097/25764/a829aa39b05c4fa9add5f50f5c53d989' component={NiamhTop} />
          <Route exact path='/pp/1097/25768/f6a4cb2d19c64c3593e51a639f2a1265' component={NiamhBottom} />

          <Route exact path='/events/flair-class-2021-showcase-event' component={FlairClass21} />
          <Route exact path='/grants/flair-graduate-grant-class-of-2021' component={FlairGrantClass21} />
          <Route exact path='/events/2023/september/flair-programme-sept-23' component={FlairProgrammeSept23} />
          <Route path='/faq' component={FAQPage} />
          <Route path='/terms-and-conditions' component={TermsAndConditionsPage} />
          <Route path='/privacy-policy' component={PrivacyPolicyPage} />
          <Route path='/return-policy' component={ReturnPolicyPage} />
          <Route path='/about' component={AboutPage} />
          <Route path='/join-flair' component={JoinFlairPage} />

          <Route path='/showcase' component={ShowcaseHome} />
          <Route path='/designers' component={DesignersListingPage} />
          <Route path='/basket' component={BasketPage} />
          <Route path='/counter' component={Counter} />
          <AuthorizeRoute path='/fetch-data' component={FetchData} />
          <Route path='/p/:id/:friendlyUrlName' component={ProductDetailsPage} />
          <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />

          <Route path='/checkout/complete/:orderId' component={CheckoutCompletePage} />
          <Route path='/checkout/processing/:orderId' component={CheckoutProcessingPage} />
          <Route path='/checkout' component={CheckoutPage} />

          <Route path='/shop' component={ShopHome} />
          <Route path='/:rootCategoryUrl(womenswear|menswear|artefacts)/:child1CategoryUrl?/:child2CategoryUrl?/:child3CategoryUrl?/designer/:designerUrl/page/:pageNum' component={StoreCataloguePage} />
          <Route path='/:rootCategoryUrl(womenswear|menswear|artefacts)/:child1CategoryUrl?/:child2CategoryUrl?/:child3CategoryUrl?/designer/:designerUrl' component={StoreCataloguePage} />
          <Route path='/:rootCategoryUrl(womenswear|menswear|artefacts)/:child1CategoryUrl?/:child2CategoryUrl?/:child3CategoryUrl?/page/:pageNum' component={StoreCataloguePage} />
          <Route path='/:rootCategoryUrl(womenswear|menswear|artefacts)/:child1CategoryUrl?/:child2CategoryUrl?/:child3CategoryUrl?' component={StoreCataloguePage} />

          <Route path='/:designerUrl/collection/:id/:collectionUrl' component={CollectionDetailsPage} />

          <Route path='/:designerUrl' component={DesignerDetailsPage} />
        </Switch>
      </Layout>
    );
  }
}
