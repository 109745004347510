import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BasketItemsGroup } from './BasketItemsGroup';
import basketService from '../api/BasketService';
import { Meta } from "../layout-components/Meta";
import './BasketPage.css';

export class BasketPage extends Component {
  static displayName = BasketPage.name;

  constructor (props) {
    super(props);

    this.state = {
        ready: false,
        basket: null
    };
    this.populateBasketState = this.populateBasketState.bind(this);
  }

  componentDidMount() {
    this._subscription = basketService.subscribe(() => this.basketChanged());
    this.populateBasketState();
  }

  componentWillUnmount() {
    basketService.unsubscribe(this._subscription);
  }

  render() {
    if (!this.state.ready)
        return (
            <p><Meta title="Shopping Bag | FLAIR" /><em>Loading...</em></p>
        );

    const basket = this.state.basket;

    if(!basket || 
       !basket.basketId || 
       !basket.basketItemsGroups || 
       basket.basketItemsGroups.length <= 0 ||
       basket.totalItems <= 0)
        return (
          <div><p><em>There are no items in your bag</em></p><p><Link to="/designers" className="d-inline-block btn btn-outline-dark">Browse our brilliant designers</Link></p></div>
        );

    let content = basket.basketItemsGroups.map((group) =>
      <BasketItemsGroup key={group.designer.id.toString()} group={group} />
    );
    return (
        <div className="container px-0">
            <Meta title="Shopping Bag | FLAIR" />
            <h1 className="border-bottom pb-3 text-center text-uppercase fslash">Your bag</h1>
            <div className="basket mt-5">
              {content}
            </div>
            <div className="row mt-5">
              <div className="col-9 col-sm-10 col-md-10 text-right text-muted">
                  Total cost
              </div>
              <div className="col-3 col-sm-2 col-md-2 text-right">
                  {basket.totalCostCurrency}
              </div>
            </div>
            <div className="row my-5 pb-4">
                <div className="col-12 text-right">
                  <Link to={`/checkout`} className="btn btn-secondary btn-lg px-5 basket-btn">Proceed to checkout</Link>
                </div>
            </div>
        </div>
    );
  }

  async basketChanged() {
    //this.setState({
    //    ready: false,
    //});
    await this.populateBasketState();
  }
  
  async populateBasketState() {
    const basket = await basketService.getBasket(
      function() {
      },
      function(error) {
        console.log('Error: ' + error);
      }
    );
    this.setState({ 
      ready: true,
      basket: basket
    });
  }
}
