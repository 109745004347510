import React from 'react';

var isMobile = function() {
    return (/Android|iPhone|iPad|Opera Mini/i.test(navigator.userAgent) ||
                (navigator.platform === 'MacIntel' && navigator.maxTouchPoints && navigator.maxTouchPoints > 1)) 
            && !window.MSStream ;
}

export function StoryTextBox(props) {
    let divClass = "text-paragraph";
    
    if(props.row.data.boxDirection === "center") {
        divClass += " mx-auto";
    } else if(props.row.data.boxDirection === "right") {
        divClass += " ml-auto";
    } else {
        divClass += " mr-auto";
    }

    if(props.row.data.textAlignment === "center") {
        divClass += " text-center";
    } else if(props.row.data.textAlignment === "right") {
        divClass += " text-right";
    } else {
        divClass += " text-left";
    }

    if(props.row.data.largeBox === true) {
        divClass += " lrg";
    }

    return (
        <div className={props.row.data.stretched === true ? "px-0 my-5" : "container px-0 my-5"}>
            <div className={divClass} 
                 dangerouslySetInnerHTML={ {__html: props.row.data.text} }
            />
        </div>
    );
}

export function StoryHeader(props) {
    if(props.row.data.level === 2) {
        return (
            <h2 className="my-5">{props.row.data.text}</h2>
        );
    }
    let c = "";
    if(props.row.data.style === "flairheader") c += " fslash";
    if(props.row.data.direction === "left") c += " ml-0 text-left";
    else if(props.row.data.direction === "right") c += " mr-0 text-right";
    return (
        <h1 className={`my-5 ${c}`}>{props.row.data.text}</h1>
    );
}
export function StoryHeaderLEGACY(props) {
    if(props.row.data.level === 2) {
        return (
            <h2 className="my-5">{props.row.data.text}</h2>
        );
    }
    let c = "";
    if(props.row.data.style === "flairheader") c += " fslash";
    if(props.row.data.direction === "left") c += " ml-0 text-left";
    else if(props.row.data.direction === "right") c += " mr-0 text-right";
    return (
        <h1 className={`my-5 ${c}`}>{props.row.data.text}</h1>
    );
}

export function StoryBanner(props) {
    return (
        <div className="my-5 banner">
            <div className={props.row.data.stretched === false ? 'container' : 'w-100'}>
                <img className="d-block w-100" src={process.env.REACT_APP_MEDIAURL_STORY_BANNER + props.row.data.file.url} />
                {props.row.data.showCaption === true &&
                    <div className="my-2 mx-4">
                        <small className="text-muted" dangerouslySetInnerHTML={ {__html: props.row.data.caption} } />
                    </div>
                }
            </div>
        </div>
    );
}

export function StoryBannerLEGACY(props) {
    if(props.row.data.fixedBackground)
        return (
            <div className={`my-5 banner fixed-bg ${isMobile() ? 'mobile' : ''}`} style={{ backgroundImage: `url(${props.row.data.file.url})` }}></div>
        );
    return (
        <div className="my-5 banner">
            <img className="d-block w-100" src={props.row.data.file.url} />
        </div>
    );
}

export function StoryLeadText(props) {
    let divClass = "lead leadbox";
    if(props.row.data.size === "large") {
        divClass += " lrg";
    } else if(props.row.data.size === "small") {
        divClass += " sml";
    }
    
    if(props.row.data.boxDirection === "center") {
        divClass += " mx-auto";
    } else if(props.row.data.boxDirection === "right") {
        divClass += " ml-auto";
    } else {
        divClass += " mr-auto";
    }

    if(props.row.data.textAlignment === "center") {
        divClass += " text-center";
    } else if(props.row.data.textAlignment === "right") {
        divClass += " text-right";
    } else {
        divClass += " text-left";
    }

    return (
        <div className={props.row.data.boxDirection === "center" ? "container px-0 my-5" : "px-0 my-5"}>
            <div className={divClass} 
                 dangerouslySetInnerHTML={ {__html: props.row.data.text} }
            />
        </div>
    );
}

export function StoryLeadTextLEGACY(props) {
    let divClass = "lead leadbox";
    if(props.row.data.size === "large") {
        divClass += " lrg";
    } else if(props.row.data.size === "small") {
        divClass += " sml";
    }
    
    if(props.row.data.direction === "center") {
        divClass += " text-center mx-auto";
    } else if(props.row.data.direction === "right") {
        divClass += " text-right ml-auto";
    } else {
        divClass += " text-left mr-auto";
    }     
    return (
        <div className={props.row.data.direction === "center" ? "container px-0 my-5" : "px-0 my-5"}>
            <div className={divClass} 
                 dangerouslySetInnerHTML={ {__html: props.row.data.text} }
            />
        </div>
    );
}

export function StoryLeadWithImageOnRight(props) {
    return (
        <div className="container px-0">
            <div className="row my-4">
                <div className="col-12 col-md-6 my-3 d-flex align-items-center">
                    <div className="lead sidetxt text-left">
                        <div dangerouslySetInnerHTML={ {__html: props.row.data.text} } />
                    </div>
                </div>
                <div className="col-12 col-md-6 my-3">
                    <img className="d-block w-100" src={props.row.data.file.url} />
                </div>
            </div>
        </div>
    );
}



export function StoryTextWithMedia(props) {
    let textClass;
    if(props.row.data.textAlignment === "left") {
        textClass = "text-left";
    } else if(props.row.data.textAlignment === "right") {
        textClass = "text-right";
    } else {
        textClass = "text-center";
    }
    if(props.row.data.imageDirection === "left") {
        if(props.row.data.stretched) {
            textClass += " smallbox ml-md-4 ml-lg-5";
        } else {
            textClass += " ml-auto";
        }
    } else if(props.row.data.imageDirection === "right" &&
              props.row.data.stretched) {
        textClass += " smallbox ml-auto mr-md-4 mr-lg-5";
    }

    let textbox;
    if(props.row.data.textboxVerticalAlign === "bottom") {
        textbox = "align-items-end";
    } else if(props.row.data.textboxVerticalAlign === "top") {
        textbox = "align-items-start";
    } else {
        textbox = "align-items-center";
    }

    return (
        <div className={`px-0 my-5 ${ props.row.data.stretched ? "" : "container" }`}>
            <div className={`row my-4 d-flex ${ props.row.data.imageDirection === "left" ? "flex-row-reverse" : "" }`}>
                <div className={`col-12 col-md-6 mb-3 d-flex ${textbox}`}>
                    <div className={`sidetxt ${textClass}`}>
                        <div dangerouslySetInnerHTML={ {__html: props.row.data.text} } />
                    </div>
                </div>
                { props.row.data.file &&
                <div className="col-12 col-md-6 mb-3">
                    <img className="d-block w-100" src={process.env.REACT_APP_MEDIAURL_STORY_TEXTWITHMEDIA_IMG + props.row.data.file.url} />
                </div>
                }
            </div>
        </div>
    );
}

export function StoryTextWithImageLEGACY(props) {
    let textClass;
    if(props.row.data.textDirection === "left") {
        textClass = "text-left";
    } else if(props.row.data.textDirection === "right") {
        textClass = "text-right";
    } else {
        textClass = "text-center";
    }
    if(props.row.data.imageDirection === "left") {
        if(props.row.data.fullscreen) {
            textClass += " smallbox ml-md-4 ml-lg-5";
        } else {
            textClass += " ml-auto";
        }
    } else if(props.row.data.imageDirection === "right" &&
              props.row.data.fullscreen) {
        textClass += " smallbox ml-auto mr-md-4 mr-lg-5";
    }

    let textbox;
    if(props.row.data.textAlignment === "bottom") {
        textbox = "align-items-end";
    } else if(props.row.data.textAlignment === "top") {
        textbox = "align-items-start";
    } else {
        textbox = "align-items-center";
    }

    return (
        <div className={`px-0 my-5 ${ props.row.data.fullscreen ? "" : "container" }`}>
            <div className={`row my-4 d-flex ${ props.row.data.imageDirection === "left" ? "flex-row-reverse" : "" }`}>
                <div className={`col-12 col-md-6 mb-3 d-flex ${textbox}`}>
                    <div className={`sidetxt ${textClass}`}>
                        <div dangerouslySetInnerHTML={ {__html: props.row.data.text} } />
                    </div>
                </div>
                { props.row.data.file &&
                <div className="col-12 col-md-6 mb-3">
                    <img className="d-block w-100" src={props.row.data.file.url} />
                </div>
                }
            </div>
        </div>
    );
}
export function StoryTextWithImageOnLeft(props) {

    let textbox;
    if(props.row.data.textAlignment === "bottom") {
        textbox = "align-items-end";
    } else if(props.row.data.textAlignment === "top") {
        textbox = "align-items-start";
    } else {
        textbox = "align-items-center";
    }

    return (
        <div className={`px-0 my-5 ${ props.row.data.fullscreen ? "" : "container" }`}>
            <div className="row my-4">
                <div className="col-12 col-md-6 my-3">
                    <img className="d-block w-100" src={props.row.data.file.url} />
                </div>
                <div className={`col-12 col-md-6 my-3 d-flex ${textbox}`}>
                    <div className="sidetxt text-center">
                        <div dangerouslySetInnerHTML={ {__html: props.row.data.text} } />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function StoryTextColumns(props) {
    return (
        <div className="container my-5 px-0">
            <div className="txt-columns" dangerouslySetInnerHTML={{__html: props.row.data.text}} />
        </div>
    );
}
export function StoryImage(props) {
    let styling;
    if(props.row.data.size === "small") {
        styling = {maxWidth: '500px'}
    } else if(props.row.data.size === "medium") {
        styling = {maxWidth: '700px'}
    } else {
    }
    return (
        <div className="container my-5 px-0 text-center">
            <img src={props.row.data.file.url} className="w-100" style={styling} alt={props.row.data.caption} />
        </div>
    );
}


export function StoryGalleryDualImages(props) {
    let spacing = "";
    if(props.row.data.spacing === "up") spacing = "mt-5";
    else if(props.row.data.spacing === "down") spacing = "mb-5";
    else if(props.row.data.spacing === "both") spacing = "my-5";

    let imagesAlignment = "align-items-start";
    if(props.row.data.alignment === "end") {
        imagesAlignment = "align-items-end";
    } else if(props.row.data.alignment === "center") {
        imagesAlignment = "align-items-center";
    }

    let image1Alignment = "align-items-start";
    if(props.row.data.image1.alignment === "end") {
        image1Alignment = "align-items-end";
    } else if(props.row.data.image1.alignment === "center") {
        image1Alignment = "align-items-center";
    }

    let image2Alignment = "align-items-start";
    if(props.row.data.image2.alignment === "end") {
        image2Alignment = "align-items-end";
    } else if(props.row.data.image2.alignment === "center") {
        image2Alignment = "align-items-center";
    }

    return (
        <div className={`px-0 ${ spacing } ${ props.row.data.fullscreen ? "" : "container" }`}>
            <div className={`row my-4 d-flex ${ imagesAlignment }`}>
                <div className="col-12 col-lg-6 my-3">
                    <div className={`dualgalleryitem mr-auto d-flex ${ image1Alignment }`}>
                        <img className="d-block" src={props.row.data.image1.file.url} />
                        <div className="ml-3" dangerouslySetInnerHTML={ {__html: props.row.data.image1.text} } />
                    </div>
                </div>
                <div className="col-12 col-lg-6 my-3">
                    <div className={`dualgalleryitem ml-auto d-flex flex-row-reverse ${ image2Alignment }`}>
                        <img className="d-block" src={props.row.data.image2.file.url} />
                        <div className="mr-3" dangerouslySetInnerHTML={ {__html: props.row.data.image2.text} } />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function StoryGalleryImages(props) {
    let spacing = "";
    if(props.row.data.spacing === "up") spacing = "mt-5";
    else if(props.row.data.spacing === "down") spacing = "mb-5";
    else if(props.row.data.spacing === "both") spacing = "my-5";

    let imagesContent = props.row.data.images.map((p, index) =>
        <div key={index} className="col-4 col-lg mb-2">
            <img className="d-block w-100" src={p.file.url} />
        </div>
    );

    let imagesAlignment = "align-items-start";
    if(props.row.data.alignment === "end") {
        imagesAlignment = "align-items-end";
    } else if(props.row.data.alignment === "center") {
        imagesAlignment = "align-items-center";
    }

    let styl = {};
    if(props.row.data.backgroundColor) { 
        styl.backgroundColor = props.row.data.backgroundColor;
    }

    return (
        <div style={styl} className={`gallery px-0 my-5 ${ spacing }`}>
            <div className={`inner py-5 ${ props.row.data.fullscreen ? "" : "container" }`}>
                <div className={`row my-4 d-flex justify-content-center ${ imagesAlignment }`}>
                    {imagesContent}
                </div>
                <div className="mx-auto textbox mt-5" dangerouslySetInnerHTML={ {__html: props.row.data.text} } />
            </div>
        </div>
    );
}

export function StoryAR2(props) {
    let media;
    if(props.row.data.video) {
        media = (
            <video src={props.row.data.video.url} className="d-block w-100" autoPlay loop muted playsInline style={{pointerEvents: "none"}}>
            </video>
        );
    } else if(props.row.data.image) {
        media = <img className="d-block w-100" src={props.row.data.image.url} />;
    }

    let alignment = "align-items-start";
    if(props.row.data.alignment === "end") {
        alignment = "align-items-end";
    } else if(props.row.data.alignment === "center") {
        alignment = "align-items-center";
    }

    return (
        <div className="px-0">
            <div className={`row my-4 d-flex ${ props.row.data.mediaDirection === "left" ? "flex-row-reverse" : "" }`}>
                <div className={`col-12 col-md-6 my-3 d-flex ${ alignment }`}>
                    <div className="sidetxt text-center mx-auto">
                        <div className="mb-3" dangerouslySetInnerHTML={ {__html: props.row.data.text} } />
                        <img className="d-block mx-auto mb-2" style={{width:"150px"}} src={props.row.data.qrCode} />
                        <p className="text-uppercase">
                            <a href={props.row.data.arUrl} target="_blank">Click here</a> or scan the QR codes to start the experience
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-6 my-3">
                    {media}
                </div>
            </div>
        </div>
    );
}


export function Box1(props) {
    let spacing = "";
    if(props.row.data.spacing === "up") spacing = "mt-5";
    else if(props.row.data.spacing === "down") spacing = "mb-5";
    else if(props.row.data.spacing === "both") spacing = "my-5";

    let styl = {};
    if(props.row.data.backgroundColor) { 
        styl.backgroundColor = props.row.data.backgroundColor;
    }

    return (
        <div style={styl} className={`gallery px-0 my-5 ${ spacing }`}>
            <div className={`inner py-5 ${ props.row.data.fullscreen ? "" : "container" }`}>
                <div className="row">
                    <div className="col-12 col-lg-6 align-self-center">
                        <div className="px-md-5 mb-4">
                            <h2 className="mb-3 fslash text-left">{props.row.data.title}</h2>
                            <div className="mt-3 textbox smallbox" dangerouslySetInnerHTML={ {__html: props.row.data.text} } />
                        </div>
                    </div>
                    <div className="col-6 col-lg-3">
                        <img className="d-block w-100" src={props.row.data.image1.url} />
                    </div>
                    <div className="col-6 col-lg-3">
                       <img className="d-block w-100" src={props.row.data.image2.url} />
                    </div>
                </div>
            </div>
        </div>
    );
}




export function StoryImagesGrid(props) {
    let gridClass='';
    switch(props.row.data.totalPerRow)
    {
        case 3:
            gridClass = 'col-12 col-lg-4'
            break;
        case 4:
            gridClass = 'col-12 col-lg-3'
            break;
        default:
            gridClass = 'col-12 col-lg-6'
            break;
    }

    let containerClass='';
    switch(props.row.data.itemsAlignment)
    {
        case "middle":
            containerClass = 'align-items-center'
            break;
        case "bottom":
            containerClass = 'align-items-end'
            break;
        default:
            containerClass = 'align-items-start'
            break;
    }

    let imagesContent = props.row.data.images.map((p, index) =>
        <div key={index} className={gridClass}>
            <img src={process.env.REACT_APP_MEDIAURL_STORY_IMAGESGRID + p.file.url} className="w-100 mb-4" />
        </div>
    );
    return (
        <div className={`row ${containerClass} my-5`}>
            {imagesContent}
        </div>
    );
}

export function StoryImagesGridLEGACY(props) {
    let gridClass='';
    switch(props.row.data.totalPerRow)
    {
        case 3:
            gridClass = 'col-12 col-lg-4'
            break;
        case 4:
            gridClass = 'col-12 col-lg-3'
            break;
        default:
            gridClass = 'col-12 col-lg-6'
            break;
    }

    let imagesContent = props.row.data.images.map((p, index) =>
        <div key={index} className={gridClass}>
            <img src={p.file.url} className="w-100 mb-4" />
        </div>
    );
    return (
        <div className={`row my-5`}>
            {imagesContent}
        </div>
    );
}


export function StoryImagesGridMasonry(props) {
    /*            {(index === 0 ?
                (
                <div class="card p-5 border-0 rounded-0">
                    <div class="card-body">
                        <div class="card-text">
                            <div className="sidetxt smallbox ml-auto mr-md-4 mr-lg-5">
                                <div dangerouslySetInnerHTML={ {__html: props.row.data.text} } />
                            </div>
                        </div>
                    </div>
                </div>
                ) :
                null
            )}
    */
    let imagesContent = props.row.data.images.map((p, index) =>
        <div key={index} className="card border-0 rounded-0 mb-4">
            <img src={p.file.url} className="w-100 card-img border-0 rounded-0" />
        </div>
    );
    return (
        <div className={`my-5 ${props.row.data.fullscreen ? '' : 'container' }`}>
            <div className="card-columns story-images-masonry">
                <div className="card p-0 px-sm-5 p-lg-5 mb-5 border-0 rounded-0">
                    <div className="card-body p-0">
                        <div className="card-text">
                            <div className="sidetxt smallbox ml-auto mr-md-4 mr-lg-5">
                                <div dangerouslySetInnerHTML={ {__html: props.row.data.text} } />
                            </div>
                        </div>
                    </div>
                </div>
                {imagesContent}
            </div>
        </div>
    );
}



export function StoryVideoSide(props) {

    /* this is same as StoryTextWithImage
        they should merge 
        StoryTextWithMedia
    */

    var videoOpts = {};
    if (props.row.data.autoplay) {
        videoOpts['autoPlay'] = 'autoPlay';
        videoOpts['muted'] = 'muted';
    }
    if (props.row.data.loop) {
        videoOpts['loop'] = 'loop';
    }
    if (props.row.data.showControls) {
        videoOpts['controls'] = 'controls';
    } else {
        videoOpts['playsInline'] = 'playsInline';
        videoOpts['style'] = {pointerEvents: 'none'};
    }

    const media = (
        <video 
            src={props.row.data.file.url} 
            className="d-block w-100"
            {...videoOpts}>
        </video>
    );

    return (
        <div className={`px-0 my-5 ${ props.row.data.fullscreen ? "" : "container" }`}>
            <div className={`row my-4 d-flex ${ props.row.data.videoDirection === "left" ? "flex-row-reverse" : "" }`}>
                { props.row.data.file &&
                <div className="col-12 col-md-7 offset-md-4">
                    {media}
                </div>
                }
            </div>
        </div>
    );    
}


export function StoryCustom(props) {
    return (
        <div dangerouslySetInnerHTML={ {__html: props.row.data.html} }
        />
    );
}

