import React, { Component } from 'react';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { EmailMarketingSignUp } from '../common/EmailMarketingSignUp'; 
import './FooterMenu.css';

export class FooterMenu extends Component {
  //static displayName = FooterMenu.name;

  constructor (props) {
    super(props);
  }

  render () {
    return (
      <footer className="footer">
        <div className="px-4 my-5 pt-5 px-md-5 ml-md-5">
            <EmailMarketingSignUp />
        </div>
        <Container fluid={true} className="px-4 mt-5 pb-3">
          <Nav>
            <NavItem className="copy">
              <div className="text-light">
                &copy; 2021 Flair Fashion Limited. All rights reserved.
              </div>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-light" to="/join-flair">Join Flair</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-light" to="/about">About</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-light" to="/terms-and-conditions">Terms &amp; Conditions</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-light" to="/privacy-policy">Privacy Policy</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-light" to="/return-policy">Returns &amp; Refunds</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-light" to="/faq">FAQ</NavLink>
            </NavItem>
            <NavItem>
              <a className="nav-link nav-icon" href="https://www.instagram.com/flair.fashionofficial/" target="_blank">
                  <svg data-name="Layer 1" className="ty-social-icon mx-2" id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                      <path className="social-cls-1" d="M83,23a22,22,0,0,1,22,22V83a22,22,0,0,1-22,22H45A22,22,0,0,1,23,83V45A22,22,0,0,1,45,23H83m0-8H45A30.09,30.09,0,0,0,15,45V83a30.09,30.09,0,0,0,30,30H83a30.09,30.09,0,0,0,30-30V45A30.09,30.09,0,0,0,83,15Z"/>
                      <path className="social-cls-1" d="M90.14,32a5.73,5.73,0,1,0,5.73,5.73A5.73,5.73,0,0,0,90.14,32Z"/>
                      <path className="social-cls-1" d="M64.27,46.47A17.68,17.68,0,1,1,46.6,64.14,17.7,17.7,0,0,1,64.27,46.47m0-8A25.68,25.68,0,1,0,90,64.14,25.68,25.68,0,0,0,64.27,38.47Z"/>
                  </svg>
              </a>
            </NavItem>
          </Nav>
        </Container>
      </footer>
    );
  }
}
