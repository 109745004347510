import React from 'react';
import { Story } from '../story/Story';
import StoryAR from '../story/StoryAR';
//import { ContentReveal } from '../common/ContentReveal'
import './ProductStory.css'

export function ProductStory(props) {
    const { product, arRef } = props;

console.log(product.story);    

    return (
        <div>
            {product.arContent &&
            <StoryAR row={product.arContent} arRef={arRef} />
            }
            {product.story &&
            <div className="mt-5 pb-5 mb-5">
                {/* 
                <ContentReveal startHeight="400px">
                    <Story content={product.story} />
                </ContentReveal>
                */}
                <Story content={product.story} />
            </div>
            }
        </div>
    );
}
