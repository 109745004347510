import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";
import './StaticPages.css';

export class FlairGrantClass21 extends Component {
    static displayName = FlairGrantClass21.name;

    constructor(props) {
        super(props);
        this.state = { data: null, loading: true };
    }

    async componentDidMount() {
        await this.fetchData();
    }    

    render() {
        let designers;
        if (this.state.loading) {
            designers = (<p>Loading..</p>);
        } else {
            const data = this.state.data;
            designers = data.map(d =>
                <div key={d.id} className="col-6 col-md-3">
                    <article className="productlistingitem mb-5">
                        <figure className="product-image">
                            <Link to={`/${d.friendlyUrlName}`} className="d-block text-center" target="_blank">
                                <img src={d.portraitImage.url} alt={d.designerName} className="w-100" />
                            </Link>
                        </figure>
                        <div className="product-meta text-center">
                            {d.designerName}
                            {d.university &&
                                <p className="">{d.university.universityName}</p>
                            }
                        </div>
                    </article>
                </div>
            );
        }

        return (
            <Fragment>

                <Meta title="Flair Graduate Grant - Class of 2021 | FLAIR"
                    description="As part of Flair’s ongoing mission to support emerging designers, we are pleased to announce Flair Graduate Grant to assist recent fashion graduates in launching their brands and developing their products." />
                
                <div className="mx-auto" style={{maxWidth:'700px'}}>
                    <h1 className="text-center titlelrg">Flair Graduate Grant</h1>
                    <h3 className="text-center titlelrg">Class of 2021</h3>

                    <div className="my-4">
                        <p>
                            As part of Flair’s ongoing mission to support emerging designers, we are pleased to announce that we are launching the <strong>Flair Graduate Grant of £1,000</strong> to assist recent fashion graduates in launching their brands and developing their products.
                        </p>
                        <p>
                            With this grant we want to celebrate graduates and their amazing work. We see so many examples of innovation; pioneering creative design practices, exploration of social issues and sustainability, creative uses of technology, and new ways of doing things. Fashion graduates bring diversity, innovation and flair; and we’re aiming to help accelerate their practical abilities to move forward in the fashion industry.

                        </p>
                        <div className="my-5">
                            <p><u>Am I eligible?</u></p>
                            <p>To apply for the grant, there are two criteria you must meet:</p>
                            <ul>
                                <li>Graduated in 2021.</li>
                                <li>Join and showcase your graduation work or brand on Flair by 12<sup>th</sup> December 2021.</li>
                            </ul>
                        </div>
                        <div className="my-5">
                            <p><u>When will the winner be announced?</u></p>
                            <p>After the submission closing date on the 12<sup>th</sup> December 2021, all entries will be judged by an industry expert and a winner will be selected.</p><p>The winner announcement will take place on the 3rd January 2022 via our Instagram <a href="https://www.instagram.com/flair.fashionofficial/" className="font-weight-bold" rel="noopener noreferrer" target="_blank">@flair.fashionofficial</a>, follow us there.</p>
                        </div>
                        <div className="my-5">
                            <p><u>How do I enter?</u></p>
                            <p>Contact us and tell us about your graduation work or brand! We will send you a joining pack to get you started with Flair.</p>
                            <div className="text-center mt-3 mb-5">
                                <div className="d-inline-block text-center">
                                    <a className="btn btn-secondary btn-lg px-5 mt-3 mb-2 mx-3" href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">Contact to join</a>
                                </div>
                                <div className="d-inline-block text-center">
                                    <a className="btn btn-secondary btn-lg px-5 mt-3 mb-2 mx-3" href="/join-flair" target="_blank">Designer information</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="my-5">
                    <h2 className="text-center titlelrg">Designers of Class 2021</h2>
                    <div className="row my-5">
                        {designers}
                    </div>
                </section>
            </Fragment>
        );
    }

    async fetchData() {
        const response = await fetch(`api/designers/all-class-21`, {
        });
        const data = await response.json();
        this.setState({ data: data, loading: false });
    }
}
