export class EmailMarketingService {    

    async subscribeEmail(emailAddress, newsletterInterestOption, doneCallback, errorCallback) {
        if(!emailAddress || !this.validateEmail(emailAddress)) {
            if(errorCallback) errorCallback('Email is not valid');
            return;
        }
        if(!newsletterInterestOption) {
            if(errorCallback) errorCallback('No subscription option was selected');
            return;
        }
        if(newsletterInterestOption !== 'womenswear' && newsletterInterestOption !== 'menswear' && newsletterInterestOption !== 'both') {
            if(errorCallback) errorCallback('No subscription option was selected');
            return;
        }

        //request body
        let reqBody = {
            emailAddress: emailAddress,
            newsletterInterestOption: newsletterInterestOption
        };

        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };

        try {
            const response = await fetch('/api/emailmarketing/subscribe', {
                method: 'post',
                headers: reqHeader,
                body: JSON.stringify(reqBody)
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const data = await response.json();

            if(data && data.success) {
                if (doneCallback) doneCallback(data.success);
                return data.success;
            }

            throw Error("Error subscribing email to mailing list, please try again soon.");
        }
        catch (error) {
            console.log(error);
            if(errorCallback) {
                errorCallback('Error subscribing email to mailing list, please try again soon.');
            }
        }
        return false;
    }

    validateEmail(emailAddress) {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(emailAddress);
    }

    static get instance() { return emailMarketingService }
}

const emailMarketingService = new EmailMarketingService();

export default emailMarketingService;
