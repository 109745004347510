import authService from '../api-authorization/AuthorizeService'

export class BasketService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _basket = null;
    _localStorageBasketName = process.env.REACT_APP_LOCALSTORAGE_BASKET_NAME;

    constructor() {
        this.loadLocalBasket();
    }

    init() {
        (async() => {
            await this.syncBasketToBackend();
        })();
    }

    async syncBasketToBackend() {
        if(!authService || !await authService.isAuthenticated()) {
            return;
        }

        console.log('sync');
        console.log(this._basket);

        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        const token = await authService.getAccessToken();
        if(token) {
            reqHeader['Authorization'] = `Bearer ${token}`;
        }

        const response = await fetch('/api/basket/sync', {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify(!this._basket ? {} : this._basket)
        });
        const data = await response.json();
        if(data && data.basketId) {
            this.updateState(data);
            return;
        }
        this.updateState(null);
    }

    resetBasket() {
        console.log('reset');
        //this.updateState(null);
    }
    
    async getBasket(doneCallback, errorCallback) {
        const token = await authService.getAccessToken();

        console.log('getbasket');
        console.log(this._basket);

        if(!token && (!this._basket || !this._basket.basketId)) {
            //no local basket or logged in user
            return;
        }

        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        if(token) {
            reqHeader['Authorization'] = `Bearer ${token}`;
        }

        const response = await fetch('/api/basket', {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify(!this._basket ? {} : this._basket)
        });
        const data = await response.json();
        if(data && data.basketId) {
            console.log('getbasket received');
            console.log(data);

            this.updateState(data.localBasket);

            if (doneCallback) doneCallback(data);
            return data;
        }
        if(errorCallback) {
            errorCallback('Error getting basket');
        }
    }

    getLocalBasket() {
        if(!this._basket || !this._basket.totalItems)
            return null;
        return this._basket;
    }

    updateLocalBasket(localBasket) {
        if(localBasket && localBasket.basketId) {
            console.log('update local basket');
            console.log(localBasket);

            this.updateState(localBasket);
        }
    }

    resetLocalBasket() {
        console.log('reset local basket');
        this.updateState(null);
    }

    async getBasketItemsCount() {
        if(!this._basket || !this._basket.totalItems)
            return 0;
        return this._basket.totalItems;
    }

    async addToBasket(productId, productSizeVariantId, doneCallback, errorCallback) {
        if(!productId || isNaN(productId)) {
            if(errorCallback) errorCallback('No product selected');
            return;
        }
        if(!productSizeVariantId || isNaN(productSizeVariantId)) {
            if(errorCallback) errorCallback('No product size selected');
            return;
        }

        //request body
        let reqBody = {
            productId: productId,
            productSizeVariantId: productSizeVariantId,
            localBasket: !this._basket ? {} : this._basket
        };

        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        const token = await authService.getAccessToken();
        if(token) {
            reqHeader['Authorization'] = `Bearer ${token}`;
        }

        try {
            const response = await fetch('/api/basket/add', {
                method: 'post',
                headers: reqHeader,
                body: JSON.stringify(reqBody)
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const data = await response.json();

            if(data && data.basketId) {
                console.log('getbasket received');
                console.log(data);

                this.updateState(data.localBasket);

                if (doneCallback) doneCallback(data.basketItem);
                return data.basketItem;
            }

            throw Error("Error adding to basket");
        }
        catch (error) {
            console.log(error);
            if(errorCallback) {
                errorCallback('Error: ' + error);
            }
        }
        return false;
    }

    async updateItemQuantity(productId, productSizeVariantId, quantityChange, doneCallback, errorCallback) {
        if(!productId || isNaN(productId)) {
            if(errorCallback) errorCallback('No product selected');
            return;
        }
        if(!productSizeVariantId || isNaN(productSizeVariantId)) {
            if(errorCallback) errorCallback('No product size selected');
            return;
        }

        if(quantityChange !== 1 && quantityChange !== -1) {
            if(errorCallback) errorCallback('Wrong quantityChange input');
            return;
        }

        //request body
        let reqBody = {
            productId: productId,
            productSizeVariantId: productSizeVariantId,
            quantityChange: quantityChange,
            localBasket: !this._basket ? {} : this._basket
        };

        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        const token = await authService.getAccessToken();
        if(token) {
            reqHeader['Authorization'] = `Bearer ${token}`;
        }

        try {
            const response = await fetch('/api/basket/updateQuantity', {
                method: 'post',
                headers: reqHeader,
                body: JSON.stringify(reqBody)
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }        
            const data = await response.json();

            if(data && data.basketId) {
                console.log('updateItemQuantity received');
                console.log(data);
    
                this.updateState(data.localBasket);
    
                if (doneCallback) doneCallback(data);
                return true;
            }

            throw Error("Error updating basket");
        }
        catch (error) {
            console.log(error);
            if(errorCallback) {
                errorCallback('Error: ' + error);
            }
        }
        return false;
    }

    async removeFromBasket(productId, productSizeVariantId, doneCallback, errorCallback) {
        if(!productId || isNaN(productId)) {
            if(errorCallback) errorCallback('No product selected');
            return false;
        }
        if(!productSizeVariantId || isNaN(productSizeVariantId)) {
            if(errorCallback) errorCallback('No product size selected');
            return false;
        }
        if(!this._basket || !this._basket.basketId) {
            if(errorCallback) errorCallback('Basket is empty');
            return false;
        }

        const localBasketItem = this.getLocalBasketItem(productId, productSizeVariantId);
        if(!localBasketItem) {
            if(errorCallback) errorCallback('The item to be removed is not in the basket');
            return false;
        }

        //request body
        let reqBody = {
            localBasket: this._basket,
            productId: localBasketItem.productId,
            productSizeVariantId: localBasketItem.productSizeVariantId,
        };

        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        const token = await authService.getAccessToken();
        if(token) {
            reqHeader['Authorization'] = `Bearer ${token}`;
        }

        try {
            const response = await fetch('/api/basket/remove', {
                method: 'delete',
                headers: reqHeader,
                body: JSON.stringify(reqBody)
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }        

            const data = await response.json();

            if(data && data.basketId) {
                console.log('removeFromBasket received');
                console.log(data);

                this.updateState(data.localBasket);

                if (doneCallback) doneCallback(data);
                return true;

                //this.updateItemRemovedState(localBasketItem.productId, localBasketItem.productSizeVariantId);
                //if (done) done();
                //return true;
            }
            errorCallback('Error removing product from basket');
        }
        catch (error) {
            console.log(error);
            if(errorCallback) {
                errorCallback('Error: ' + error);
            }
        }

        return false;
    }


    getLocalBasketItem(productId, productSizeVariantId) {
        if(!this._basket || !this._basket.items) return;
        if(!productId || isNaN(productId)) return;
        if(!productSizeVariantId || isNaN(productSizeVariantId)) return;

        const found = this._basket.items
            .filter(element => element.productId === productId &&
                               element.productSizeVariantId === productSizeVariantId);
        if (found.length > 0) {
            return found[0];
        }
        return;
    }
/*
    getLocalBasketItemIndex(productId, productSizeVariantId) {
        if(!this._basket || !this._basket.items) return -1;
        if(!productId || isNaN(productId)) return -1;
        if(!productSizeVariantId || isNaN(productSizeVariantId)) return -1;

        const found = this._basket.items
            .map((element, index) => 
                element.productId === productId &&
                element.productSizeVariantId === productSizeVariantId ? 
                    { found: true, index } : { found: false })
            .filter(element => element.found === true);

        if (found.length > 0) {
            return found[0].index;
        }
        return -1;
    }



    updateItemAddedState(basketItem) {
        if(!basketItem) return;

        let itemIndex = -1;
        if(!this._basket || !this._basket.basketId) {
            this._basket = {
                basketId: basketItem.basketId,
                items: []
            }
        } else {
            itemIndex = this.getLocalBasketItemIndex(basketItem.productId, basketItem.productSizeVariantId)
        }

        if(itemIndex < 0) {
            this._basket.items.push({
                productId: basketItem.productId,
                productSizeVariantId: basketItem.productSizeVariantId,
                quantity: basketItem.quantity
            });
        } else {
            this._basket.items[itemIndex].quantity = basketItem.quantity;
        }

        this.saveLocalBasket();
        this.notifySubscribers();
    }

    updateItemRemovedState(productId, productSizeVariantId) {
        if(!this._basket || !this._basket.items) return;
        if(!productId || isNaN(productId)) return;
        if(!productSizeVariantId || isNaN(productSizeVariantId)) return;

        const itemIndex = this.getLocalBasketItemIndex(productId, productSizeVariantId);

        if(itemIndex < 0) {
            throw new Error(`Could not find a product`);
        }

        this._basket.items = this._basket.items.splice(itemIndex, 1);

        this.saveLocalBasket();
        this.notifySubscribers();
    }
*/

    updateState(basket) {
        //if the two baskets are the same, then don't update
        if((!basket || !basket.basketId) && (!this._basket || !this._basket.basketId)) return;
        if(basket && basket.basketId && this._basket && this._basket.basketId) {
            if(basket.basketId === this._basket.basketId &&
               basket.lastUpdated === this._basket.lastUpdated &&
               basket.totalItems === this._basket.totalItems) {
                return;
               } 
        }

        if(!basket || !basket.basketId) {
            this._basket = null;
        } else {
            this._basket = basket;
        }
        this.saveLocalBasket();
        this.notifySubscribers();
    }

    saveLocalBasket() {
        console.log('saving');
        console.log(this._basket);
        localStorage.setItem(this._localStorageBasketName, JSON.stringify(this._basket));
    }
    loadLocalBasket() {
        try {
            const basket = localStorage.getItem(this._localStorageBasketName);
            if(basket) {
                this._basket = JSON.parse(basket);
            }
        } catch {}
    }
    
    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    static get instance() { return basketService }
}

const basketService = new BasketService();
basketService.init();

export default basketService;
