import React from 'react';
import QRCode from 'qrcode.react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import './CheckoutProcessingWeChat.css';

export function CheckoutProcessingWeChat(props) {
    const { url, orderId } = props;

    const isMobile = ((/Android|iPhone|iPad|Opera Mini/i.test(navigator.userAgent) ||
                     (navigator.platform === 'MacIntel' && navigator.maxTouchPoints && navigator.maxTouchPoints > 1)) 
                     && !window.MSStream);

    return (
        <div className="container cw-pay">
            <div className="row d-flex flex-row-reverse">
                <div className="col-12 col-lg-6 d-flex align-items-center">
                    <div className="wc-sidetxt text-left mx-auto my-4" style={{maxWidth:'400px'}}>
                        <div className="wc-lottie mx-auto mb-4">
                            <Player
                                autoplay
                                loop
                                background="transparent"
                                src="https://assets1.lottiefiles.com/packages/lf20_qfpj11qq.json">
                                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                            </Player>
                        </div>
                        <h1 className="mb-3 text-center">Scan to complete your purchase with WeChat Pay</h1>
                        <h2 className="mb-3 text-center">Order # {orderId}</h2>
                        <p className="mb-4">Please scan this QR code on your phone {isMobile === true ? ' or click the button ' : '' } to complete the purchase and pay with WeChat. The page will automatically redirect once the payment has been received from WeChat, please keep it open until you finish</p>
                        {
                            isMobile === true &&
                            <div className="my-4 text-center">
                                <a href={url} className="btn mx-auto" target="_blank">Proceed To WeChat Pay</a>
                            </div>
                        }
                    </div>
            </div>
                <div className="col-12 col-lg-6 text-center">
                    <QRCode 
                        value={url}
                        size={512}
                        bgColor="#fff"
                        fgColor="#000"
                        level="H"
                        className="wc-qr mb-5" />
                </div>
            </div>
        </div>
    );
}

