import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import emailMarketingService from '../api/EmailMarketingService';
import './EmailMarketingSignUp.css';

export class EmailMarketingSignUp extends Component {

  constructor (props) {
    super(props);
    this.state = {email: null, newsletterInterestOption: 'both', loading: false, showSuccessMessage: false };
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  async handleSubscribe(e) {
    e.preventDefault();

    const {email, newsletterInterestOption} = this.state;

    if (!email || !emailMarketingService.validateEmail(email)) {
      alert('Email is not valid');
      return;
    }

    this.setState({loading: true}); 

    const res = await emailMarketingService.subscribeEmail(
        email,
        newsletterInterestOption,
        function(success) {
          this.setState({loading: false}); 
          if(success == true) {
            //success
            this.setState({
                showSuccessMessage: true
            });
          }
        }.bind(this), 
        function(error) {
          this.setState({loading: false}); 
          alert(error);
        }.bind(this)
    );
  }

  render () {
    const {newsletterInterestOption, showSuccessMessage} = this.state;
    if(showSuccessMessage) {
      return(
        <div className="my-4 text-uppercase">
          Thank you for signing up to Flair's mailing list.
        </div>
      );
    }
    return (
      <form onSubmit={this.handleSubscribe}>
        <div className="mr-auto mailinglist">
          <p>Sign up for email updates and designer promotions</p>
          <div className="mb-2">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" 
                     name="newsletterInterestOption" 
                     id="newsletterInterestOption-womenswear" 
                     value="womenswear"
                     checked={newsletterInterestOption==='womenswear'}
                     onChange={this.handleInputChange} />
              <label className="form-check-label" htmlFor="newsletterInterestOption-womenswear">Womenswear</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" 
                     name="newsletterInterestOption" 
                     id="newsletterInterestOption-menswear" 
                     value="menswear"
                     checked={newsletterInterestOption==='menswear'}
                     onChange={this.handleInputChange} />
              <label className="form-check-label" htmlFor="newsletterInterestOption-menswear">Menswear</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" 
                     name="newsletterInterestOption" 
                     id="newsletterInterestOption-both" 
                     value="both"
                     checked={newsletterInterestOption==='both'}
                     onChange={this.handleInputChange} />
              <label className="form-check-label" htmlFor="newsletterInterestOption-both">Both</label>
            </div>
          </div>
          <div className="clearfix mb-2">
            <input name="email" placeholder="Email address" type="email" 
                   onChange={this.handleInputChange} defaultValue={""} required 
                   className="form-control mailinglist-email" />
            <input type="submit" className="product-input btn btn-secondary mailinglist-btn" disabled={this.state.loading}>{this.state.buttonText}</input>
          </div>
          <small className="form-text text-muted">You may unsubscribe at any time. To find out more, please visit our <Link className="" to="/privacy-policy">Privacy Policy</Link>.</small>
        </div>
      </form>
    );
  }
}
