import React, { Fragment, Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { CardElement, IbanElement, IdealBankElement } from 'react-stripe-elements';
import './PaymentSection.css';

export class PaymentSection extends Component {
  static displayName = PaymentSection.name;
  
  constructor (props) {
    super(props);
    this.state = {
      activePaymentTab: PaymentSection.paymentMethods.card.id
    };
    this.props.paymentMethodTypeChange(PaymentSection.paymentMethods.card.id);
    this.getTabClassNames = this.getTabClassNames.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.changeTab = this.changeTab.bind(this);
    //showPaymentMethods(this.props.paymentCountry);
  }

  componentDidUpdate(prevProps) {
    //reset the tabs to show the first one when country changes
    //card tab is always first and visiable
    
    //TODO
    //this causes the component to be rendered twice, first when the props changes and after this func, 
    //it will setstate and get the component to render again
    if (this.props.paymentCountry !== prevProps.paymentCountry) {
        this.changeTab(PaymentSection.paymentMethods.card.id)
    }
  }  

  getTabClassNames(paymentMethodId, country) {
    //we always want to show the card payment tab for all countries
    if(paymentMethodId !== PaymentSection.paymentMethods.card.id &&
       (!country || country.length <= 0 ||
       !PaymentSection.paymentMethods[paymentMethodId].countries.includes(country.toUpperCase()))) {
        return 'd-none';
    }
    else if(this.state.activePaymentTab === paymentMethodId) {
        return 'active';
    }
    return '';
  }

  getTabPaneClassNames(paymentMethodId, country) {
    if(paymentMethodId !== PaymentSection.paymentMethods.card.id &&
       (!country || country.length <= 0 ||
       !PaymentSection.paymentMethods[paymentMethodId].countries.includes(country.toUpperCase()))) {
        return 'd-none';
    }
    return '';
  }

  setActiveTab(event) {
    event.preventDefault();
    this.changeTab(event.target.dataset.tab);
  }

  changeTab(paymentMethodId) {
    this.setState({
        activePaymentTab: paymentMethodId
    });
    this.props.paymentMethodTypeChange(paymentMethodId);
  }

  render() {
    const country = this.props.paymentCountry;

    return (
        <div>
            <h2 className="d-flex justify-content-between align-items-center mb-3">3. Payment Method</h2>
            <Nav tabs>
                <NavItem>
                    <NavLink className={this.getTabClassNames(PaymentSection.paymentMethods.card.id, country)} data-tab={PaymentSection.paymentMethods.card.id} onClick={this.setActiveTab}>
                        Card
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={this.getTabClassNames(PaymentSection.paymentMethods.sepa_debit.id, country)} data-tab={PaymentSection.paymentMethods.sepa_debit.id} onClick={this.setActiveTab}>
                        SEPA Direct Debit
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={this.getTabClassNames(PaymentSection.paymentMethods.ideal.id, country)} data-tab={PaymentSection.paymentMethods.ideal.id} onClick={this.setActiveTab}>
                        iDEAL
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={this.getTabClassNames(PaymentSection.paymentMethods.wechat.id, country)} data-tab={PaymentSection.paymentMethods.wechat.id} onClick={this.setActiveTab}>
                        WeChat
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={this.getTabClassNames(PaymentSection.paymentMethods.alipay.id, country)} data-tab={PaymentSection.paymentMethods.alipay.id} onClick={this.setActiveTab}>
                        AliPay
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activePaymentTab} className="py-4">
                <TabPane tabId={PaymentSection.paymentMethods.card.id} className={this.getTabPaneClassNames(PaymentSection.paymentMethods.card.id, country)}>
                    <Row>
                        <Col sm="12">
                            <label className="w-100">
                                <span className="custom-lbl">Enter your card details</span>
                                <CardElement className="custom-element" style={PaymentSection.elementStyle} />
                            </label>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={PaymentSection.paymentMethods.sepa_debit.id} className={this.getTabPaneClassNames(PaymentSection.paymentMethods.sepa_debit.id, country)}>
                    <Row>
                        <Col sm="12">
                            <label className="w-100">
                                <span className="custom-lbl">IBAN</span>
                                <IbanElement supportedCountries={['SEPA']} className="custom-element" style={PaymentSection.elementStyle} />
                            </label>
                            <div className="text-muted pt-2">
                                By providing your IBAN and confirming this payment, you’re authorizing Payments Demo and Stripe, our payment provider, to send instructions to your bank to debit your account. You’re entitled to a refund under the terms and conditions of your agreement with your bank.
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={PaymentSection.paymentMethods.ideal.id} className={this.getTabPaneClassNames(PaymentSection.paymentMethods.ideal.id, country)}>
                    <Row>
                        <Col sm="12">
                            <label className="w-100">
                                <span className="custom-lbl">iDEAL Bank</span>
                                <IdealBankElement className="custom-element ideal-bank-element" style={PaymentSection.idealElementStyle} />
                            </label>
                            <div className="text-muted pt-2">
                                You’ll be redirected to the banking site to complete your payment.
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={PaymentSection.paymentMethods.wechat.id} className={this.getTabPaneClassNames(PaymentSection.paymentMethods.wechat.id, country)}>
                    <Row>
                        <Col sm="12">
                            <p>You have selected WeChat Pay as a payment method.</p>
                            <p>When placing your order, you will be redirected and a WeChat QR code will be generated to complete your purchase.</p>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={PaymentSection.paymentMethods.alipay.id} className={this.getTabPaneClassNames(PaymentSection.paymentMethods.alipay.id, country)}>
                    <Row>
                        <Col sm="12">
                            <p>You have selected AliPay as a payment method.</p>
                            <p>When placing your order, you will be redirected to the AliPay payment page. Once you have completed the payment, you will be redirected back to Flair to confirm your order.</p>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    );
    return (
        <Fragment>
            <h2 className="d-flex justify-content-between align-items-center mb-3">3. Payment</h2>
            <nav id="payment-methods">
                <ul>
                    <li>
                        <input type="radio" name="payment" id="payment-card" value="card" defaultChecked={"true"} />
                        <label htmlFor="payment-card">Card</label>
                    </li>
                    <li>
                        <input type="radio" name="payment" id="payment-ach_credit_transfer" value="ach_credit_transfer" />
                        <label htmlFor="payment-ach_credit_transfer">Bank Transfer</label>
                    </li>
                    <li>
                        <input type="radio" name="payment" id="payment-alipay" value="alipay" />
                        <label htmlFor="payment-alipay">Alipay</label>
                    </li>
                    <li>
                        <input type="radio" name="payment" id="payment-bancontact" value="bancontact" />
                        <label htmlFor="payment-bancontact">Bancontact</label>
                    </li>
                    <li>
                        <input type="radio" name="payment" id="payment-eps" value="eps" />
                        <label htmlFor="payment-eps">EPS</label>
                    </li>
                    <li>
                        <input type="radio" name="payment" id="payment-ideal" value="ideal" />
                        <label htmlFor="payment-ideal">iDEAL</label>
                    </li>
                    <li>
                        <input type="radio" name="payment" id="payment-giropay" value="giropay" />
                        <label htmlFor="payment-giropay">Giropay</label>
                    </li>
                    <li>
                        <input type="radio" name="payment" id="payment-multibanco" value="multibanco" />
                        <label htmlFor="payment-multibanco">Multibanco</label>
                    </li>
                    <li>
                        <input type="radio" name="payment" id="payment-sepa_debit" value="sepa_debit" />
                        <label htmlFor="payment-sepa_debit">SEPA Direct Debit</label>
                    </li>
                    <li>
                        <input type="radio" name="payment" id="payment-sofort" value="sofort" />
                        <label htmlFor="payment-sofort">SOFORT</label>
                    </li>
                    <li>
                        <input type="radio" name="payment" id="payment-wechat" value="wechat" />
                        <label htmlFor="payment-wechat">WeChat Pay</label>
                    </li>
                </ul>
            </nav>
            <div className="payment-info card visible">
                <fieldset>
                    <label>
                    <span>Card</span>
                    <div id="card-element" className="field"></div>
                    </label>
                </fieldset>
            </div>
            <div className="payment-info sepa_debit">
                <fieldset>
                    <label>
                    <span>IBAN</span>
                    <div id="iban-element" className="field"></div>
                    </label>
                </fieldset>
                <p className="notice">By providing your IBAN and confirming this payment, you’re authorizing Payments Demo and Stripe, our payment
                    provider, to send instructions to your bank to debit your account. You’re entitled to a refund under the terms
                    and conditions of your agreement with your bank.</p>
            </div>
            <div className="payment-info ideal">
                <fieldset>
                    <label>
                    <span>iDEAL Bank</span>
                    <div id="ideal-bank-element" className="field"></div>
                    </label>
                </fieldset>
            </div>
            <div className="payment-info redirect">
                <p className="notice">You’ll be redirected to the banking site to complete your payment.</p>
            </div>
            <div className="payment-info receiver">
                <p className="notice">Payment information will be provided after you place the order.</p>
            </div>
            <div className="payment-info wechat">
                <div id="wechat-qrcode"></div>
                <p className="notice">Click the button below to generate a QR code for WeChat.</p>
            </div>
        </Fragment>
    );
  }

  static elementStyle = {
    base: {
      iconColor: '#666ee8',
      color: '#495057',//'#31325f',
      fontWeight: 400,
      fontFamily:
        '"Open Sans", sans-serif',
        //'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#999',
      },
      ':-webkit-autofill': {
        color: '#666ee8',
      },
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
    }
  };
  static idealElementStyle = {
    base: Object.assign({padding: '8px 15px'}, PaymentSection.elementStyle.base),
    invalid: PaymentSection.elementStyle.invalid
  };

  /*
  {
    base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#aab7c4'
        }
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
    }
  }
  */

  

  // List of relevant countries for the payment methods supported in this demo.
  // Read the Stripe guide: https://stripe.com/payments/payment-methods-guide
  static paymentMethods = {
    ach_credit_transfer: {
      id: 'ach_credit_transfer',
      name: 'Bank Transfer',
      flow: 'receiver',
      countries: ['US'],
      currencies: ['usd'],
    },
    alipay: {
      id: 'alipay',
      name: 'Alipay',
      flow: 'redirect',
      countries: ['CN', 'HK', 'SG', 'JP', 'GB'],
      currencies: [
        'aud',
        'cad',
        'eur',
        'gbp',
        'hkd',
        'jpy',
        'nzd',
        'sgd',
        'usd',
      ],
    },
    bancontact: {
      id: 'bancontact',
      name: 'Bancontact',
      flow: 'redirect',
      countries: ['BE'],
      currencies: ['eur'],
    },
    card: {
      id: 'card',
      name: 'Card',
      flow: 'none',
    },
    eps: {
      id: 'eps',
      name: 'EPS',
      flow: 'redirect',
      countries: ['AT'],
      currencies: ['eur'],
    },
    ideal: {
      id: 'ideal',
      name: 'iDEAL',
      flow: 'redirect',
      countries: ['NL'],
      currencies: ['eur'],
    },
    giropay: {
      id: 'giropay',
      name: 'Giropay',
      flow: 'redirect',
      countries: ['DE'],
      currencies: ['eur'],
    },
    multibanco: {
      id: 'multibanco',
      name: 'Multibanco',
      flow: 'receiver',
      countries: ['PT'],
      currencies: ['eur'],
    },
    sepa_debit: {
      id: 'sepa_debit',
      name: 'SEPA Direct Debit',
      flow: 'none',
      countries: [
        'FR',
        'DE',
        'ES',
        'BE',
        'NL',
        'LU',
        'IT',
        'PT',
        'AT',
        'IE',
        'FI',
      ],
      currencies: ['eur'],
    },
    sofort: {
      id: 'sofort',
      name: 'SOFORT',
      flow: 'redirect',
      countries: ['DE', 'AT'],
      currencies: ['eur'],
    },
    wechat: {
      id: 'wechat',
      name: 'WeChat',
      flow: 'none',
      countries: ['CN', 'HK', 'SG', 'JP', 'GB'],
      currencies: [
        'aud',
        'cad',
        'eur',
        'gbp',
        'hkd',
        'jpy',
        'nzd',
        'sgd',
        'usd',
      ],
    },
  };

}