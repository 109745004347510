import React from 'react';
import { ProductListingItem } from './ProductListingItem';

export function ProductsListing(props) {
    let products = props.products;

    let content = products.map(p =>
        <div key={p.id} className="col-6 col-md-6 col-lg-4 col-xl-3">
            <ProductListingItem product={p} />
        </div>
    );

    return (
        <div className="productlisting">
            <div className="row">
                {content}
            </div>
        </div>
    );
}
