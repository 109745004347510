import React from 'react';
import { Link } from 'react-router-dom';
import './CategoriesSideListing.css';

export function DesignersSideListing(props) {
    let designers = props.designers;
    let categoryBaseUrl = props.categoryBaseUrl;

    let anyDesignerSelected = false;

    let content = designers.map((d) => {
        anyDesignerSelected = anyDesignerSelected || d.isSelected;
        return (
            <div key={d.id} className="text-left" style={{ marginLeft: '0.8rem' }}>
                <Link to={`${categoryBaseUrl}/designer/${d.friendlyUrlName}`} className={'' + (d.isSelected?'font-weight-bold cat-selected':'')}>
                    {d.brandTitle}
                </Link>
            </div>
        );
    });
        
    return (
        <div className="store-categories mt-4">
            <div className="text-left mb-1">
                <Link to={categoryBaseUrl} className={'' + (!anyDesignerSelected?'font-weight-bold cat-selected':'')}>
                    View All Designers
                </Link>
            </div>
            {content}
        </div>
    );
}
