import React, { Fragment } from 'react';
import { BasketItemsGroup } from '../basket/BasketItemsGroup';

export function BasketDetails(props) {
    const basket = props.basket;

    let content = basket.basketItemsGroups.map((group) =>
      <BasketItemsGroup key={group.designer.id.toString()} group={group} hideButtons={true} />
    );

    return (
        <Fragment>
            <h2 className="d-flex justify-content-between align-items-center mb-4">4. Review Your Bag</h2>
            {content}
            <div className="row mt-4">
              <div className="col-9 col-sm-10 col-md-10 text-right text-muted">
                  Total cost
              </div>
              <div className="col-3 col-sm-2 col-md-2 text-right">
                  {basket.totalCostCurrency}
              </div>
            </div>
        </Fragment>
    );
}

