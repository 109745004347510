import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './ProductListingItem.css';

export function ProductListingItem(props) {
    const { product, itemSmallBottomPadding } = props;

    let titles;
    if(product.designer != null) {
        titles = (
            <Fragment>
                <h3 className="brand mb-2"><Link to={`/${product.designer.friendlyUrlName}`} className="">{product.designer.brandTitle}</Link></h3>
                <h1 className="text-uppercase mb-1">
                    <Link to={`/p/${product.id}/${product.friendlyUrlName}`}>
                        {product.title}
                    </Link>
                </h1>
            </Fragment>
        );
    } else {
        titles = (
            <Fragment>
                <h1 className="text-uppercase mb-1">
                    <Link to={`/p/${product.id}/${product.friendlyUrlName}`}>
                        {product.title}
                    </Link>
                </h1>
            </Fragment>
        );
    }

    return (
        <article className={itemSmallBottomPadding ? "productlistingitem mb-5" : "productlistingitem mb-5 pb-5"}>
            <figure className="product-image">
                <Link to={`/p/${product.id}/${product.friendlyUrlName}`} className="d-block">
                    <img src={product.id < 19167 ? product.listingThumbnail.url : process.env.REACT_APP_MEDIAURL_PRODUCT_LISTINGIMG + product.listingThumbnail.url} alt={product.title} className="w-100" />
                </Link>
            </figure>
            <div className="product-meta">
                {titles}
                <span className="price">{product.unitPriceCurrency}</span>
                <p>{displayProductStatus(product.status)}</p>
            </div>
        </article>
    );
}

function displayProductStatus(status) {
    switch (status) {
        case 1:
            return 'Out of stock'
        case 2:
            return 'Not available'
        default:
            return '';
    }
}