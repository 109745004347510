import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { CheckoutProcessingWeChat } from './CheckoutProcessingWeChat'

import checkoutService from '../api/CheckoutService';
import qs from 'qs';

export class CheckoutProcessingPage extends Component {
  static displayName = CheckoutProcessingPage.name;

  constructor (props) {
    super(props);
    this.timer = null;
    const query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true
    })
    const { orderId } = this.props.match.params
    this.state = {
        stripe: null,
        orderId: orderId,
        sourceId: query.source,
        source: null,
        client_secret: query.client_secret,
        paymentIntentId: query.paymentIntent,
        pageProcessStatus: CheckoutProcessingPage.pageProcessStatus.processing,
        error: null,
        order: null,
        completeUrl: null,
    };
    this.start = this.start.bind(this);
    this.processPaymentSource = this.processPaymentSource.bind(this);
    this.redirectToNextStep = this.redirectToNextStep.bind(this);
    this.handlePaymentIntentResult = this.handlePaymentIntentResult.bind(this);
    this.pollPaymentIntentStatus = this.pollPaymentIntentStatus.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    if (window.Stripe) {
      //TODO
      //CHANGE THE KEY FOR THE LIVE SITE
      this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_API_PUBLIC_KEY)}, function () {
        this.start();
      });
    }
    else {
      const stripeJs = document.createElement('script');
      stripeJs.src = 'https://js.stripe.com/v3/';
      stripeJs.async = true;
      stripeJs.onload = () => {
        //TODO
        // The setTimeout lets us pretend that Stripe.js took a long time to load
        // Take it out of your production code!
        setTimeout(() => {
          this.setState({
            //TODO
            //CHANGE THE KEY FOR THE LIVE SITE
            stripe: window.Stripe(process.env.REACT_APP_STRIPE_API_PUBLIC_KEY),
          });
          this.start();
        }, 500);
      };
      document.body && document.body.appendChild(stripeJs);
    }
  }
  
  componentWillUnmount() {
    this._mounted = false;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }


  start() {
    const { sourceId, client_secret, paymentIntentId, orderId } = this.state;

    if(paymentIntentId) {
        if (sourceId && client_secret) {
          this.processPaymentSource(sourceId, client_secret, paymentIntentId);
        } else {
          // Poll the PaymentIntent status.
          this.pollPaymentIntentStatus(paymentIntentId, orderId);
        }
    }
    else {
        console.log('FAILED TO START PROCESS PAYMENT, redirecting to home')
        this.redirectToNextStep('/');
    }          
  }

  async processPaymentSource(sourceId, client_secret, paymentIntentId) {
    const { stripe, orderId } = this.state;
    let sourceObj;
    try {
        const { source, error } = await stripe.retrieveSource({
            id: sourceId,
            client_secret: client_secret,
        });
        if(error) {
            console.log(error);
            throw new Error("failed");
        }
        sourceObj = source;
        console.log(source);
    } catch (e) {
        //TODO
        //SHOW ERROR
        console.log(e);
        this.setError('Error retrieving source:' + e);
    }
    if(sourceObj) {
        this.setState({
            source: sourceObj
        });

        if(sourceObj.flow === 'none' && sourceObj.type === 'wechat') {
            // Poll the PaymentIntent status.
            console.log('processing wechat');
            this.pollPaymentIntentStatus(paymentIntentId, orderId, 300000);
        } else {
            // Poll the PaymentIntent status.
            console.log('processing redirect payments');
            this.pollPaymentIntentStatus(paymentIntentId, orderId);
        }
    } else {
        //TODO
        //SHOW ERROR
        this.setError('Payment source could not be found');
    }
  }



  async pollPaymentIntentStatus(paymentIntentId,
                                orderId,
                                timeout = 60000,
                                interval = 1000,
                                start = null) {
    if(!this._mounted) {
      return;
    }
    start = start ? start : Date.now();
    const endStates = ['checkoutcomplete', 'checkoutfailed'];
    // Retrieve the PaymentIntent status from our server.
    //const rawResponse = await fetch(`checkout/payment/${this.state.orderId}/${paymentIntentId}/status`);
    //const response = await rawResponse.json();
    console.log('Checking intent id: ' + paymentIntentId);
    const response = await checkoutService.getCheckoutOrderPaymentIntent(orderId, paymentIntentId)
    if(!response || !response.requestSuccess) {
      console.log('FAILED')
      //this.redirectToNextStep('/');
      //return;
    }
    if (!endStates.includes(response.payment.status) &&
        Date.now() < start + timeout) {
      // Not done yet. Let's wait and check again.
      this.timer = setTimeout(
        this.pollPaymentIntentStatus,
        interval,
        paymentIntentId,
        orderId,
        timeout,
        interval,
        start
      );
    }
    else {
      this.handlePaymentIntentResult(response.payment, orderId);
      if (!endStates.includes(response.payment.status)) {
        // Status has not changed yet. Let's time out.
        console.warn(new Error('Polling timed out.'));
      }
    }
  }


  // Handle new PaymentIntent result
  async handlePaymentIntentResult(paymentIntent, orderId) {
    if(paymentIntent.status === 'checkoutcomplete') {
      console.log('[PaymentIntent]', paymentIntent);
      //console.log(order);
      //REDIRECT USER TO CHECKOUT COMPLETE
      console.log('REDIRECT');
      this.redirectToNextStep(this.getCompleteUrl(orderId));
    } else if(paymentIntent.status === 'checkoutfailed') {
      console.log('REDIRECT');
      this.redirectToNextStep(this.getFailedUrl(orderId));
      //this.setError('Payment failed');
    } else {
      console.log('ERROR');
      this.setError('Payment failed or something went wrong.');
    }
  }


  redirectToNextStep(completeUrl) {
    this.setState({
        pageProcessStatus: CheckoutProcessingPage.pageProcessStatus.complete,
        completeUrl: completeUrl
    })
  }

  setError(error) {
    this.setState({
        error: error
    })
  }

  getCompleteUrl(orderId) {
    return `/checkout/complete/${orderId}`;
  }
  getFailedUrl(orderId) {
    return `/checkout/complete/${orderId}/failed`;
  }

  getErrorMessage() {
    if(!this.state.error || this.state.error.length <= 0)
        return;
    let message;
    if(this.state.error) {
        message = (
            <Fragment>
                <hr />
                <p className="mb-0">{this.state.error}</p>
            </Fragment>
        );
    }
    return (
        <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Oh no!</h4>
            <p>There was an error when processing your order. Please check your information and try again.</p>
            <p>If this continues please get in touch with us to see how we can help resolve the issue. <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_EMAIL}</a> or WhatsApp message on <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_PHONENUMBER}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_CONTACT_PHONENUMBER_DISPLAY}</a>.</p>
            {message}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
  }



  render() {
    if (this.state.pageProcessStatus === CheckoutProcessingPage.pageProcessStatus.complete)
        return (
            <Redirect to={this.state.completeUrl} />
        );

    const { source } = this.state;
    let actionContent;

    if(source && source.flow === 'none' && source.type === 'wechat') {
        actionContent = <CheckoutProcessingWeChat url={source.wechat.qr_code_url} orderId={this.state.orderId} />;
    } else {
        actionContent = (
          <div>
            <h1 className="text-center mb-4 mt-5" style={{fontSize: '3em'}}>
              Processing your payment..
            </h1>
            <h2 className="text-center">Do not close this window</h2>
          </div>
        );
    }

    const errorMessage = this.getErrorMessage();

    return (
        <div>
            {errorMessage}
            {actionContent}
        </div>

    );
  }

  static pageProcessStatus = {
      processing: 0,
      complete: 1,
  }
}
