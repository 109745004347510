import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from '../api-authorization/LoginMenu';
import { BasketIconMenu } from '../basket/BasketIconMenu';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleMobileNavbar = this.toggleMobileNavbar.bind(this);
    this.state = {
      collapsed: false
    };
  }

  toggleMobileNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    if(this.state.collapsed) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = null;
    }

    return (
      <header className={this.state.collapsed || true ? 'sticky-top' : ''}>
        <Navbar className="navbar-expand-md navbar-toggleable-md navbar-light sticky-top mb-3 px-0" light>
          <Container fluid={true} className="px-4">
            <div className="col-5 px-0 leftMenu">
              <ul className="navbar-nav d-none d-md-flex">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/shop">Shop designers</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/showcase">Showcasing</NavLink>
                </NavItem>
                {/*
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/artefacts">Artefacts</NavLink>
                </NavItem>
                */}
              </ul>
            </div>
            <div className="col-2 px-0 text-center abstop">
              <NavbarBrand href={process.env.REACT_APP_URL_DEFAULT_DOMAIN} className="logo">Flair</NavbarBrand>
            </div>
            <div className="col-5 px-0 text-right rightMenu abstop">
              <ul className="navbar-nav d-md-flex justify-content-end">
                <NavItem className="d-none d-md-block">
                  <NavLink tag={Link} className="text-dark" to="/about">About Flair</NavLink>
                </NavItem>
                <NavItem className="d-none d-md-block">
                  <NavLink tag={Link} className="text-dark" to="/join-flair">Join as a designer</NavLink>
                </NavItem>
                <BasketIconMenu />
                {/*
                <LoginMenu>
                </LoginMenu>
                */}
              </ul>
            </div>
            <div className="d-md-none">
              <input type="checkbox" id="nav-overlay-input" className="nav-overlay-checkbox" onChange={this.toggleMobileNavbar} checked={this.state.collapsed}  />
              <label htmlFor="nav-overlay-input" className="nav-overlay-button"><span></span></label>
              <div id="mobile-nav-overlay">
                <ul className="pt-5 mt-3">
                  <NavItem>
                    <NavLink tag={Link} to="/shop" onClick={this.toggleMobileNavbar} className="text-uppercase">Shop designers</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/showcase" onClick={this.toggleMobileNavbar} className="text-uppercase">Showcasing</NavLink>
                  </NavItem>
                  {/*
                  <NavItem>
                    <NavLink tag={Link} to="/artefacts" onClick={this.toggleMobileNavbar}>Artefacts</NavLink>
                  </NavItem>
                  */}
                  <NavItem>
                    <NavLink tag={Link} to="/designers" onClick={this.toggleMobileNavbar} className="text-uppercase">Show All Designers</NavLink>
                  </NavItem>
                </ul>
                <hr/>
                <ul>
                  <NavItem>
                    <NavLink tag={Link} to="/about" onClick={this.toggleMobileNavbar}>About</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/join-flair" onClick={this.toggleMobileNavbar}>Join Flair</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/terms-and-conditions" onClick={this.toggleMobileNavbar}>Terms &amp; Conditions</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/privacy-policy" onClick={this.toggleMobileNavbar}>Privacy Policy</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/return-policy" onClick={this.toggleMobileNavbar}>Returns &amp; Refunds</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/faq" onClick={this.toggleMobileNavbar}>FAQ</NavLink>
                  </NavItem>
                  <NavItem>
                    <a className="nav-link nav-icon" href="https://www.instagram.com/flair.fashionofficial/" onClick={this.toggleMobileNavbar} target="_blank">
                      Follow on Instagram
                    </a>
                  </NavItem>
                  <NavItem className="text-left px-4 text-muted">
                    &copy; 2020 Flair Fashion Limited. All rights reserved.
                  </NavItem>
                </ul>
              </div>
            </div>
          </Container>
        </Navbar>
      </header>
    );
  }
}
