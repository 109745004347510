import React, { Component } from 'react';
import { ProductsListing } from './ProductsListing';
import { CategoriesSideListing } from './CategoriesSideListing';
import { DesignersSideListing } from './DesignersSideListing';
import { StoreCatalogueHeader } from './StoreCatalogueHeader';
import { StoreCataloguePaging } from './StoreCataloguePaging';

export class StoreCataloguePage extends Component {
    static displayName = StoreCataloguePage.name;

    constructor(props) {
        super(props);
        this.state = { rootCategory: [], designers: [], products: [], loading: true, selectedDesigner: null, selectedCategory: null, paginationInfo: null };
        this.newData = this.newData.bind(this);
        this.getCategoryBaseUrl = this.getCategoryBaseUrl.bind(this);
    }

    componentDidMount() {
        this.newData();
    }

    componentWillReceiveProps(nextProps) {
        if(
            this.props.match.params.rootCategoryUrl !== nextProps.match.params.rootCategoryUrl ||
            this.props.match.params.child1CategoryUrl !== nextProps.match.params.child1CategoryUrl ||
            this.props.match.params.child2CategoryUrl !== nextProps.match.params.child2CategoryUrl ||
            this.props.match.params.child3CategoryUrl !== nextProps.match.params.child3CategoryUrl ||
            this.props.match.params.designerUrl !== nextProps.match.params.designerUrl ||
            this.props.match.params.pageNum !== nextProps.match.params.pageNum
        ) 
        {
            this.props = nextProps;
            this.newData();
        }
    }
 
    newData() {
        console.log(this.props.match.params);
        const { rootCategoryUrl, child1CategoryUrl, child2CategoryUrl, child3CategoryUrl, designerUrl, pageNum } = this.props.match.params
        this.fetchData(rootCategoryUrl, child1CategoryUrl, child2CategoryUrl, child3CategoryUrl, designerUrl, pageNum);        
    }

    getCategoryBaseUrl() {
        return '/' + this.props.match.params.rootCategoryUrl +
                (this.props.match.params.child1CategoryUrl ? '/' + this.props.match.params.child1CategoryUrl : '') +
                (this.props.match.params.child2CategoryUrl ? '/' + this.props.match.params.child2CategoryUrl : '') +
                (this.props.match.params.child3CategoryUrl ? '/' + this.props.match.params.child3CategoryUrl : '')
    }
    
    render() {
        if(this.state.loading) {
            return (
                <p><em>Loading...</em></p>
            );
        }
        let categoryBaseUrl = this.getCategoryBaseUrl();
        return (
            <div className="row">
                <div className="col-md-3 col-lg-2 d-none d-md-block" style={{ marginTop: '-100px', zIndex: '1' }}>
                    <div className="sticky-top" style={{ paddingTop: '75px' }}>
                        <CategoriesSideListing path='/' category={this.state.rootCategory} />
                        <DesignersSideListing categoryBaseUrl={categoryBaseUrl} designers={this.state.designers} />
                    </div>
                </div>
                <div className="col-12 col-md-9 col-lg-10">
                    <div className="mb-4">
                        <StoreCatalogueHeader 
                            rootCategory={this.state.rootCategory}
                            selectedCategory={this.state.selectedCategory} 
                            selectedDesigner={this.state.selectedDesigner}
                            categoryBaseUrl={categoryBaseUrl} />
                    </div>
                    <ProductsListing products={this.state.products} />
                    <StoreCataloguePaging paging={this.state.paginationInfo} categoryBaseUrl={categoryBaseUrl} selectedDesigner={this.state.selectedDesigner} />
                </div>
            </div>
        );
    }

    async fetchData(rootCategoryUrl, child1CategoryUrl, child2CategoryUrl, child3CategoryUrl, designerUrl, pageNum) {
        let url = rootCategoryUrl + '/';
        if(child1CategoryUrl) url += child1CategoryUrl + '/';
        if(child2CategoryUrl) url += child2CategoryUrl + '/';
        if(child3CategoryUrl) url += child3CategoryUrl + '/';

        let query = '';
        if(designerUrl) query += 'designerUrlName=' + designerUrl + '&';
        query += 'currentPage=' + (pageNum ? pageNum + '' : '1');

        const response = await fetch(`api/storecatalogue/${url}?${query}`, {
        });
        const data = await response.json();
        this.setState({ 
            rootCategory: data.rootCategory,
            designers: data.designers,
            products: data.products, 
            selectedDesigner: data.selectedDesigner, 
            selectedCategory: data.selectedCategory,
            paginationInfo: data.paginationInfo,
            loading: false
        });
    }
}
