import React, { Fragment, Component } from 'react';
import { Collapse } from 'reactstrap';

export class ShippingSection extends Component {
    static displayName = ShippingSection.name;

    constructor (props) {
      super(props);
      this.state = {
        showState: false,
        stateRequired: false,
        stateLabel: 'County',
        postCodeLabel: 'Postal Code',
        hideAddress: true,
      };
      this.countryChange = this.countryChange.bind(this);
      this.handleAddressVisibility = this.handleAddressVisibility.bind(this);
    }

    countryChange(event) {
      event.preventDefault();
      switch(event.target.value) {
        case 'us':
          this.setState({
            showState: true,
            stateRequired: true,
            stateLabel: 'State',
            postCodeLabel: 'ZIP'
          });
          break;
        case 'gb':
          this.setState({
            showState: true,
            stateRequired: false,
            stateLabel: 'County',
            postCodeLabel: 'Postcode'
          });
          break;
        default:
          this.setState({
            showState: false,
            stateRequired: false,
            stateLabel: 'County',
            postCodeLabel: 'Postal Code'
          });
      }
      this.props.handleChange(event);
    }

    handleAddressVisibility(event) {
      this.setState({
        hideAddress: event.target.checked,
      });
      this.props.handleChange(event);
    }


    render() {
      return (
        <Fragment>
          <h2 className="d-flex justify-content-between align-items-center mb-3">2. Shipping Address</h2>

          <div className="form-check mb-3">
            <input
                id="shipping_sameAddressAsBilling"
                name="sameAddressAsBilling"
                type="checkbox"
                className="form-check-input"
                defaultChecked={this.state.hideAddress}
                onChange={this.handleAddressVisibility} />
            <label className="form-check-label" htmlFor="shipping_sameAddressAsBilling">Same address for shipping?</label>
          </div>

          <Collapse isOpen={!this.state.hideAddress}>
            <p>We will ship your order to the following address</p>

            {
              !this.state.hideAddress &&
              <div>
                <div className="row">
                    <div className="col-6 mb-3">
                        <label htmlFor="shipping_firstname">First name</label>
                        <input type="text" className="form-control" id="shipping_firstname" name="firstName" onChange={this.props.handleChange} placeholder="" defaultValue={""} required />
                        <div className="invalid-feedback">
                            Valid first name is required.
                        </div>
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="shipping_lastName">Last name</label>
                        <input type="text" className="form-control" id="shipping_lastName" name="lastName" onChange={this.props.handleChange} placeholder="" defaultValue={""} required />
                        <div className="invalid-feedback">
                        Valid last name is required.
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="shipping_address">Address</label>
                    <input type="text" className="form-control" id="shipping_address" name="address" onChange={this.props.handleChange} placeholder="1234 Main St" required />
                    <div className="invalid-feedback">
                        Please enter your shipping address.
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="shipping_city">City</label>
                    <input type="text" className="form-control" id="shipping_city" name="city" onChange={this.props.handleChange} placeholder="London" required />
                    <div className="invalid-feedback">
                        Please enter your shipping city.
                    </div>
                </div>

                <div className="row">
                    <div className={(!this.state.showState ? 'col-md-8 mb-3' : 'col-md-5 mb-3')}>
                        <label htmlFor="shipping_country">Country</label>
                        <select onChange={this.countryChange} className="custom-select d-block w-100" id="shipping_country" name="country" required>
                            <option value="">Choose...</option>
                            <option value="gb">United Kingdom</option>
                            {/*
                            <option value="de">Germany</option>
                            <option value="us">United States</option>
                            */}
                        </select>
                        <div className="invalid-feedback">
                            Please select a valid country.
                        </div>
                    </div>
                    <div className={'col-6 col-md-4 mb-3 ' + (!this.state.showState ? ' d-none' : '')}>
                        <label htmlFor="shipping_county">{this.state.stateLabel} <span className={'text-muted' + (this.state.stateRequired ? ' d-none' : '')}>(Optional)</span></label>
                        <input type="text" className="form-control" id="shipping_county" name="county" onChange={this.props.handleChange} placeholder="" />
                    </div>
                    <div className={(!this.state.showState ? 'col-md-4 mb-3' : 'col-6 col-md-3 mb-3')}>
                        <label htmlFor="shipping_postcode">{this.state.postCodeLabel}</label>
                        <input type="text" className="form-control" id="shipping_postcode" name="postCode" onChange={this.props.handleChange} placeholder="" required />
                        <div className="invalid-feedback">
                            Postcode is required.
                        </div>
                    </div>
                </div>
              </div>
            }
          </Collapse>
        </Fragment>
      );
    }
}