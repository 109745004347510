import React from 'react';

export function ProductGallery(props) {
    const { productId, gallery, title } = props;
    const content = gallery.map((media) =>
        <PropductGalleryItem key={media.id.toString()}
            productId={productId} media={media} title={title} />
    );
    return (
        <div className="row">
            {content}
        </div>
    );
}

function PropductGalleryItem(props) {
    const { productId, media, title } = props;
    if (media.type === 'video')
        return PropductGalleryItemVideo(productId, media, title);
    else
        return PropductGalleryItemPhoto(productId, media, title);
}

function PropductGalleryItemPhoto(productId, media, title) {
    return (
        <div className="col-12 col-lg-12 col-xl-6 offset-xl-0">
            <a
                data-toggle="zoom"
                data-image=""
                className="d-block mx-auto productgallery-img-wrapper">
                <img
                    src={productId < 19167 ? media.url : process.env.REACT_APP_MEDIAURL_PRODUCT_GALLERYIMG + media.url}
                    alt={title} className="" />
            </a>
        </div>
    );
}

function PropductGalleryItemVideo(productId, media, title) {
    return (
        <div className="embed-responsive">
            <video className="embed-responsive-item">
                <source src={media.url} type="video/mp4" />
            </video>
        </div>
    );
}
