import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ProductsListing } from '../store-catalogue/ProductsListing';
import { DesignerUniversities } from './DesignerUniversities';
import { Meta } from "../layout-components/Meta";
import { Story } from '../story/Story';
import './DesignerDetailsPage.css';

export class DesignerDetailsPage extends Component {
    static displayName = DesignerDetailsPage.name;

    constructor(props) {
        super(props);
        this.state = { designer: null, loading: true };
    }

    async componentDidMount() {
        const { designerUrl } = this.props.match.params;
        await this.fetchData(designerUrl);
    }    

    render() {
        if (this.state.loading)
            return (
                <p><em>Loading...</em></p>
            );
        
        let designer = this.state.designer;

        return (
            <div>
                <Meta 
                    title={`${designer.brandTitle} | FLAIR`}
                    description={`Discover and shop directly from ${designer.brandTitle} on Flair.fashion. Explore emerging designers who are at the forefront of innovation in design, sustainability and technology.`}
                    url={`${process.env.REACT_APP_URL_DEFAULT_DOMAIN}/${designer.friendlyUrlName}`}
                    image={process.env.REACT_APP_MEDIAURL_DESIGNER_PORTRAIT + designer.portraitImage.url + "?v=1"}>
                </Meta>
                <div className="container px-0 mb-4 d-lg-none">
                    <div className="row justify-content-between">
                        <div className="col-7 col-sm-8">
                            <p><Link to="/designers">Designer</Link></p>
                            <h1 className="text-uppercase mb-2 header-style">{designer.brandTitle}</h1>
                            <h2 className="text-uppercase fslash mb-0">By {designer.designerName}</h2>
                        </div>
                        <div className="col-5 col-sm-4 text-center align-self-end">
                            <div className="logoholder-sm">
                                <img src={designer.id < 1058 ? designer.logoImage.url : process.env.REACT_APP_MEDIAURL_DESIGNER_LOGO + designer.logoImage.url} alt={designer.brandTitle} />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 d-block d-lg-none text-center mb-4">
                    </div>

                </div>


                <div className="row mb-5 pb-5">
                    <div className="col-lg-6 order-lg-last">
                        <img src={designer.id < 1058 ? designer.portraitImage.url : process.env.REACT_APP_MEDIAURL_DESIGNER_PORTRAIT + designer.portraitImage.url + "?v=1"} className="designer-img w-100" />
                    </div>
                    <div className="col-lg-6 bio">
                        <div className="d-none d-lg-block">
                            <div className="clearfix">
                                <div className="logoholder float-right text-right">
                                    <img src={designer.id < 1058 ? designer.logoImage.url : process.env.REACT_APP_MEDIAURL_DESIGNER_LOGO + designer.logoImage.url} alt={designer.brandTitle} />
                                </div>
                            </div>
                            <h1 className="title text-uppercase header-style">{designer.brandTitle}</h1>
                            <h2 className="subtitle text-uppercase mb-5"><span className="fslash" style={{color:'#cccccc'}}>By</span> {designer.designerName}</h2>
                        </div>
                        <div className="container px-0">
                            <div className="desc mt-4 p-0 col-lg-10 col-xl-8">{designer.tagline}</div>
                            <DesignerUniversities list={designer.universities} />
                        </div>
                    </div>
                </div>

                <div className="mt-4 pb-5">
                    <Story content={designer.story} />
                </div>

                {(designer.collections && designer.collections.length > 0) &&
                    <section className="pb-5 mb-5">
                        <h2 className="header-style page-title__h2 text-center mt-4 mb-2">{designer.brandTitle} Collections</h2>
                        <p className="text-center mb-4">View latest collections from designer</p>

                        <div className="row">
                        {
                            designer.collections.map((d, i) =>
                            <article key={d.id} className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 item my-3 mx-auto">
                                <Link to={`/${d.designer.friendlyUrlName}/collection/${d.id}/${d.friendlyUrlName}`}>
                                    <svg width="100%" viewBox="0 0 284 284" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect id="svg_1" height="250" width="250" y="30.75" x="0.75" strokeWidth="3" stroke="#262624" fill="#fff"/>
                                        <rect id="svg_6" height="250" width="250" y="20.75" x="10.75" strokeWidth="3" stroke="#262624" fill="#fff"/>
                                        <rect id="svg_7" height="250" width="250" y="10.75" x="20.75" strokeWidth="3" stroke="#262624" fill="#fff"/>
                                        <image id="svg_8" height="250" width="250" y="0.75" x="30.75" href={d.id < 1040 ? d.thumbnailImage.url : process.env.REACT_APP_MEDIAURL_COLLECTION_THUMBNAIL + d.thumbnailImage.url} />
                                        <rect id="svg_9" height="250" width="250" y="0.75" x="30.75" strokeWidth="3" stroke="#262624"/>
                                    </svg>
                                    <div className="mt-2 font-weight-bold text-center">{d.title}</div>
                                </Link>
                            </article>
                            )
                        }
                        </div>
                    </section>
                }

                {(designer.products && designer.products.length > 0) &&
                    <section className="pb-5 mb-5">
                        <h2 className="header-style page-title__h2 text-center mt-4 mb-2">{designer.brandTitle} Store</h2>
                        <p className="text-center mb-4">Buy directly from designer</p>
                        <ProductsListing products={designer.products} />
                    </section>
                }
            </div>
        );
    }

    async fetchData(designerUrl) {
        const response = await fetch(`api/designers/details/${designerUrl}`, {
        });
        const data = await response.json();
        this.setState({ designer: data, loading: false });
    }
}
