import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './StoreCataloguePaging.css';

export function StoreCataloguePaging(props) {
    let { paging, categoryBaseUrl, selectedDesigner } = props;

    if(paging.totalPages <= 1) {
        return (<Fragment />);
    }

    if(selectedDesigner) {
        categoryBaseUrl = categoryBaseUrl + '/designer/' + selectedDesigner.friendlyUrlName;
    }

    let content = [];
    for(var i=1; i<=paging.totalPages; i++) {
        content.push(
            <li key={i} className={'page-item' + (paging.currentPage === i ? ' active':'')}>
                <Link to={`${categoryBaseUrl}/page/${i}`} className="page-link text-dark">
                    {i}
                    {paging.currentPage === i &&
                        <span className="sr-only">(current)</span>
                    }
                </Link>
            </li>
        );
    }

    return (
        <div className="mt-4">
            <nav aria-label="Store catalogue page navigation">
                <ul className="pagination justify-content-center">
                    {content}
                </ul>
            </nav>
        </div>
    );
}
