import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import CarouselSlider from '../common/CarouselSlider';

export function CollectionCarouselListing(props) {

    if(!props.items || !props.items.length || props.items.length <= 0) {
        return(<Fragment></Fragment>);
    }

    return (
        <CarouselSlider 
            fullPageWidth={true}
            itemWidth={210}
            scrollLock={false}
            exactWidth={true}
            slidesToShow="auto"
            sliderClassName="pr-4"
            wrapperClassName="w-full"
            sliderNextButton={props.sliderNextButton}
            sliderPreviousButton={props.sliderPreviousButton}
            responsive = {
                [
                    {
                      breakpoint: 992,
                      settings: {
                        itemWidth: 280
                    }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        itemWidth: 230
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        itemWidth: 230
                      }
                    }
                  ]
            }>
            {props.items.map(d =>
                <article key={d.id} className="item pl-4 pr-1">
                    <Link to={`/${d.designer.friendlyUrlName}/collection/${d.id}/${d.friendlyUrlName}`} className="d-block">
                        <svg width="100%" viewBox="0 0 284 284" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect id="svg_1" height="250" width="250" y="30.75" x="0.75" strokeWidth="3" stroke="#262624" fill="#fff"/>
                            <rect id="svg_6" height="250" width="250" y="20.75" x="10.75" strokeWidth="3" stroke="#262624" fill="#fff"/>
                            <rect id="svg_7" height="250" width="250" y="10.75" x="20.75" strokeWidth="3" stroke="#262624" fill="#fff"/>
                            <image id="svg_8" height="250" width="250" y="0.75" x="30.75" href={d.id < 1040 ? d.thumbnailImage.url : process.env.REACT_APP_MEDIAURL_COLLECTION_CAROUSELTHUMBNAIL + d.thumbnailImage.url} />
                            <rect id="svg_9" height="250" width="250" y="0.75" x="30.75" strokeWidth="3" stroke="#262624"/>
                        </svg>
                        <div className="mt-2 font-weight-bold text-center">{d.title}</div>
                        {props.showBrandTitle ? <div className="text-center">By {d.designer.brandTitle}</div> : null }
                    </Link>
                </article>
            )}
        </CarouselSlider>
    );
}