import React from 'react';


export function DesignerUniversities(props) {
    if(!props.list || props.list.length <= 0) return null;

    let content = props.list.map(u =>
        <div key={u.id}>
            {u.universityName}
            {(u.graduationYear ? 
                (<React.Fragment><br />Graduated {u.graduationYear}</React.Fragment>) 
                : (<React.Fragment></React.Fragment>) 
            )}
        </div>
    );

    return (
        <div className="unilist mt-5 p-0 col-lg-10 col-xl-8">
            University:<br />
            {content}
        </div>
    );
}