import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";
import { Story } from '../story/Story';
import { ProductsListing } from '../store-catalogue/ProductsListing';
import './CollectionDetailsPage.css';

export class CollectionDetailsPage extends Component {
    static displayName = CollectionDetailsPage.name;

    constructor(props) {
        super(props);
        this.state = { collection: null, loading: true };
    }

    async componentDidMount() {
        const { id, collectionUrl, designerUrl } = this.props.match.params;
        await this.fetchData(id, collectionUrl, designerUrl);
    }

    render() {
        if (this.state.loading)
            return (
                <p><em>Loading...</em></p>
            );
        
        let collection = this.state.collection;

        return (
            <div>
                <Meta 
                    title={`Collection: ${collection.title} by ${collection.designer.brandTitle} | FLAIR`}
                    description={`Experience ${collection.designer.brandTitle} collection ${collection.title} on Flair.fashion.`}
                    url={`${process.env.REACT_APP_URL_DEFAULT_DOMAIN}/${collection.designer.friendlyUrlName}/collection/${collection.id}/${collection.friendlyUrlName}`}
                    image={collection.thumbnailImage.url}>
                </Meta>
                <div className="motive py-5 my-5">
                    <h3 className="fslash">Collection</h3>
                    <h1 className="header-style">{collection.title}</h1>
                    <h3>By <Link to={`/${collection.designer.friendlyUrlName}`}>{collection.designer.brandTitle}</Link></h3>
                </div>

                <div className="pb-5 my-5">
                    <Story content={collection.story} />
                </div>

                {collection.products && collection.products.length > 0 &&
                <section className="pb-5 mb-5">
                    <h1 className="ftitle fslash">Designer store</h1>
                    <h3 className="sectiontitle mb-4">Buy items from this collection</h3>
                    <ProductsListing products={collection.products} />
                </section>
                }
            </div>
        );
    }

    async fetchData(id, collectionUrl, designerUrl) {
        const response = await fetch(`api/collections/details/${designerUrl}/${id}/${collectionUrl}`, {
        });
        const data = await response.json();
        this.setState({ collection: data, loading: false });
    }
}
