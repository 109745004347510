import React from 'react';
import { Link } from 'react-router-dom';
import { ProductAddToBasket } from './ProductAddToBasket'
import { ProductsSideBenefits } from './ProductsSideBenefits'

export function ProductRightSideDetails(props) {
    const { product, scrollToAR, scrollToDetail }  = props;

    return (
        <div className="product-sideinfo sticky-top mb-4">
            <div className="sticky-top text-left">
                <div className="mb-3 fixed-w">
                    <div className="d-none d-md-block">
                        <h5 className="mb-3 text-muted">Designed by <Link to={`/${product.designer.friendlyUrlName}`} className="">{product.designer.brandTitle}</Link></h5>
                        <h1 className="text-uppercase mb-2 header-style">{product.title}</h1>
                        <h5 className="mb-4 text-muted">{product.tagline}</h5>
                        <h2 className="price mb-4 d-none d-md-block">{product.unitPriceCurrency}</h2>
                        <ProductAddToBasket product={product} />
                        {product.sizingInformation &&
                        <div className="text-right mt-1">
                            <a href="#" className="product-input" onClick={(e) => {e.preventDefault();scrollToDetail('2');}}>Size guide</a>
                        </div>
                        }
                    </div>
                    <ProductsSideBenefits product={product} scrollToAR={scrollToAR} scrollToDetail={scrollToDetail} />
                    <div className="text-center">
                        <a href="#" className="product-input" onClick={(e) => {e.preventDefault();scrollToDetail('1');}}>Read more</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ProductMobileBottomDetails(props) {
    const { product }  = props;

    return (
        <div>
            <div className="d-flex">
                <div className="mb-2 text-muted small flex-grow-1 ">Designed by <Link to={`/${product.designer.friendlyUrlName}`} className="">{product.designer.brandTitle}</Link></div>
                <div>{product.unitPriceCurrency}</div>
            </div>
            <div className="text-uppercase mb-3">{product.title}</div>
            <ProductAddToBasket product={product} />
        </div>
    );
}