import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

export function Meta(props) {
    const {title, description, image, url} = props;
    return (
        <Helmet>
            {title && <title>{title}</title>}
            {title && <meta name="title" content={title} />}
            {title && <meta name="twitter:title" content={title} />}
            {title && <meta property="og:title" content={title} />}
            
            {!title && <title>FLAIR - The Fashion Platform For The Next Generation</title>}
            {!title && <meta name="title" content="FLAIR - The Fashion Platform For The Next Generation" />}
            {!title && <meta name="twitter:title" content="FLAIR - The Fashion Platform For The Next Generation" />}
            {!title && <meta property="og:title" content="FLAIR - The Fashion Platform For The Next Generation" />}

            {description && <meta name="description" content={description} />}
            {description && <meta name="twitter:description" content={description} />}
            {description && <meta property="og:description" content={description} />}

            {!description && <meta name="description" content="Discover and shop from fashion graduates and next generation of designers and brands, who are at the forefront of innovation in design, sustainability and technology." />}
            {!description && <meta name="twitter:description" content="Discover and shop from fashion graduates and next generation of designers and brands, who are at the forefront of innovation in design, sustainability and technology." />}
            {!description && <meta property="og:description" content="Discover and shop from fashion graduates and next generation of designers and brands, who are at the forefront of innovation in design, sustainability and technology." />}

            {image && <meta name="twitter:img:src" content={image} />}
            {image && <meta property="og:image" content={image} />}

            {!image && <meta name="twitter:img:src" content="https://res.cloudinary.com/flairfashion/image/upload/f_auto,q_auto:best/v1615069206/TestSite/Logos/flair-logo-s.jpg" />}
            {!image && <meta property="og:image" content="https://res.cloudinary.com/flairfashion/image/upload/f_auto,q_auto:best/v1615069206/TestSite/Logos/flair-logo-s.jpg" />}

            {props.children}
            
            {url && <link rel="canonical" href={url} />}
            {url && <meta property="og:url" content={url} />}
]        </Helmet>
    );
}
