import basketService from '../api/BasketService';
import authService from '../api-authorization/AuthorizeService';

export class CheckoutService {

    async fetchCheckoutData() {
        const localbasket = await basketService.getLocalBasket();
        const token = await authService.getAccessToken();
        
        if(!token && (!localbasket || !localbasket.basketId)) {
            //no local basket or logged in user
            return null;
        }
    
        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        if(token) {
            reqHeader['Authorization'] = `Bearer ${token}`;
        }
    
        const response = await fetch('/api/checkout', {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify(!localbasket ? {} : localbasket)
        });
    
        const data = await response.json();
        console.log(data);
        basketService.updateLocalBasket(data.basket.localBasket);
        return data;
    }

    async initiateCheckoutOrder(orderData) {
        if(!orderData) return;
    
        const token = await authService.getAccessToken();
    
        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        if(token) {
            reqHeader['Authorization'] = `Bearer ${token}`;
        }
    
        const response = await fetch('/api/checkout/initiate-checkout-order', {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify(!orderData ? {} : orderData)
        });
    
        try {
            const data = await response.json();
            console.log(data);
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }
    
    async finishCheckoutOrder(orderId) {
        if(!orderId) return;
    
        const token = await authService.getAccessToken();
    
        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        if(token) {
            reqHeader['Authorization'] = `Bearer ${token}`;
        }
    
        const response = await fetch('/api/checkout/finish-checkout-order', {
            method: 'post',
            headers: reqHeader,
            body: JSON.stringify({ orderId: orderId })
        });
    
        const data = await response.json();
        console.log(data);
        return data;
    }  

    async getCheckoutOrderPaymentIntent(orderId, paymentIntentId) {
        if(!orderId || !paymentIntentId) return;
    
        const token = await authService.getAccessToken();
    
        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        if(token) {
            reqHeader['Authorization'] = `Bearer ${token}`;
        }
    
        const response = await fetch(`/api/checkout/payment/${orderId}/${paymentIntentId}/status`, {
            method: 'get',
            headers: reqHeader
        });
    
        const data = await response.json();
        console.log(data);
        return data;
    }  

    async getCheckoutCompleteStatus(orderId) {
        if(!orderId) return;
    
        const token = await authService.getAccessToken();
    
        let reqHeader = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        };
        if(token) {
            reqHeader['Authorization'] = `Bearer ${token}`;
        }
    
        const response = await fetch(`/api/checkout/complete/${orderId}/status`, {
            method: 'get',
            headers: reqHeader
        });
    
        const data = await response.json();
        console.log(data);
        return data;
    }  

    static get instance() { return checkoutService }
}

const checkoutService = new CheckoutService();

export default checkoutService;
