import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import CarouselSlider from '../common/CarouselSlider';

export function DesignerCarouselListing(props) {

    if(!props.items || !props.items.length || props.items.length <= 0) {
        return(<Fragment></Fragment>);
    }

    return (
        <CarouselSlider 
            fullPageWidth={true}
            itemWidth={175}
            scrollLock={false}
            exactWidth={true}
            slidesToShow="auto"
            sliderClassName="pr-4"
            wrapperClassName="w-full"
            sliderNextButton={props.sliderNextButton}
            sliderPreviousButton={props.sliderPreviousButton}
            responsive = {
                [
                    {
                      breakpoint: 992,
                      settings: {
                        itemWidth: 250
                    }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        itemWidth: 200
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        itemWidth: 200
                      }
                    }
                  ]
            }>
            {props.items.map(d =>
                <article key={d.id} className="item pl-4 pr-1">
                    <Link to={`/${d.friendlyUrlName}`} className="d-block">
                        <img src={d.id < 1058 ? d.portraitImage.url : process.env.REACT_APP_MEDIAURL_DESIGNER_CAROUSELPORTRAIT + d.portraitImage.url + "?v=1"} className="w-100" alt={d.brandTitle} />
                        <div className="mt-2 text-center font-weight-bold">{d.brandTitle}</div>
                        <div className="text-center">By {d.designerName}</div>
                        <div className="text-center">{d.university}</div>
                    </Link>
                </article>
            )}
        </CarouselSlider>
    );
}