import React, { Component, Fragment } from 'react';
import { StoryHeader, StoryHeaderLEGACY, StoryTextBox, StoryBanner, StoryBannerLEGACY, StoryLeadText, StoryLeadTextLEGACY, StoryLeadWithImageOnRight, StoryTextWithMedia, StoryTextWithImageLEGACY, StoryTextColumns, StoryImage, StoryGalleryDualImages, StoryGalleryImages, StoryAR2, Box1, StoryImagesGrid, StoryImagesGridLEGACY, StoryVideoSide, StoryCustom, StoryImagesGridMasonry } from './StorySections';
import StoryVideo, { StoryVideoLEGACY } from './StoryVideo';
import StoryAR from './StoryAR';
import { StoryImagesCarousel, StoryImagesCarouselLEGACY } from './StoryImagesCarousel';
import './Story.css';

export class Story extends Component {
    static displayName = Story.name;

    render() {
        const { content } = this.props;

        if(!content) 
            return (
                <Fragment></Fragment>
            );

        const json = JSON.parse(content);

        return (
            <div className="story">
                {json.blocks.map((row, index) => {
                    if(row.type === "textBox") {
                        return (
                            <StoryTextBox key={index} row={row} />
                        );
                    } 
                    if(row.type === "header" && json.time === 1600780052222) {
                        return (
                            <StoryHeaderLEGACY key={index} row={row} />
                        );
                    } 
                    if(row.type === "header") {
                        return (
                            <StoryHeader key={index} row={row} />
                        );
                    } 
                    else if(row.type === "banner" && json.time === 1600780052222) {
                        return (
                            <StoryBannerLEGACY key={index} row={row} />
                        );
                    }
                    else if(row.type === "banner") {
                        return (
                            <StoryBanner key={index} row={row} />
                        );
                    }
                    else if(row.type === "lead-w-img-right") {
                        return (
                            <StoryLeadWithImageOnRight key={index} row={row} />
                        );
                    }
                    else if(row.type === "lead-text") { //LEGACY
                        return (
                            <StoryLeadTextLEGACY key={index} row={row} />
                        );
                    }
                    else if(row.type === "leadText") {
                        return (
                            <StoryLeadText key={index} row={row} />
                        );
                    }
                    else if(row.type === "textWithMedia") {
                        return (
                            <StoryTextWithMedia key={index} row={row} />
                        );
                    }
                    else if(row.type === "text-with-image") { //LEGACY
                        return (
                            <StoryTextWithImageLEGACY key={index} row={row} />
                        );
                    }
                    else if(row.type === "text-columns" || //LEGACY
                            row.type === "textColumns") {
                        return (
                            <StoryTextColumns key={index} row={row} />
                        );
                    }
                    else if(row.type === "image") {
                        return (
                            <StoryImage key={index} row={row} />
                        );
                    }
                    else if(row.type === "video" && json.time === 1600780052222) {
                        return (
                            <StoryVideoLEGACY key={index} row={row} />
                        );
                    }
                    else if(row.type === "video") {
                        return (
                            <StoryVideo key={index} row={row} />
                        );
                    }
                    else if(row.type === "video-side") {
                        return (
                            <StoryVideoSide key={index} row={row} />
                        );
                    }
                    else if(row.type === "gallery-dual-images") {
                        return (
                            <StoryGalleryDualImages key={index} row={row} />
                        );
                    }
                    else if(row.type === "gallery-images") {
                        return (
                            <StoryGalleryImages key={index} row={row} />
                        );
                    }
                    else if(row.type === "ar") {
                        return (
                            <StoryAR2 key={index} row={row} />
                        );
                    }
                    else if(row.type === "ar-content" || row.type === "ar-content") {
                        return (
                            <StoryAR key={index} row={row} />
                        );
                    }
                    else if(row.type === "box1") {
                        return (
                            <Box1 key={index} row={row} />
                        );
                    }
                    else if(row.type === "images-grid" && json.time === 1600780052222) {
                        return (
                            <StoryImagesGridLEGACY key={index} row={row} />
                        );
                    }
                    else if(row.type === "imagesGrid") {
                        return (
                            <StoryImagesGrid key={index} row={row} />
                        );
                    }
                    else if(row.type === "images-grid-masonry") {
                        return (
                            <StoryImagesGridMasonry key={index} row={row} />
                        );
                    }
                    else if(row.type === "images-carousel") {
                        //LEGACY
                        return (
                            <StoryImagesCarouselLEGACY key={index} row={row} />
                        );
                    }
                    else if(row.type === "imagesCarousel") {
                        return (
                            <StoryImagesCarousel key={index} row={row} />
                        );
                    }
                    else if(row.type === "custom") {
                        return (
                            <StoryCustom key={index} row={row} />
                        );
                    }
                })}
            </div>
        );
    }
}
