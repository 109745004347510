import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Meta } from "../layout-components/Meta";

export function StoreCatalogueHeader(props) {

    let title, titleMeta, descriptionMeta;
    if(props.selectedCategory.id === props.rootCategory.id) {
        title = `All ${props.rootCategory.title}${props.selectedDesigner ? ` by ${props.selectedDesigner.brandTitle}` : ''}`;
        titleMeta = `Clothing, Bags, Accessories and Shoes for ${props.rootCategory.title}${props.selectedDesigner ? ` by ${props.selectedDesigner.brandTitle}` : ''}`;
        descriptionMeta = `Shop designer clothing, bags, accessories and shoes for ${props.rootCategory.title.toLowerCase()}${props.selectedDesigner ? ` by ${props.selectedDesigner.brandTitle}` : ''} on Flair.fashion. Shop from emerging designers who are at the forefront of innovation in design, sustainability and technology.`;
    } else {
        title = `${props.selectedCategory.title}${props.selectedDesigner ? ` by ${props.selectedDesigner.brandTitle}` : ''}`;
        titleMeta = `${props.selectedCategory.title}${props.selectedDesigner ? ` by ${props.selectedDesigner.brandTitle}` : ''}`;
        descriptionMeta = `Shop ${props.selectedCategory.title.toLowerCase()}${props.selectedDesigner ? ` by ${props.selectedDesigner.brandTitle}` : ''} on Flair.fashion. Shop from emerging designers who are at the forefront of innovation in design, sustainability and technology.`;
    }

    let description;
    if(props.selectedDesigner) {
        description = (
            <Link to={`/${props.selectedDesigner.friendlyUrlName}`} className="">View designer's page</Link>
        );
    }
        
    return (
        <Fragment>
            <Meta title={`${titleMeta} | FLAIR`}
                description={descriptionMeta}
                url={`${process.env.REACT_APP_URL_DEFAULT_DOMAIN}${props.categoryBaseUrl}${props.selectedDesigner ? `/designer/${props.selectedDesigner.friendlyUrlName}` : ``}`} />

            <h1 id="tabelLabel" className="text-left">
                {title}
            </h1>
            <p className="text-left">
                {description}
            </p>
        </Fragment>
    );
}
